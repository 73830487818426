.techSearchBox {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  max-width: 400px;
  z-index: 20;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-height: 600px;

  transition: max-width 0.3s ease, height 0.3s ease;

  &.expanded {
    width: 60vw;
    max-width: unset !important;
    max-height: 600px;
    height: 80vh;
    min-width: 750px;
  }

  &__visible {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    gap: 8px;
    padding: 10px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    align-self: flex-end;

    h3 {
      margin: 0;
      font-weight: 600;
    }

    .expandIcon {
      cursor: pointer;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    border: 1px solid #dfdfdf;
    position: relative;
    min-width: 200px;
    width: 100%;

    input {
      padding: 8px;
      border: none !important;
      background-color: rgba(0, 0, 0, 0.04);
      flex-grow: 1;
      margin-right: 8px;
      width: 100% !important;
    }

    .searchIcon {
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }

  &__resultsBox {
    position: relative;
    width: 100%;
    background-color: #fff;
    height: 70vh !important;
    justify-content: space-between;

    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
  }

  &__results {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    padding: 10px;
    flex: 1;
  }
  &__result {
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    gap: 8px;
    cursor: pointer;

    &:hover {
      background-color: rgba(#3700b3, 0.1);
    }

    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
  }

  &.expanded &__footer {
    justify-content: space-between;
  }

  &.expanded .techSearchBox__results {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 10px;
    column-gap: 20px;
  }

  &__noResults {
    padding: 16px;
    text-align: center;

    p {
      margin: 0;
      color: #888;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiPagination-root {
      button {
        color: #000 !important;
        background-color: transparent !important;
        border: 1px solid black !important;
        border-radius: 0 !important;
        // &:hover {
        //   background-color: rgba(0, 0, 0, 0.04) !important;
        // }
      }

      .Mui-selected {
        background-color: black !important;
        color: white !important;
      }
    }
  }

  .seeMoreBtn {
    display: block;
    width: 100%;
    padding: 0px;
    background-color: transparent;
    color: #3700b3;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: fit-content;
    border-radius: 0 !important;
    &:hover {
      border-bottom: #3700b3;
    }
  }
}
