._spReqTeam {
  margin-top: 1rem;
  * {
    box-sizing: border-box;
    // font-family: $font !important;
    // word-break: break-all;
    overflow-wrap: break-word;
    margin: 0;
  }

  span {
    font-size: 1.4rem;
    font-weight: 400;
  }

  gap: 2rem;
  & > div {
    margin: 0 auto;
    max-width: $mwidth_max;
  }
  .requirement-ctr {
    display: grid;
    gap: 1rem;
  }

  &--firstStep {
    box-shadow: 18px 15px 69px rgba(0, 0, 0, 0.05);
    background-color: white;
    border-radius: 15px;
    padding: 2rem;
    margin-bottom: 2rem;
    max-width: $mwidth_max;
    width: 100%;
    button {
      margin: 0 !important;
    }
    &_firstInfos {
      display: flex;
      &__left {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex: 3;

        & > div {
          display: flex;
          align-items: center;
          gap: 1rem;
        }

        &-title {
          // aligne item in row
          border-right: 1px solid #32e7bb;
          // padding: 0 1rem;
          // padding-right: 1rem;
          width: 308px;
        }
        &-reqInfo {
          h4 {
            margin: 0 !important;
            font-size: 1.8rem !important;
          }
        }
      }
    }
    &__descActions {
      margin-top: 1rem;
      gap: 1rem;
      justify-content: space-between;

      ._reqDescEditor {
        height: auto;
      }

      &--left {
        gap: 1rem;
        justify-content: space-between;
        width: 100%;
        p {
          font-size: 14px !important;
          letter-spacing: 0.605px !important;
          font-weight: 500 !important;
          color: #5c5c5c !important;
        }
        &-reqTimes {
          gap: 1rem;
          justify-content: space-between;
          align-items: center;
          & > div {
            gap: 0.5rem;
            span:first-child {
              color: #adadad;
            }
            span:last-child {
              color: #1e272e;
              font-weight: 600;
            }
            ._reqTime-confirmed {
              color: #32e7bb !important;
            }
            &:not(:last-child) {
              border-right: 2px solid #32e7bb;
              padding-right: 1rem;
            }
          }
        }
      }
      &--right {
        gap: 0.5rem;
      }
    }

    // div[]

    input,
    textarea,
    select {
      border: none;
      background-color: #f4f6f6;
      border-radius: 5px;
      padding: 1rem;
      width: 100%;
      min-height: 46px;
    }

    #_spReqEndDate,
    #startDate,
    #endDate,
    #_spReqEndDate {
      padding: 0.5px 0.5rem 0.5px 3.5rem !important;
    }
    &__editInfo {
      border-top: 1px solid #c4c4c4;
      margin-top: 1rem;
      padding: 1rem 0;
      position: relative;

      &--fields {
        margin-top: 2rem;
        gap: 3rem;
        border-bottom: 1px solid #c4c4c4;
        padding-bottom: 1rem;
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        &-selecters {
          ._spReq-selections {
            gap: 2rem;
            h3 {
              margin-bottom: 0.5rem;
            }
            select {
              padding: 0 1rem;
            }
          }
        }
      }
      &--actions {
        margin-top: 2rem;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        &_btns {
          gap: 1rem;
          justify-content: flex-end;
          align-items: center;
          flex: 1;
        }
        &_check {
          flex: 1;
          gap: 0.5rem;
          align-items: center;
          input {
            width: 20px;
            height: 20px;
            min-width: unset !important;
          }
          label {
            font-size: 18px !important;
            margin-bottom: 0 !important;
            font-weight: 600;
          }
        }
      }
      &--error {
        position: absolute;
        left: 40%;
        // bottom: 5%;
        background-color: #eb4e4bc2;
        padding: 0.5rem 1rem;
        border-radius: 5px;

        p {
          color: white;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.5px;
        }
      }
      ._reqTconfirmed {
        background-color: #00d796;
        color: white;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 700;
        width: 194px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &--info {
    background-color: #ecfaff;
    padding: 16.5px 25px;
    border-radius: 15px;
    box-shadow: 18px 15px 69px rgba(0, 0, 0, 0.05);
    max-width: $mwidth_max;

    &__content {
      position: relative;
      gap: 2rem;
      display: grid;
      grid-template-columns: 350px 1fr 300px;
      align-items: center;

      &-req {
        align-items: center;
        h4 {
          font-size: 1.8rem;
          color: black;
        }
      }
    }
  }
  &--OptionThird {
    box-shadow: 18px 15px 69px rgba(0, 0, 0, 0.05);
    background-color: white;
    transition: height 0.5s;
    // min-height: 30vh;
    position: relative;
    &_unconfirmed {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 4;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 15px;
      backdrop-filter: blur(2px);
    }

    border-radius: 15px;

    & > div {
      padding: 2rem;
    }

    &__info {
      display: flex;
      gap: 1rem;
      align-items: center;

      &--left {
        flex: 3;
        display: flex;
        gap: 1rem;
        align-items: center;
        & > div {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        .stepThreeDue {
          background-color: #efefef;
          padding: 5px;
          width: 244px;
          border-radius: 50px;
          p {
            font-weight: 700;
          }
        }

        .stepThreeAddMore {
          p {
            font-weight: 700;
            color: $sp_primColor;
          }
        }
      }

      &--right {
        flex: 1;
        max-width: 350px;
        display: flex;
        justify-content: flex-end;
        // align-items: center;
        gap: 1rem;
        position: relative;

        &-options {
          display: flex;
          gap: 1rem;
          position: absolute;

          flex-direction: column;
          background-color: #fff;
          width: 270px;
          border: 1px solid #c3c3c3;
          border-radius: 5px;
          padding: 0.5rem 1rem;
          right: 20%;
          z-index: 3;

          transition: all 0.5s;
          & > div:first-child {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            justify-content: space-between;
            width: 100%;
            h4 {
              color: #4361e8;
              font-size: 1.8rem;
              margin: 0;
            }
          }

          &--list {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            & > div {
              display: flex;
              justify-content: space-between;
              align-items: center;
              & > p {
                flex: 1;
                color: #4361e8;
                font-size: 1.7rem;
              }
            }
          }
        }
      }
    }
    ._spreqPartnerManagement {
      background-color: #ecfaff;
      // margin-top: 0.5rem;
      box-shadow: 5px 10px 50px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      padding: 0.5rem 1.5rem;
      // min-height: 72px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      position: relative;
      &--openOverLaye {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        // blur background
        backdrop-filter: blur(3px);
        z-index: 2;

        &--content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: white;
          padding: 2rem;
          border-radius: 5px;
          width: 90%;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: center;
          justify-content: center;

          p {
            font-size: 1.6rem;
            color: black;
          }
          button {
            background-color: transparent;
            color: $sp_primColor;
            border: none;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 700;
            width: 194px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
          }
        }
      }
      & > div {
        width: 100%;
        @include column;
        gap: 1rem;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        width: 100%;
        // margin-bottom: 1rem;
        &__title {
          font-size: 14px;
        }
      }
      &__body {
        @include column;
        gap: 1rem;
        #company-search-box {
          background: #ffffff;
          border: 1px solid #dddde1;
          border-radius: 6px;
          width: 100%;
          height: 40px;
          min-width: 289px;
        }

        &__search {
          outline: none;
          &__input {
            padding: 0 !important;
            outline: none;

            &__input {
              // padding: 0 !important;
              outline: none;
            }
          }
        }

        &__partners {
          @include column;
          gap: 1rem;
          background-color: white;
          padding: 1rem;
          border-radius: 5px;
          box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);

          &--partner {
            justify-content: space-between;
            align-items: center;

            &_info {
              flex: 3;
              align-items: center;
              gap: 0.2rem;
            }
          }

          &--more {
            justify-content: space-between;
            p {
              color: $sp_primColor;
              font-weight: 600;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
        &--recommend {
          gap: 1rem;
          &__list {
            gap: 0.5rem;

            &--partner {
              justify-content: space-between;
              align-items: center;
              box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);
              background-color: white;
              padding: 0.5rem 1rem;
              border-radius: 5px;

              &_info {
                flex: 3;
                align-items: center;
                gap: 0.2rem;
              }
              button {
                max-width: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
              }
            }
          }
        }
      }
    }
    &__details {
      display: flex;
      position: relative;
      min-height: 60vh;
      &--left {
        flex: 3;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: stretch;
        justify-content: stretch;
        ._spOptionsList,
        ._spPositionsList,
        ._spVendorsList {
          display: grid;
          grid-template-columns: repeat(3, minmax(200px, 390px));
          // max-width: calc(100% - 447px);
          justify-content: space-between;
          gap: 1rem;
          max-width: 1200px;
          min-width: 1118px;
        }
        ._spOptionsList {
          &-single {
            display: flex;
            flex-direction: column;
            // gap: 1rem;
            align-items: stretch;
            justify-content: stretch;
          }
        }
      }
      &--right {
        max-width: 447px;
        flex: 1;
        position: absolute;
        top: 1.5rem;
        right: 2rem;

        // translate: translateX(100px);
        // transform: translateX(100px);

        &_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // gap: 1rem;
          // margin-bottom: 1rem;
          flex-wrap: wrap;
        }
        &_devProfile {
          display: grid;
          gap: 2rem;
          h3 {
            font-size: 1.7rem !important;
            color: black;
          }
          ._sidePanel--devProfile {
            display: flex;
            align-items: flex-start;
            gap: 2%;
            &--img {
              width: 75px;
              height: 75px;
            }
            &--devDetails {
              gap: 0.2rem !important;
              p {
                color: #6a6e76;
              }
            }
            &--devPrice {
              background-color: #00c1aa;
              span {
                color: #fff;
              }
            }
          }
          ._sidePanel--devAbout {
            p {
              font-weight: 400;
              color: #a0a0a0;
            }
          }
          ._sidePanel--devLinks {
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: space-between;
            & > div {
              display: flex;
              gap: 0.5rem;
              align-items: center;
              & > p,
              & > a {
                color: #4361e9;
                font-size: 1.4rem;
                font-weight: 600;
              }
            }
          }

          ._sidePanel--devSkills {
            display: grid;
            gap: 1rem;
            &--list {
              display: flex;
              flex-wrap: wrap;
              gap: 0.5rem;
              span {
                border: 1px solid #e6e8eb;
                padding: 0.5rem 1rem;
                border-radius: 50px;
                font-size: 1.4rem;
                color: #6a6e76;
              }
            }
          }
        }
        &_matches {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          ._spSingleOptionMatch {
            max-height: 645px !important;

            &--rank {
              // background-color: #00c1aa;
              display: flex;
              flex-direction: column;
              gap: 0.2rem;
              border-bottom: 1px solid #c7d7e2;
              padding-bottom: 1rem;
              & > div {
                display: flex;
                gap: 1rem;
                align-items: center;
                justify-content: space-between;
              }
              p {
                color: #c2c2c2;
                font-size: 14px;
                font-weight: 400;
              }
              .rankNumber {
                font-size: 1.6rem;
                font-weight: 700;
                background-color: #808f8c;
                color: #fff;
                padding: 0.1rem 1rem;
                border-radius: 5px;
                min-width: 69.17px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            &--overlay {
              position: absolute;
              top: 0;
              right: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.5);
              z-index: 20;
              border-radius: 15px;
            }

            &--optionPicker {
              position: absolute;
              bottom: 18%;
              right: 24px;
              z-index: 30;
              &-optPosLists {
                width: 161px;
                p,
                h3 {
                  padding: 0.2rem;
                  // center element
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  // border-radius: 5px;
                  margin-bottom: 0.2rem;
                  &:hover {
                    cursor: pointer;
                  }
                }
                h3 {
                  background-color: black !important;
                  color: #fff;
                }
                p {
                  background-color: rgb(68, 68, 68);
                  color: white;
                }
              }
            }
            &--actions {
              z-index: 25;
              &_chat {
                background-color: white !important;
                &:hover {
                  background-color: white !important;
                }
              }
            }
          }
          ._spReqGetMoreMatches {
            margin-top: 2rem;
            h3 {
              font-size: 1.6rem;
              color: black;
              // font-weight: 00;
            }
            p {
              border: 1px solid #4361e9;
              color: #4361e9;
              padding: 0.5rem 1rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              margin-top: 1rem;
              cursor: pointer;
            }
          }
        }
        &_logs {
          gap: 1rem;
          box-sizing: border-box;
          overflow: hidden;
          &__header {
            justify-content: space-between;
          }
          &__list {
            gap: 0.5rem;

            &-single {
              span {
                font-size: 14px;
                // color: #6a6e76;
              }
              .reqLogInd {
                display: inline-block;
                // width: 1.5rem;
              }
              .reqLogTime,
              .reqLogAuthor {
                font-weight: 600;
              }
              .reqLogAuthor {
                position: relative;
                margin-right: 25px;
                &::after {
                  content: "";
                  margin-right: 0.5rem;
                  width: 15px;
                  height: 2px;
                  background-color: black;
                  position: absolute;
                  top: 50%;
                  right: -25px;
                }
              }

              .reqLogTime {
                margin-right: 0.5rem;
              }

              .reqLogMessage {
                color: #6a6e76;
                word-break: break-word;
              }
            }
          }
        }
      }
    }

    .No_resource {
      padding: 1rem;
    }
  }
  ._spOptionSelector {
    display: flex;
    gap: 1rem;
    position: absolute;

    flex-direction: column;
    background-color: #fff;
    width: 18rem;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    right: 100%;
    z-index: 3;

    transition: all 0.5s;
    & > div:first-child {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: space-between;
      width: 100%;
      h4 {
        color: #4361e8;
        font-size: 1.6rem;
        margin: 0;
      }
    }

    &--list {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
          flex: 1;
          color: #4361e8;
          font-size: 1.4rem;
        }
      }
    }
  }

  .reqStepCount {
    border: 1px solid black;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    p {
      font-size: 1.4rem;
      // margin: 0;
      font-weight: 700;
    }
  }
  .reqStepTitle {
    font-size: 1.8rem;
    font-weight: 600;
  }
}

._req-userNotify {
  // background-color: red !important;
  background-color: #ecfaff;
  box-shadow: 5px 10px 50px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 20px;
  width: 700px;
  max-width: 90vw;
  max-height: 90vh;
  display: grid;
  gap: 1rem;
  overflow-y: auto;
  overflow-x: hidden;

  textarea,
  input {
    border: 1px solid #dddde1;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    color: #26282b;
    width: 100%;
    outline: none;
    &:focus {
      border: 1px solid #4361e9;
    }
  }
  &--main {
    display: grid;
    gap: 1rem;

    & > div {
      display: grid;
      gap: 0.2rem;
    }
  }

  &-search {
    position: relative;

    &-results {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: white;
      border: 1px solid #dddde1;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      font-size: 14px;
      color: #26282b;
      z-index: 10;
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
      display: grid;
      gap: 0.5rem;
      button {
        margin-top: 0;
      }
      &-single {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        width: 100%;
        // padding: 0.5rem 1rem;

        &:hover {
          cursor: pointer;
          background-color: #ecfaff;
        }
        // max-width: 660px;

        & > div {
          p {
            // max-width: 150px;
            white-space: normal;
            overflow: ellipsis;
            word-break: break-all;
          }
        }

        &-left {
          display: flex;
          align-items: center;
          gap: 1rem;
          flex: 3;
          &-img {
            width: 40px !important;
            height: 40px !important;
            border-radius: 50% !important;
            object-fit: cover !important;
            overflow: hidden;
          }
          // &-name {
          //   font-size: 14px;
          //   font-weight: 600;
          // }
        }
        &-right {
          // display: flex;
          // align-items: center;
          // gap: 1rem;
          flex: 1;

          button {
            width: 155px;
            padding: 0.5rem 1rem;
          }
          // justify-content: flex-end;
        }
      }
    }
  }

  label {
    font-size: 14px;
    font-weight: 600;
    // margin-bottom: 1rem;
  }
  &-required {
    color: red;
    font-size: 12px;
    margin-left: 0.5rem;
  }
  textarea {
    max-width: 100%;
    height: 100px;
  }
  button {
    margin-top: 0;
    border-radius: 5px;
  }

  &-messageBox {
    &_switchers {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      .template--viewer {
        width: 100%;
      }

      button {
        border-radius: 5px !important;
        font-size: 14px;
        width: 120px;

        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 10px 0;
        border: 1px solid #dddde1;
        background-color: white;
        color: black;
        font-weight: 700;
        &:hover {
          background-color: #2b49cfc0 !important;
          color: white;
        }
        &.active {
          background-color: #2b49cfc0 !important;
          color: white;
        }
      }
    }
    textarea {
      width: 100%;
      height: 250px !important;
    }
  }
  &-actions {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    button {
      width: 150px;
    }
  }

  &--emailTemp {
    display: grid;
    gap: 1rem;

    textarea {
      height: 250px !important;
    }

    &_templates {
      &__list {
        background-color: white;
        border: 1px solid #dddde1;
        border-radius: 5px;
        p {
          padding: 0.2rem 1rem;
        }
      }
    }

    &_actions {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      align-items: center;
    }
    &_realActions {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;
    }
  }
}
