#root {
  display: flex;
  position: relative;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  align-items: stretch;
  color: $text;
  font-family: "Montserrat", sans-serif;
  background-color: $white;

  .nav {
    flex-basis: 12%;
    min-width: 22rem;
    min-height: 100vh;

    position: relative;

    &-content {
      position: fixed;
      background-color: $sidebare;
      border-right: 1px solid $color_border;
      height: 100vh;
      min-width: 22rem;
      padding: 2rem 2rem;
      z-index: 400;

      .spryte-logo {
        margin-right: 10px;
        cursor: pointer;
        height: 25px !important;
      }

      // toggle button
      .toggleBtn {
        height: 15px !important;
        margin-bottom: 2.5rem;
      }

      .nav-logo {
        margin-bottom: 4rem;
        h2 {
          font-size: 2rem !important;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        // overflow: hidden;
        max-width: 22rem;
        position: fixed;

        .facadeProfile {
          // background-color: #000000;
          width: 18rem;
          overflow: hidden;
          margin-left: -1rem;
          &_info {
            gap: 1rem;
          }
          &_check {
            margin-top: 2rem;
            p {
              margin-bottom: 0;
            }
          }
          .spImgPrev {
            width: 4rem;
            height: 4rem;
          }
        }

        .sub-menu {
          transition: all 0.8s;
          transform: translateX(30px);
        }

        a {
          color: $text;
          font-weight: 500;
          font-size: 1.4rem;
          text-align: right;
          display: flex;
          align-items: center;

          .back {
            font-weight: 600;
            color: $color_prim;
            &:hover {
              color: $danger;
            }
          }
          .dev_name {
            font-weight: 700;
            margin-top: 3rem;
            font-size: 1.5rem;
          }

          &:not(:last-child) {
            margin-bottom: 1rem;
          }
          &:hover {
            color: $color_prim;
            text-decoration: none;
          }
          .admin {
            margin-left: 2.3rem;
          }
          svg {
            width: 1.5rem;
            font-size: 1.5rem;
            margin-right: 0.6rem;
            color: lighten($color: #000000, $amount: 30);
          }
          small {
            padding: 0 !important;
            margin-left: -3px !important;
            color: black !important;
            font-size: 10px !important;
          }
        }
        .admin_container {
          margin: 1.2rem 0 1.7rem 0;
        }
        // toggles navbar
        .toggleNavbar {
          display: none;
        }
        // applies on sidebar when user isn't logged in
        .toggled_sign {
          font-size: 0.9rem;
          text-align: left;
          margin-left: -10px;
        }
      }
    }
  }

  // collapse navbar on toggle
  .nav-collapse {
    min-width: 6rem !important;

    // collapse navbar content on toggle
    .nav-content-collapse {
      min-width: 6rem !important;
      max-width: 6rem !important;
    }
  }

  .content {
    flex-basis: 85%;
    position: relative;

    .main {
      max-width: 85vw;
      padding: 6rem 2rem 0 2rem;
      margin: 0;
      height: 100%;
      width: 85vw;
      position: relative;
      box-shadow: -1px 0 #e3e8ee;
      background-color: $white;
    }

    // increase the max-width and width of the main content on toggle sidebar
    .main-collapse {
      max-width: 95vw !important;
      width: 95vw !important;
    }

    .topBar {
      position: fixed;
      width: 100%;
      z-index: 300;
      // align-items: center;
      height: 5vh;
      .header {
        padding: 1rem 2rem;
        box-shadow: 0 1px #e3e8ee;
        font-size: 1.4rem;
        background-color: $white;
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 15px !important;
        &__right {
          display: flex;
          flex-basis: 20%;
          align-items: center;
          justify-content: flex-end;
          gap: 2rem;
        }
        &__left {
          // background-color: aqua;
          flex-basis: 65%;
          display: flex;
          gap: 1rem;
          justify-content: flex-start;
          align-items: center;

          &-search {
            display: flex;
            gap: 2rem;
            align-items: center;
            flex: 1;

            &--btns {
              display: flex;
              gap: 1rem;
              align-items: center;
              .btn {
                color: white;
                font-size: 1.2rem;
              }
              .btn-sub {
                background-color: $color_prim;
              }
              .btn-cancel {
                background-color: $danger;
              }
            }
          }
          &-vendor {
            p {
              margin-bottom: 0;
            }
            span {
              font-weight: 700;
              font-size: 1.5rem;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}
