.colManagement {
  padding-bottom: 10rem;
  select,
  input,
  textarea {
    border: 1px solid rgba(128, 128, 128, 0.1) !important;
    padding: 1rem;
    background-color: rgba($color_prim, 0.1);
    outline: none;
  }
  .collection {
    margin-top: 5rem;
    h3 {
      margin: 0;
      font-size: 1.4rem;
      font-weight: 600;
    }

    p {
      font-size: 1.3rem;
    }
    .addNew {
      .btn {
        background-color: $color_prim;
        color: white;
        font-size: 1.2rem;
      }
      .css-yk16xz-control {
        border: none;
        border-bottom: 2px solid $color_prim;
        border-radius: 0;
        &:hover,
        &:focus {
          border: none;
          border-bottom: 2px solid $danger;
        }
      }
    }

    &_data {
      display: flex;
      flex-wrap: nowrap;
      gap: 3rem;

      &-grid {
        flex: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        .spbtn {
          font-size: 1.5rem;
        }
        // flex-shrink: 1;
      }
      &-information {
        flex: 50%;
        // flex-shrink: 2;
        margin-top: 7rem;
        &__header {
          justify-content: space-between;
          align-items: center;
        }
        &__detail {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          margin-top: 1rem;

          .collectDetail {
            display: flex;
            gap: 1rem;
            &_title {
              font-weight: 500;
              width: 11rem;
            }
          }
        }
      }
      span {
        font-size: 1.4rem;
      }
    }
  }
}

.uploader {
  gap: 1rem;
  // max-width: 11rem;
  &-preview {
    position: relative;
    max-height: 8rem;
    max-width: 8rem;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    svg {
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;
      color: $danger;
      background-color: white;
      border-radius: 50%;
    }
  }
}

.collectionModal {
  background-color: white;
  padding: 2rem;
  max-width: 80vw;
  width: 700px;
  max-height: 80vh;

  overflow-y: auto;
}
