.resource_dialog {
  left: 25% !important;
  top: 2rem !important;
  height: 90vh;
  input {
    border: none;
    border-bottom: 2px solid $color_border;
    border-radius: 0%;
    &:focus {
      border-bottom: 2px solid $danger;
    }
  }

  select {
    border: none;
    border-bottom: 2px solid $color_border;
    background-color: transparent;
    border-radius: 0%;
    &:focus {
      border-bottom: 2px solid $danger;
    }
  }

  .dev_info {
    // background-color: darkgoldenrod;
    padding: 1rem 5rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 2rem;
    margin-bottom: 3rem;
    label {
      font-weight: 500;
    }
    div {
      display: flex;
      flex-direction: column;
    }
  }

  #confirmScraped {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .confirmDevSkills {
      flex-basis: 70%;
      overflow: auto;
    }
    &_btns {
      color: $color_prim;
      // background-color: turquoise;
      display: flex;
      justify-content: flex-end;
      gap: 2rem;
      margin-bottom: 2rem;
      // font-size: 6rem;
      align-items: flex-end;
      align-content: flex-end;
      div {
        display: flex;
        align-items: center;
        gap: 1rem;
        border-radius: 0.3rem;
        .icon_prev {
          margin-right: -0.7rem;
        }
        &:hover {
          cursor: pointer;
          //   color: $danger;
        }
      }
    }
    h1 {
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
    }
  }

  .confirmDevSkills {
    // background-color: darkgoldenrod;
    &_table {
      width: 100%;
      text-align: center;
      h5,
      h3 {
        font-weight: 600;
      }

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      tr {
        td {
          width: calc(100% / 5);
          // input{
          //     border: none;
          //     border-bottom: 2px solid $color_border;
          //     border-radius: 0%;
          //     &:focus{
          //         border-bottom: 2px solid $danger;
          //     }

          // }
          // select{

          // }
        }
      }
    }
  }
}
