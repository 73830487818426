.e-bigger #overviewgrid .statustxt {
  position: relative;
}
.rating {
  text-align: center;
}
.rating .star {
  font-size: 132%;
  color: lightgrey;
  text-align: center;
}

.rating .star.checked {
  color: #ffa600;
}

.rating .star:before {
  content: "★";
}

#overviewgrid td.e-rowcell.e-selectionbackground.e-active {
  background-color: transparent;
}

.e-image {
  height: 13px;
  width: 14px;
}

td.e-rowcell .statustxt.e-activecolor {
  color: #00cc00;
  position: relative;
}

td.e-rowcell .statustxt.e-inactivecolor {
  color: #e60000;
  position: relative;
}

.statustemp.e-inactivecolor {
  background-color: #ffd7cc;
  text-align: center;
}

.statustemp.e-activecolor {
  background-color: #ccffcc;
}

.statustxt.e-activecolor {
  color: #00cc00;
}

.statustxt.e-inactivecolor {
  color: #e60000;
}

.statustemp {
  position: relative;
  height: 19px;
  border-radius: 5px;
  text-align: center;
}

.e-dlg-overlay {
  position: fixed !important;
}
