.completed{
    display: flex;
    flex-direction: column;
    align-items: center;

    .RCP{
        position: relative;

        .indicator{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    ul{
        li{
            color: $danger;
        }
    }
    
}