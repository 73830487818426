.spmrg {
  &_sm {
    &-top {
      margin-top: 1rem;
    }
    &-btm {
      margin-bottom: 1rem;
    }
  }
  &_mid {
    &-top {
      margin-top: 5rem;
    }
    &-btm {
      margin-bottom: 5rem;
    }
  }
  &_lg {
    &-top {
      margin-top: 10rem;
    }
    &-btm {
      margin-bottom: 10rem;
    }
  }
}
.e-grid.e-gridhover tr.e-row:hover .e-rowcell:not(.e-active):not(.e-updatedtd) {
  background-color: rgb(135, 206, 250) !important;
  transition: background-color 2s;
}

.e-grid .e-toolbar .e-toolbar-items .e-toolbar-item .e-icons {
  color: black;
}

.e-grid .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  color: #1e96f5;
}

.e-grid .e-toolbar .e-toolbar-items .e-toolbar-item button:hover {
  background-color: white;
}

._spHover {
  &:hover {
    cursor: pointer;
  }
}
.animate-top {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
  from {
    bottom: 100px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    bottom: 100px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.spSlide-up {
  top: -100vh;
  left: 50%;
  visibility: hidden;
  // opacity: 0;
  // display: none;
  animation-duration: 1s;
  animation-name: slideup;
}

.spSlide-down {
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  visibility: visible;
  animation-duration: 1s;
  animation-name: slidedown;
}

@keyframes slidedown {
  from {
    top: -100vh;
    left: 50%;
    visibility: hidden;
  }
  to {
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    visibility: visible;
  }
}
@keyframes slideup {
  from {
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
    visibility: visible;
  }
  to {
    top: -100vh;
    left: 50%;
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}
