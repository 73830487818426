.spModal {
  .backdrop {
    position: fixed;
    top: 3%;
    right: 0;
    bottom: 0;
    left: 11vw;
    background-color: rgba(51, 51, 51, 0.3);
    backdrop-filter: blur(1px);
    opacity: 0;
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 200ms;
    display: flex;
    align-items: center;
    justify-content: center;

    & .modal-content {
      transform: translateY(100px);
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
    }

    &.active {
      transition-duration: 250ms;
      transition-delay: 0ms;
      opacity: 1;

      & .modal-content {
        transform: translateY(0);
        opacity: 1;
        transition-delay: 150ms;
        transition-duration: 350ms;
      }
    }
  }
  .content {
    position: relative;
    padding: 20px;
    box-sizing: border-box;

    min-height: 50px;

    min-width: 38rem;
    max-height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    max-width: 50vw;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: white;
    border-radius: 5px;
  }

  .SpModalHead {
    margin-bottom: 2rem;
    h3 {
      font-size: 1.8rem;
      font-weight: 600;
    }
    p {
      font-weight: 400;
    }
  }

  .SpModalFooter {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 1.3rem;
    button {
      padding: 5px 10px;
    }
    .spLoadingBtn {
      button {
        // font-size: 2rem;
        font-weight: 500;
      }
    }
    .spclose {
      background-color: $danger;
      color: white;
      border: none;
      border-radius: 2px;
    }
  }
}
