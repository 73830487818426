._sppdevAvail {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  margin-top: 2rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
  & > div {
    flex: 1;
    border: 1px solid #dfdfdf;
  }

  h3 {
    font-size: 2rem !important;
    font-weight: 600 !important;
    margin-bottom: 1rem;

    // color: $color-primary;
  }

  &__availability {
    &_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      background-color: #f8f8f8;
      padding: 1rem;
    }
    .custom-date-color {
      background-color: #4361e9;
      color: white;
    }
    table {
      // max-width: 700px;

      @media (max-width: 1440px) {
        // max-width: 700px;
      }
      th {
        min-width: 80px;
      }
      th:last-child {
        min-width: 200px;
      }

      thead {
        // background-color: #efefef;
        border-radius: 0.5rem;

        th {
          padding: 1rem 0.5rem;
          font-size: 1.2rem;
          font-weight: 600;

          &:not(:last-child) {
            text-align: center;
          }
        }
      }

      tbody {
        tr {
          &:not(:last-child) {
            border-bottom: 1px solid #adadad;
            padding: 1rem;
          }
          td {
            padding: 1.4rem 0.5rem;
            text-align: center;
            &:last-child {
              text-align: center;
              display: flex;
              gap: 1rem;
              align-items: center;

              //   justify-content: space-between;
            }

            &:first-child {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  &__eligib {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &_head {
      border-bottom: 1px solid #adadad;
      padding-bottom: 1rem;

      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        background-color: #f8f8f8;
        padding: 1rem;
      }

      & p {
        margin-top: 1.5rem !important;
        font-size: 1.4rem !important;
        font-weight: 400;
        padding: 0 10px;
      }
    }

    &_content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &--element {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 0 1rem;

        &:not(:last-child) {
          border-bottom: 1px solid #adadad;
        }

        & > span {
          font-weight: 600 !important;
        }
        &-content {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          flex-wrap: wrap;
          padding: 1rem 0;

          & > div {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            border: 1px solid #e6e8eb;
            border-radius: 25px;
            padding: 0.5rem;
            svg {
              opacity: 1 !important;
            }
          }

          button {
            border: 1px solid #e6e8eb;
            border-radius: 25px;
            padding: 0.5rem;
            font-size: 1.2rem;
            font-weight: 500;
            display: flex;
            gap: 0.5rem;
            align-items: center;
            background-color: transparent;

            svg {
              font-size: 1.8rem !important;
              width: 2rem !important;
              height: 2rem !important;
              opacity: 1 !important;
              fill: #4361e9 !important;
            }
          }
        }
      }
    }
  }
}
