.sp-ttp {
  position: relative;
  // overflow: auto;
  &_content {
    position: absolute;
    // top: 100%;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    z-index: 100;
    max-height: 25rem;
    padding: 15px 30px;
    overflow: auto;
    width: 40vw;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.005),
      0 6.7px 5.3px rgba(0, 0, 0, 0.07), 0 12.5px 10px rgba(0, 0, 0, 0.05),
      0 22.3px 17.9px rgba(0, 0, 0, 0.011),
      0 41.8px 33.4px rgba(0, 0, 0, 0.0125), 0 100px 80px rgba(0, 0, 0, 0.0175);
    border-radius: 3.5px;
    border: 1px solid rgba(128, 128, 128, 0.225) !important;
    transition: all 0.25s;

    opacity: 0;
    visibility: hidden;
    text-align: center;
  }
  &:hover &_content {
    opacity: 1;
    visibility: visible;
    bottom: 50%;
    right: 20%;
  }
}
