.loader {
  // position: absolute;
  // left: 50%;
  // top: 50%;
  z-index: 1;
  width: 15rem;
  height: 15rem;
  margin: -7.5rem 0 0 -7.5rem;
  border: 1.6rem solid #f3f3f3;
  border-radius: 50%;
  border-top: 1.6rem solid #3498db;
  width: 12rem;
  height: 12rem;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    }
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #3498db;
  border-color: #3498db transparent #3498db transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}