.marketSearch {
  width: 500px !important;
  padding: 35px 50px;
  background-color: white;
  border-radius: 15px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -65px;
  margin-bottom: -35px;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.1);
  z-index: 2;

  &_close {
    // background-color: $danger;
    // padding: 0.5rem;
    margin-bottom: 2rem;
    &-icon {
      color: $color_prim;
      font-size: 2rem !important;
      position: fixed;
      top: 1rem;
      right: 1rem;
      &:hover {
        cursor: pointer;
        color: $danger;
      }
    }
  }
  .infoOverflow {
    height: 65px;
    width: 65px;
    background-color: red;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    border-top-right-radius: 12.5px;
    .info {
      background-color: rgba(0, 0, 0, 0.055);
      height: 75px;
      width: 75px;
      border-radius: 50px;
      cursor: pointer;
      position: absolute;
      top: -20px;
      right: -20px;
      transition: background-color 0.125s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.125);
      }
      h3 {
        font-size: 1.75rem;
        position: absolute;
        top: 57.5%;
        left: 42.5%;
        transform: translate(-50%, -50%);
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .infoContent {
    position: absolute;
    top: 0;
    right: -53.75%;
    width: 300px;
    background-color: white;
    padding: 25px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    font-style: italic;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    z-index: 100;
    &.active {
      right: -54.75%;
      opacity: 1;
      visibility: visible;
    }
  }
  .btnSearch {
    border-radius: 5.5px;
    background-color: #2d3436;
    width: 100%;
    margin: 15px auto;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: background-color 0.2s;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #1d2122;
    }
    h3 {
      color: white;
      text-align: center;
      font-size: 1.5rem;
    }
  }
  input {
    width: 100%;
    padding: 10px;
    border: 2px solid #2d34362f;
    border-radius: 10px;
    margin: 15px 0;
    font-size: 1.35rem;
    font-weight: 500;
  }
  p {
    opacity: 0.55;
    font-weight: 500;
    text-align: center;
  }
  .stackDiv {
    display: flex;
    flex-wrap: wrap;
    .hrTitle {
      font-size: 1.15rem;
      margin-top: 15px;
      margin-bottom: -5px;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 700;
    }
    hr {
      width: 100%;
      border: none;
      height: 2px;
      background-color: rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
    }
  }
  .stackItem {
    display: flex;
    align-items: center;
    margin: 5px 5px 5px 0;
    width: fit-content;
    cursor: pointer;
    position: relative;
    transition: all 0.2s;
    padding: 2.5px 10px;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 15px;
      height: 100%;
      width: 0;
      background-color: rgba(0, 0, 0, 0.055);
      transition: width 0.2s cubic-bezier(0.55, 0, 0.1, 1);
    }
    h3 {
      color: #2d3436af;
      margin-left: 4.5px;
      font-size: 1.2rem;
    }
    img {
      height: 17.5px;
      width: 17.5px;
    }
  }
}

.marketSearch .selectedStackDiv {
  display: flex;
  flex-wrap: wrap;
}

.selectedStackItem,
.selectedVendor {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d3436b4;
  border-radius: 3.5px;
  padding: 2.5px 20px 2.5px 30px;
  position: relative;
  overflow: hidden;
  margin-left: 8.5px;
  margin-bottom: 8.5px;
  cursor: pointer;
  z-index: 1;
}

.selectedStackItem::after,
.selectedVendor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #e74c3c;
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
}

.selectedStackItem .close,
.selectedVendor .close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 100%;
  width: 20px;
  border-radius: 3.5px;
  background-color: #e74c3c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  cursor: pointer;
  z-index: 10;
  opacity: 0.35;
  transition: all 0.2s;
}

.selectedStackItem:hover .close,
.selectedVendor:hover .close {
  opacity: 1;
}
.selectedStackItem p,
.selectedVendor p {
  opacity: 1;
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
  z-index: 2;
}

.selectedStackItem p img,
.selectedVendor p img {
  width: 20px;
}

@media (max-width: 735px) {
  .marketSearch p {
    font-size: 0.925rem;
  }
  .marketSearch .btnSearch h3 {
    font-size: 1rem;
  }
}
@media (max-width: 665px) {
  .marketSearch {
    width: 98.5vw;
    padding: 25px;
    margin-bottom: 35px;
  }
  .marketSearch .info {
    display: none;
  }
  .marketSearch .stackItem h3,
  .marketSearch .hrTitle {
    font-size: 0.9rem;
    margin-left: 2.5px;
  }

  .marketSearch p,
  .marketSearch input {
    font-size: 0.925rem;
  }
}

.migrateDevOverlay {
  width: 92.5%;
  height: 133vh;
  opacity:0.5;
  background-color: Black;
  border-radius: 15px;
  position: absolute;
  top: 15%;
}
