.brand {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 80vh;
  // width: 85vw;

  &_body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &-btn {
      display: flex;
      gap: 1rem;
      .btn {
        background-color: $color_prim;
        color: white;
        font-weight: 600;
        &:hover {
          color: white;
        }
      }
    }
  }
  &_dialog {
    width: 100%;
    padding: 2rem;
    &-box {
      &__inputs {
        gap: 3rem;
        section {
          .description {
            p {
              font-weight: 500;
              font-size: 13.5px;
              opacity: 0.9;
              margin-bottom: 20px;
              margin-top: -5.5px;
            }
          }
          .info:not(:last-child) {
            min-height: 20rem;
          }
          .info {
            justify-content: space-between;
            &_row {
              justify-content: space-between;
              align-items: baseline;
              gap: 3rem;
              .info_input {
                flex: 1;
              }
              &-slides {
                flex: 40%;
                &-preview {
                  max-height: 30rem;
                  // overflow: hidden;
                  display: flex;
                  gap: 2rem;
                  padding: 0.5rem;

                  &__imgBox {
                    max-width: calc((100% / 8));
                    max-height: 5rem;
                    position: relative;

                    &--img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                    svg {
                      color: $danger;
                      position: absolute;
                      bottom: -10px;
                      right: -8px;
                    }
                  }
                }
              }
              &-main {
                flex: 10%;
                max-height: 30rem;
                &-preview {
                  padding: 0.5rem;
                  &_imgBox {
                    height: 30rem;
                    // background-color: red;
                    position: relative;
                    &__img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                    svg {
                      color: $danger;
                      position: absolute;
                      bottom: -10px;
                      right: -8px;
                    }
                  }
                }
              }
            }
            &_input {
              //
              label {
                font-size: 1.3rem;
                font-weight: 600;
              }
              input {
                padding: 0.5rem;
                min-width: 20rem;
                border: none;
                border-bottom: 2px solid $color_prim;
                border-radius: 0;
                font-size: 1.3rem;
                &:focus {
                  border: none;
                  border-bottom: 2px solid $danger;
                  border-radius: 0;
                  outline: none;
                }
              }
              textarea {
                min-height: 10rem;
                padding: 0.5rem;
                font-size: 1.3rem;
              }
            }
            &_column {
              gap: 2rem;
            }
            .title {
              max-width: 30rem;
            }
            button {
              background-color: $color_prim;
              color: white;
            }
            .avat {
              width: 20rem;
              max-height: 20rem;
            }
          }
        }
        .ref_stat {
          .info {
            gap: 5rem;
            .stat_item {
              flex: 1;
            }
          }
        }
      }
      &__actions {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;

        #addCase_sub {
          background-color: $color_prim;
          color: white;
          font-weight: 600;
        }
        #addCase_cancel {
          background-color: $danger;
          color: white;
          font-weight: 600;
        }
      }
    }
  }
  .contract_cont {
    .ctr {
      &_el_header {
        flex-direction: column;
        align-items: flex-start;
        .header-intro {
          display: flex;
          gap: 1rem;
        }
      }
    }
    .contract_bottom {
      p {
        color: $danger;
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
  }
}
