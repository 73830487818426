.spLoadingBtn {
  &-btn {
    background-color: $color_prim;
    border: none;
    font-size: 1.35rem;
    padding: 1rem 1.6rem;
    border-radius: 0.3rem;
    color: white;
    font-weight: 600;
  }
}
