.markets {
  &_content {
    &-yourPrice {
      margin-top: 5rem;
      &__head {
        gap: 1rem;
        align-items: center;

        h3 {
          font-weight: 600;
        }
      }
    }
    &-popularSearch {
      // background-color: antiquewhite;
      padding-bottom: 2rem;
      margin-top: 5rem;
      &__head {
        h3 {
          font-size: 2rem;
          font-weight: 700;
        }
      }

      .prices_table-header {
        width: 100% !important;
      }
      span {
        font-size: 1.35rem;
      }
      .keyword {
        width: 18vw;
        padding-right: 2rem;
        min-width: 16rem;
        // background-color: red;
      }
      .searched {
        // min-width: 6rem;
        width: 19vw;
        min-width: 14rem;
        padding-right: 2rem;
      }
      .yRates {
        width: 11vw;
        min-width: 12rem;
        padding-right: 2rem;
      }
      .cRates {
        width: 11vw;
        padding-right: 2rem;
      }
      .edit {
        width: 10vw;
      }
    }
  }
  &_newRate {
    .request_form-content {
      max-width: 80rem;
      gap: 2rem;
    }
    h2 {
      font-weight: 700;
    }
  }
}
