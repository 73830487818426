@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(7.5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.requirementsSteps {
  /* margin: 65px 0; */
  width: 100%;
  /* margin: 0px 150px; */
  margin-bottom: 50px;
}
.requirementsSteps .stepID {
  margin-right: 7.5px;
  font-size: 13.5px;
  background-color: #459df538;
  padding: 1.5px 10px;
  border-radius: 2.5px;
}
.requirementsSteps .stepID h5 {
  color: #459df5;
  font-weight: 700;
}
#date {
  margin-bottom: -10px;
}
.requirementsSteps .requirementCard {
  width: 100%;
  height: 305px;
  background-color: white;
  border-radius: 3.5px;
  margin-bottom: 25px;
  border: 2.15px solid rgba(71, 31, 31, 0.075);
  position: relative;
  padding: 20px;
  margin-right: 15px;
}
.requirementsSteps .requirementCard.one {
  height: 250px;
}
.requirementsSteps #priceCard {
  width: 400px;
}
.priceCardCtr {
  display: flex;
  overflow-x: auto;
  margin-bottom: 25px;
}
.priceCardCtr .requirementCard {
  margin-right: 15px;
}

.requirementsSteps h4 {
  font-weight: 600;
  font-size: 13.5px;
  margin-bottom: 6.5px;
}
.requirementsSteps .requirementCard .dateStackCtr {
  display: flex;
  justify-content: space-between;
}
.requirementsSteps .requirementCard .dateDiv {
  display: flex;
  margin-bottom: 25px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem {
  width: 235px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr {
  display: flex;
  align-items: center;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr .helpCtr {
  position: relative;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .qaCtr
  .helpCtr
  .helpContent {
  position: absolute;
  top: 110%;
  left: 10px;
  padding: 12.5px;
  padding-right: 20px;
  background-color: #2d3436;
  border-radius: 3.5px;
  box-shadow: 0 2.8px 2.2px rgba(77, 69, 69, 0.009),
    0 6.7px 5.3px rgba(0, 0, 0, 0.012), 0 12.5px 10px rgba(0, 0, 0, 0.015),
    0 22.3px 17.9px rgba(0, 0, 0, 0.018), 0 41.8px 33.4px rgba(0, 0, 0, 0.02),
    0 100px 80px rgba(0, 0, 0, 0.003);
  width: 300px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.175s;
  z-index: 1;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .qaCtr
  .helpCtr:hover
  .helpContent {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .qaCtr
  .helpCtr
  .helpContent
  p {
  font-weight: 500;
  font-size: 14px;
  color: white;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr .icon {
  fill: rgba(128, 128, 128, 0.5);
  margin-left: 7.5px;
  margin-top: -3.5px;
  cursor: pointer;
  font-size: 18px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem h4 {
  color: #459df5;
  font-size: 13.5px;
  margin: 0;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem {
  background-color: #459df517;
  padding: 1.5px;
  padding-left: 10px;
  border-radius: 2.5px;
  width: fit-content;
  width: 135px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .editPrice {
  background-color: white;
  padding: 1.5px;
  padding-left: 10px;
  border-radius: 2.5px;
  width: 135px;
  height: 19px;
  border: 2.15px solid rgba(71, 31, 31, 0.15);
}
.requirementsSteps .requirementCard .dateDiv .dateItem .editCtr {
  display: flex;
  position: relative;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .editCtr .errorMessage {
  position: absolute;
  top: 125%;
  left: 0px;
  padding: 12.5px;
  padding-right: 20px;
  background-color: #2d3436;
  border-radius: 3.5px;
  box-shadow: 0 2.8px 2.2px rgba(77, 69, 69, 0.009),
    0 6.7px 5.3px rgba(0, 0, 0, 0.012), 0 12.5px 10px rgba(0, 0, 0, 0.015),
    0 22.3px 17.9px rgba(0, 0, 0, 0.018), 0 41.8px 33.4px rgba(0, 0, 0, 0.02),
    0 100px 80px rgba(0, 0, 0, 0.003);
  width: 300px;
  /* visibility: hidden;
  opacity: 0; */
  transition: all 0.175s;
  z-index: 1;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .editCtr input {
  background-color: white;
  padding: 1.5px;
  padding-left: 5px;
  border-radius: 2.5px;
  width: 135px;
  height: 19px;
  border: 2.15px solid rgba(71, 31, 31, 0.15);
  font-size: 13px;
  font-weight: 600;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .editCtr
  .dropdownOptions {
  position: absolute;
  top: 125%;
  left: 0;
  width: 215px;
  background-color: white;
  border-radius: 3.5px;
  box-shadow: 0 2.8px 2.2px rgba(77, 69, 69, 0.009),
    0 6.7px 5.3px rgba(0, 0, 0, 0.012), 0 12.5px 10px rgba(0, 0, 0, 0.015),
    0 22.3px 17.9px rgba(0, 0, 0, 0.018), 0 41.8px 33.4px rgba(0, 0, 0, 0.02),
    0 100px 80px rgba(0, 0, 0, 0.003);
  z-index: 1;
  border: 2.15px solid rgba(128, 128, 128, 0.15);
  padding: 10px 0;
  visibility: visible;
  /* opacity: 0; */
}
/* .requirementsSteps .requirementCard .editCtr .edit:hover ~ .dropdownOptions,
.requirementsSteps .requirementCard .dropdownOptions:hover {
  top: 110%;
  visibility: visible;
  opacity: 1;
} */
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .editCtr
  .dropdownOptions
  h4 {
  color: grey;
  padding: 3.5px 15px;
  font-size: 13.5px;
  cursor: pointer;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .editCtr
  .dropdownOptions
  h4:hover {
  color: inherit;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .edit {
  background-color: #2d3436;
  height: 18px;
  width: 18px;
  padding: 2.5px;
  fill: white;
  border-radius: 2.5px;
  margin-left: 2.5px;
  opacity: 0.85;
  cursor: pointer;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .highlightItem.dropdown
  h4 {
  color: grey;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem.dropdown {
  background-color: rgba(128, 128, 128, 0.125);
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem.price h4 {
  color: #3e9416;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem.price {
  background-color: #4bbb1723;
}
.highlightItem.criteria {
  background-color: transparent !important;
  padding: 0 !important;
  display: flex;
  justify-content: flex-end;
}
.highlightItem.criteria img {
  height: 22.5px;
  width: 22.5px;
  margin-left: 12.5px;
}
.dateDiv p {
  font-size: 13.5px;
  margin-left: 5px;
  font-weight: 500;
}
.dateDiv span {
  color: #459df5;
  font-weight: 600;
}
.requirementsSteps .requirementCard .dateDiv .dateItem h2 {
  margin-top: -4px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .experienceItem {
  display: flex;
  align-items: center;
  margin: 12.5px 0;
  width: 450px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .experienceItem img {
  height: 18.5px;
  width: 18.5px;
  margin-right: 7.5px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .experienceItem h4 {
  margin: 0;
  font-size: 13.5px;
}
#large {
  width: 450px;
}
#flexEnd {
  justify-content: flex-end;
  align-items: flex-end;
  width: fit-content;
}
.dev {
  display: flex;
}
#sb {
  width: 125px;
}
/* .emptyDev .dev {
  margin: 20px 0 !important;
} */
.dev:last-child {
  margin-bottom: 0;
}
.devImg {
  height: 40px;
  width: 40px;
  background-color: rgba(128, 128, 128, 0.1);
  border-radius: 50%;
  margin-right: 7.5px;
  position: relative;
  overflow: hidden;
  border: 2px solid rgba(128, 128, 128, 0.15);
}
.devImg .devImg_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.devImg .icon {
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 40px;
  opacity: 0.75;
}
.dev h4 {
  margin-bottom: 0;
}
.dev p {
  font-size: 13.5px;
  opacity: 0.55;
  font-weight: 500;
  margin-bottom: 8.5px;
}
.dev .starCtr {
  display: flex;
  margin-top: -15px;
}
#ratePrompt {
  font-weight: 500;
  padding: 1px 15px;
  background-color: #2d3436;
  color: white;
  cursor: pointer;
  opacity: 0.85;
  border-radius: 1.5px;
  font-size: 12px;
  margin-right: -25px;
}
.dev .starCtr .star {
  fill: #459df5;
}
.requirementCard.devs {
  width: 400px;
  height: fit-content;
  padding: 35px;
}
.requirementCard.devs button {
  background-color: red;
  border: none;
  background-color: #2d3436;
  border-radius: 2.5px;
  margin-bottom: 15px;
  color: white;
  font-weight: 600;
  opacity: 0.85;
  font-size: 11.5px;
  padding: 2px;
}
.dev .priceCtr {
  margin-left: 30px;
}
.dev .thumb {
  font-size: 25px;
  margin-left: 10px;
  opacity: 0.25;
}
.resourceCtr {
  display: flex;
  overflow-x: auto;
  margin-bottom: 25px;
}
.resourceCtr .infoBox {
  width: 400px;
  height: fit-content;
  border-radius: 3.5px;
  background-color: rgba(128, 128, 128, 0.075);
  margin-left: 30px;
  padding: 15px;
}
.scoreChip {
  position: absolute;
  bottom: 15px;
  left: 20px;
  background-color: #459df52a;
  padding: 1.5px 15px;
  border-radius: 2.5px;
}
.scoreChip h4 {
  color: #459df5;
  margin: 0;
  font-size: 13.5px;
}
.infoBox .infoBoxContent {
  display: flex;
}
.infoBox .infoBoxContent p {
  font-size: 14px;
  margin-left: 15px;
  font-weight: 500;
  opacity: 0.65;
}
.infoBox .infoBoxContent span {
  font-weight: 700;
  opacity: 1;
  color: black;
}
.infoBox .infoBoxContent ul {
  padding-left: 30px;
  margin-top: 15px;
}
.infoBox .infoBoxContent li {
  margin-bottom: 5.5px;
}
.infoBox .infoBoxContent li p {
  margin-left: 0;
}
.confirmationCtr {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.confirmationCtr .confirmationCtrContent {
  width: 550px;
  background-color: rgb(248, 248, 248);
  border-radius: 5px;
  animation: fadeInUp;
  animation-fill-mode: both;
  animation-duration: 0.325s;
  animation-delay: 0.1s;
  animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}
.confirmationCtr .confirmationCtrContent .top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: white;
  border-radius: 5px 5px 5px 5px;
}
.confirmationCtr .confirmationCtrContent .top .warning {
  fill: #e74c3c;
  font-size: 32.5px;
  margin: 7.5px 0;
}
.confirmationCtr .confirmationCtrContent .top h2 {
  font-weight: 600;
  color: #e74c3c;
  font-size: 20.5px;
}
.confirmationCtr .confirmationCtrContent .bottom {
  padding: 35px;
}
.confirmationCtr .confirmationCtrContent .bottom h4 {
  font-size: 13.5px;
}
.confirmationCtr .confirmationCtrContent .bottom ul {
  padding-left: 35px;
  margin: 15px 0;
}
.confirmationCtr .confirmationCtrContent .bottom ul li {
  font-size: 15px;
  font-weight: 500;
  opacity: 0.75;
  margin: 5px 0;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr button {
  padding: 10px 15px;
  border-radius: 3.5px;
  width: 165px;
  margin-right: 15px;
  font-weight: 500;
  font-size: 13.5px;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr #yes {
  background-color: #2d3436;
  opacity: 0.85;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr #no {
  background-color: transparent;
  border: 2px solid grey;
  color: grey;
  opacity: 0.425;
}
#rate {
  margin-bottom: 13.5px !important;
}
.confirmationCtr #rate .top {
  align-items: flex-start;
  padding: 25px;
  height: fit-content;
}
.confirmationCtr #rate .top .active {
  border-radius: 3.5px;
  width: 65px;
  height: 6px;
  background-color: #2d3436;
}
.confirmationCtr #rate .top .activityCtr {
  display: flex;
  align-items: center;
}
.confirmationCtr #rate .top .activityCtr .thumb {
  fill: #2d343683;
  border: 1.85px solid rgba(128, 128, 128, 0.35);
  border-radius: 5px;
  font-size: 31.5px;
  padding: 5px;
  margin-right: 5px;
  cursor: pointer;
  transition: all 0.15s;
}
.confirmationCtr #rate .top .activityCtr .checkC {
  font-size: 22.5px;
  fill: #27ae60;
}
.confirmationCtr #rate .top .activityCtr .checkE {
  font-size: 17.5px;
  fill: #459df5;
}
.confirmationCtr #rate .top .activityCtr span {
  text-decoration: underline;
  cursor: pointer;
}
.confirmationCtr #rate .top .activityCtr .thumb.chosen {
  fill: white;
  border: none;
  background-color: #2d3436;
  padding: 8px;
}
.confirmationCtr #rate .top .inactive {
  border-radius: 3.5px;
  width: 42.5px;
  height: 6px;
  background-color: #2d343621;
}
.confirmationCtr #rate .top h4 {
  font-size: 15.25px;
  margin: 0;
  letter-spacing: -0.5px;
  margin: 12.5px 0;
  font-weight: 500;
}
.confirmationCtr #rate .top textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1.95px solid rgba(128, 128, 128, 0.25);
  width: 100%;
  min-height: 150px;
  font-size: 13.5px;
  font-weight: 500;
}
.confirmationCtr #rate .top button {
  background-color: #2d3436;
  padding: 5px 35px;
  border-radius: 4.5px;
}
.confirmationCtr #rate .top textarea::placeholder {
  font-size: 13.5px;
  opacity: 0.55;
  font-weight: 500;
}
.emptyDev {
  margin-bottom: 0px;
}
.emptyDev p {
  font-weight: 500;
  opacity: 0.55;
  font-style: italic;
  font-size: 13px;
  margin-bottom: 15px;
  margin-top: -5px;
}
.emptyDev .noDevP {
  padding: 7.5px 20px;
  background-color: rgba(128, 128, 128, 0.25);
  margin-top: 7.5px;
  border-radius: 1.5px;
}
/* .emptyDev:last-child p {
  margin-bottom: 5px;
} */
.emptyDev button {
  background-color: #2d3436;
  border-radius: 2.5px;
  margin-top: 5px;
  font-size: 12.5px;
  padding: 5px 15px;
}

.requirementsProgress {
  height: 5.5px;
  background-color: rgba(128, 128, 128, 0.25);
  /* margin: 0 150px; */
  margin-right: 25px;
  border-radius: 3.5px;
  position: relative;
  width: 95%;
}
#inactive {
  background-color: rgba(128, 128, 128, 1);
}
.currentProgress.one {
  width: 0%;
  height: 100%;
  background-color: #2e86dea1;
}
.currentProgress.two {
  width: 50%;
  height: 100%;
  background-color: #2e86dea1;
}
.requirementsProgress .progress {
  position: absolute;
  height: 22.5px;
  width: 50px;
  background-color: #2e86de;
  border-radius: 1.5px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.requirementsProgress .progress .progressTT {
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #2d3436;
  border-radius: 2.5px;
  padding: 2px 15px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.175s;
  width: max-content;
  min-width: 150px;
  max-width: 265px;
}
.requirementsProgress .progress:hover .progressTT {
  opacity: 1;
  visibility: visible;
  top: 112.5%;
}
.requirementsProgress .progress .progressTT p {
  color: white;
  font-weight: 600;
  font-size: 12.5px;
  text-align: center;
}
.requirementsProgress .progress h4 {
  color: white;
  font-size: 11px;
  margin: 0 !important;
}
.requirementsProgress .progress.one {
  left: 0;
}
.requirementsProgress .progress.two {
  left: 50%;
}
.requirementsProgress .progress.three {
  left: 100%;
}
/* .requirementsProgress .progress.four {
  left: 99%;
}
.requirementsProgress .progress.five {
  left: 99%;
} */

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(7.5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.requirementsSteps {
  /* margin: 65px 0; */
  width: 100%;
  /* margin: 0px 150px; */
  margin-bottom: 50px;
}
#date {
  margin-bottom: -10px;
}
.requirementsSteps .requirementCard {
  width: 100%;
  height: 335px;
  background-color: white;
  border-radius: 3.5px;
  margin-bottom: 25px;
  border: 2.15px solid rgba(71, 31, 31, 0.075);
  position: relative;
  padding: 20px;
}
.requirementsSteps #priceCard {
  width: 375px;
}
.priceCardCtr {
  display: flex;
}
.priceCardCtr .requirementCard {
  margin-right: 15px;
}
.requirementsSteps .requirementCard .confirm {
  position: absolute;
  bottom: 20px;
  right: 20px;
  border-radius: 3px;
  padding: 2px 25px;
  width: 150px;
  font-size: 12.5px;
  font-weight: 600;
  background-color: #1e272edc;
  text-align: center;
  color: white;
}
#flexEnd svg {
  opacity: 0.4;
}
.requirementsSteps .requirementCard .confirm.confirmed {
  background-color: #2ecc71;
}
.requirementsSteps h4 {
  font-weight: 600;
  margin-bottom: 6.5px;
  font-size: 13.5px;
}
.requirementsSteps .requirementCard .dateStackCtr {
  display: flex;
  justify-content: space-between;
}
.requirementsSteps .requirementCard .dateDiv {
  display: flex;
  margin-bottom: 25px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem {
  width: 235px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr {
  display: flex;
  align-items: center;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr .helpCtr {
  position: relative;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .qaCtr
  .helpCtr
  .helpContent {
  position: absolute;
  top: 110%;
  left: 10px;
  padding: 12.5px;
  padding-right: 20px;
  background-color: #2d3436;
  border-radius: 3.5px;
  box-shadow: 0 2.8px 2.2px rgba(77, 69, 69, 0.009),
    0 6.7px 5.3px rgba(0, 0, 0, 0.012), 0 12.5px 10px rgba(0, 0, 0, 0.015),
    0 22.3px 17.9px rgba(0, 0, 0, 0.018), 0 41.8px 33.4px rgba(0, 0, 0, 0.02),
    0 100px 80px rgba(0, 0, 0, 0.003);
  width: 300px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.175s;
  z-index: 1;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .qaCtr
  .helpCtr:hover
  .helpContent {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .qaCtr
  .helpCtr
  .helpContent
  p {
  font-weight: 500;
  font-size: 14px;
  color: white;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr .icon {
  fill: rgba(128, 128, 128, 0.5);
  margin-left: 7.5px;
  margin-top: -3.5px;
  cursor: pointer;
  font-size: 18px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem h4 {
  color: #459df5;
  font-size: 13.5px;
  margin: 0;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem {
  background-color: #459df517;
  padding: 1.5px;
  padding-left: 10px;
  border-radius: 2.5px;
  width: fit-content;
  width: 135px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .editPrice {
  background-color: white;
  padding: 1.5px;
  padding-left: 10px;
  border-radius: 2.5px;
  width: 135px;
  height: 19px;
  border: 2.15px solid rgba(71, 31, 31, 0.15);
}
.requirementsSteps .requirementCard .dateDiv .dateItem .editCtr {
  display: flex;
  position: relative;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .editCtr .errorMessage {
  position: absolute;
  top: 125%;
  left: 0px;
  padding: 12.5px;
  padding-right: 20px;
  background-color: #2d3436;
  border-radius: 3.5px;
  box-shadow: 0 2.8px 2.2px rgba(77, 69, 69, 0.009),
    0 6.7px 5.3px rgba(0, 0, 0, 0.012), 0 12.5px 10px rgba(0, 0, 0, 0.015),
    0 22.3px 17.9px rgba(0, 0, 0, 0.018), 0 41.8px 33.4px rgba(0, 0, 0, 0.02),
    0 100px 80px rgba(0, 0, 0, 0.003);
  width: 300px;
  /* visibility: hidden;
  opacity: 0; */
  transition: all 0.175s;
  z-index: 1;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .editCtr input {
  background-color: white;
  padding: 1.5px;
  padding-left: 5px;
  border-radius: 2.5px;
  width: 135px;
  height: 19px;
  border: 2.15px solid rgba(71, 31, 31, 0.15);
  font-size: 13px;
  font-weight: 600;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .editCtr
  .dropdownOptions {
  position: absolute;
  top: 125%;
  left: 0;
  width: 215px;
  background-color: white;
  border-radius: 3.5px;
  box-shadow: 0 2.8px 2.2px rgba(77, 69, 69, 0.009),
    0 6.7px 5.3px rgba(0, 0, 0, 0.012), 0 12.5px 10px rgba(0, 0, 0, 0.015),
    0 22.3px 17.9px rgba(0, 0, 0, 0.018), 0 41.8px 33.4px rgba(0, 0, 0, 0.02),
    0 100px 80px rgba(0, 0, 0, 0.003);
  z-index: 1;
  border: 2.15px solid rgba(128, 128, 128, 0.15);
  padding: 10px 0;
  visibility: visible;
  /* opacity: 0; */
}
/* .requirementsSteps .requirementCard .editCtr .edit:hover ~ .dropdownOptions,
.requirementsSteps .requirementCard .dropdownOptions:hover {
  top: 110%;
  visibility: visible;
  opacity: 1;
} */
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .editCtr
  .dropdownOptions
  h4 {
  color: grey;
  padding: 3.5px 15px;
  font-size: 13.5px;
  cursor: pointer;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .editCtr
  .dropdownOptions
  h4:hover {
  color: inherit;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .edit {
  background-color: #2d3436;
  height: 18px;
  width: 18px;
  padding: 2.5px;
  fill: white;
  border-radius: 2.5px;
  margin-left: 2.5px;
  opacity: 0.85;
  cursor: pointer;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .highlightItem.dropdown
  h4 {
  color: grey;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem.dropdown {
  background-color: rgba(128, 128, 128, 0.125);
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem.price h4 {
  color: #3e9416;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem.price {
  background-color: #4bbb1723;
}
.highlightItem.criteria {
  background-color: transparent !important;
  padding: 0 !important;
  display: flex;
  justify-content: flex-end;
}
.highlightItem.criteria img {
  height: 22.5px;
  width: 22.5px;
  margin-left: 12.5px;
}
.dateDiv p {
  font-size: 13.5px;
  margin-left: 5px;
  font-weight: 500;
}
.dateDiv span {
  color: #459df5;
  font-weight: 600;
}
.requirementsSteps .requirementCard .dateDiv .dateItem h2 {
  margin-top: -4px;
  font-weight: 700;
  letter-spacing: -1px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .experienceItem {
  display: flex;
  align-items: center;
  margin: 12.5px 0;
  width: 450px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .experienceItem img {
  height: 18.5px;
  width: 18.5px;
  margin-right: 7.5px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .experienceItem h4 {
  margin: 0;
  font-size: 13.5px;
}
#large {
  width: 450px;
}
#flexEnd {
  justify-content: flex-end;
  align-items: flex-end;
  width: fit-content;
}
.dev {
  margin-bottom: 35px;
  display: flex;
}
/* .emptyDev .dev {
  margin: 15px 0 !important;
} */
.dev:last-child {
  margin-bottom: 0;
}
.devImg {
  height: 55px;
  width: 55px;
  background-color: rgba(128, 128, 128, 0.1);
  border-radius: 50%;
  margin-right: 16.5px;
  position: relative;
  overflow: hidden;
  border: 2px solid rgba(128, 128, 128, 0.15);
}
.devImg .icon {
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 55px;
  opacity: 0.75;
}
.dev h4 {
  margin-bottom: 0;
}
.dev p {
  font-size: 13.5px;
  opacity: 0.55;
  font-weight: 500;
  margin-bottom: 8.5px;
}
.dev .starCtr {
  display: flex;
}
.dev .starCtr .star {
  fill: #459df5;
}
.requirementCard.devs {
  width: 375px;
  height: fit-content;
  padding: 35px;
}
.dev .priceCtr {
  margin-left: 30px;
}
.dev .thumb {
  font-size: 25px;
  margin-left: 10px;
  opacity: 0.25;
}
.resourceCtr {
  display: flex;
}
.resourceCtr .infoBox {
  width: 500px;
  height: fit-content;
  border-radius: 3.5px;
  background-color: rgba(128, 128, 128, 0.075);
  margin-left: 30px;
  padding: 15px;
}
.scoreChip {
  position: absolute;
  bottom: 15px;
  left: 20px;
  background-color: #459df52a;
  padding: 1.5px 15px;
  border-radius: 2.5px;
}
.scoreChip h4 {
  color: #459df5;
  margin: 0;
  font-size: 13.5px;
}
.infoBox .infoBoxContent {
  display: flex;
}
.infoBox .infoBoxContent p {
  font-size: 14px;
  margin-left: 15px;
  font-weight: 500;
  opacity: 0.65;
}
.infoBox .infoBoxContent span {
  font-weight: 700;
  opacity: 1;
  color: black;
}
.infoBox .infoBoxContent ul {
  padding-left: 30px;
  margin-top: 15px;
}
.infoBox .infoBoxContent li {
  margin-bottom: 5.5px;
}
.infoBox .infoBoxContent li p {
  margin-left: 0;
}
.confirmationCtr {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirmationCtr .confirmationCtrContent {
  width: 550px;
  background-color: rgb(248, 248, 248);
  border-radius: 3.5px;
  animation: fadeInUp;
  animation-fill-mode: both;
  animation-duration: 0.325s;
  animation-delay: 0.1s;
  animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}
.confirmationCtr .confirmationCtrContent .top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: white;
  border-radius: 3.5px 3.5px 0 0;
}
.confirmationCtr .confirmationCtrContent .top .warning {
  fill: #e74c3c;
  font-size: 32.5px;
  margin: 7.5px 0;
}
.confirmationCtr .confirmationCtrContent .top h2 {
  font-weight: 600;
  color: #e74c3c;
  font-size: 20.5px;
}
.confirmationCtr .confirmationCtrContent .bottom {
  padding: 35px;
}
.confirmationCtr .confirmationCtrContent .bottom h4 {
  font-size: 13.5px;
}
.confirmationCtr .confirmationCtrContent .bottom ul {
  padding-left: 35px;
  margin: 15px 0;
}
.confirmationCtr .confirmationCtrContent .bottom ul li {
  font-size: 15px;
  font-weight: 500;
  opacity: 0.75;
  margin: 5px 0;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr button {
  padding: 10px 15px;
  border-radius: 3.5px;
  width: 165px;
  margin-right: 15px;
  font-weight: 500;
  font-size: 13.5px;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr #yes {
  background-color: #2d3436;
  opacity: 0.85;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr #no {
  background-color: transparent;
  border: 2px solid grey;
  color: grey;
  opacity: 0.425;
}
.emptyDev {
  margin-bottom: 0px;
}
.emptyDev p {
  font-weight: 500;
  opacity: 0.55;
  font-style: italic;
  font-size: 13px;
  margin-bottom: 15px;
  margin-top: -5px;
}
.emptyDev .noDevP {
  padding: 7.5px 20px;
  background-color: rgba(128, 128, 128, 0.25);
  margin-top: 7.5px;
  border-radius: 1.5px;
}
.cancel-btn {
  background-color: #2d3436;
  border: none;
  padding: 1.5px 12.5px;
  border-radius: 2.5px;
  color: white;
  font-weight: 600;
  font-size: 13.5px;
  margin-top: 20px;
}
#matching-popup h2 {
  font-weight: 700;
  font-size: 20.5px;
}
#matching-popup p {
  font-weight: 500;
  font-size: 14.5px;
  opacity: 0.55;
  line-height: 125%;
  letter-spacing: -0.5px;
}
/* .emptyDev:last-child p {
  margin-bottom: 5px;
} */
.emptyDev button {
  background-color: #2d3436;
  border-radius: 2.5px;
  margin-top: 5px;
  font-size: 12.5px;
  padding: 5px 15px;
}

.requirement-ctr {
  display: flex;
  margin-top: 50px;
}

.infoBox.requirement {
  margin-left: 150px;
  margin-top: 100px;
}

.requirementsHeader {
  width: 100vw;
  height: 275px;
  background: linear-gradient(to left, #343536, #181b1c);
  position: relative;
  overflow: hidden;
}
.requirementsHeader img {
  position: absolute;
  height: auto;
  width: 250px;
  right: 150px;
  bottom: 0;
  box-shadow: 0 20px 150px rgb(8 112 184 / 70%);
}
.requirementsHeader h1 {
  position: absolute;
  color: white;
  bottom: 50px;
  left: 150px;
  font-size: 3rem;
  letter-spacing: -1px;
}

.requirementsListCtr {
  margin: 35px 150px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.infoBox {
  background-color: rgb(232, 232, 255);
  border-radius: 5px;
  margin-bottom: 25px;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  width: 650px;
}
.infoBox p {
  color: rgb(159, 159, 197);
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
}
.requirementsListCtr .listHeader {
  display: flex;
  border-bottom: 1.5px solid rgba(128, 128, 128, 0.2);
  height: fit-content;
  padding: 10px 0;
  cursor: pointer;
}
.requirementsListCtr .listHeader.items {
  border: none;
}
.requirementsListCtr .listHeader .small {
  min-width: 100px;
  margin-right: 10px;
  overflow: visible;
}
.requirementsListCtr .listHeader .med {
  min-width: 145px;
  margin-right: 10px;
  overflow: visible;
}
.requirementsListCtr .listHeader .large {
  min-width: 265px;
  margin-right: 10px;
  overflow: visible;
}
.requirementsListCtr .listHeader p {
  font-size: 12px;
  font-weight: 500;
}
.requirementsListCtr .listHeader.items p {
  font-size: 13.85px;
  font-weight: 600;
}
.requirementsListCtr .listHeader.items .activeChip {
  color: #27ae60;
  background-color: #2ecc702a;
  border-radius: 3px;
  padding: 1.5px 10px;
  text-align: center;
  width: 125px;
  font-size: 12px;
  text-transform: uppercase;
}
.requirementsListCtr .listHeader.items .activeChip.inactive {
  color: #2e86de;
  background-color: #2e86de1f;
  border-radius: 3px;
  padding: 1.5px 10px;
  width: 125px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
}
.errorMessage {
  padding: 45px 0;
  font-style: italic;
  font-weight: 500;
  opacity: 0.5;
  font-size: 14px;
}
.sptable.requirements_all-table .sptable_container-header td {
  text-transform: uppercase;
  opacity: 0.5;
  vertical-align: top;
}
.row.requirements_all-top__filter {
  align-items: center;
  margin-bottom: 30px !important;
  margin-top: -25px !important;
  margin-right: 15px !important;
}
.row.requirements_all-top__filter select {
  background-color: #ecf0f1 !important;
  padding: 2.5px 10px;
}
.row.requirements_all-top__filter h4 {
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-right: 7.5px;
  opacity: 0.85;
}

.sptable.requirements_all-table hr {
  width: 100%;
}
.sptable.requirements_all-table .sptable_container-body tr {
  font-weight: 600;
  letter-spacing: -0.5px;
}
/* .sptable.requirements_all-table .sptable_container-body td {
  padding-bottom: 6.5px !important;
  vertical-align: baseline;
} */
.sptable.requirements_all-table .sptable_container-body .status {
  border-radius: 1.5px;
  margin: 3.5px 0 !important;
  background-color: #f39c12;
}
.viewbtn {
  color: white;
  border: none;
  background-color: #181b1c;
  padding: 2.5px 10px;
  border-radius: 2.5px;
  opacity: 0.85;
  font-size: 10px;
}
.requirements .row.desc {
  flex-direction: column;
  align-items: flex-start;
}
.requirements .row.desc .desc-slot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}
.requirements .row.desc .desc-slot h3 {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0;
}
.requirements .row.desc .desc-slot .desc-slot_num {
  padding: 1.5px 10px;
  border-radius: 2.5px;
  color: #2e86de;
  background-color: #2e86de1f;
  font-size: 13.5px;
  font-weight: 700 !important;
}
.requirements_agreement {
  background-color: yellow;
  width: fit-content;
  padding: 10px 35px 10px 15px;
  border-radius: 1.5px;
  margin-top: 15px;
  border: 1px solid rgba(128, 128, 128, 0.15);
  background-color: rgba(128, 128, 128, 0.075);
}
.requirements_agreement-content,
.requirements_agreement-signBy {
  font-size: 1.35rem;
  font-weight: 500;
  color: grey;
  letter-spacing: -0.25px;
}
.requirements_agreement-content span,
.requirements_agreement-signBy span {
  font-weight: 600;
}
.requirements_agreement-signBy,
.requirements_agreement-noSign {
  margin-bottom: 0;
}
.requirements_agreement-noSign {
  font-style: italic;
}
.clientType_tags_tag {
  background-color: #2e86de20 !important;
  border: 1px solid #2e86de;
  font-size: 10.5px !important;
  color: #2e86de !important;
  text-transform: uppercase;
  border-radius: 1.5px !important;
  margin-right: 3.5px;
}
.row.star #score {
  margin-left: 3.5px;
  color: #2e86de;
}
.row.star .star {
  fill: #2e86de;
}
.status {
  font-size: 10.5px !important;
  width: 80px;
}
.sptable.requirements_all-table .sptable_container-body .status {
  background: none !important;
  font-weight: 700 !important;
}
.chip-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 150px;
  min-width: 80px;
}
.chip-wrapper span {
  margin-bottom: 5px;
}
.bookedDevs_body_notice,
.bookedDevs_body_box {
  background-color: white;
}
.bookedDevs_body_box--top,
.bookedDevs_body_notice h3 {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.35px;
  margin-bottom: 25px;
}
.bookedDevs_body_notice h3 {
  margin-bottom: 0;
  margin-left: 5px;
  color: #e74c3c;
}
.bookedDevs_body_notice ul {
  padding-left: 15px;
}
.bookedDevs_body_notice ul li {
  font-size: 13px;
  font-weight: 500;
  /* opacity: 0.55; */
  letter-spacing: 0;
}
.bookedDevs_body_notice .notice-ctr {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.bookedDevs_body tr th,
#mr {
  padding-left: 35px;
}
.bookedDevs_body tr th {
  opacity: 0.55;
}
#mr {
  font-size: 13.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  text-transform: uppercase;
}
.requirements_single-content-steps .bookedDevs_body_box {
  flex-basis: 100%;
}
.bookedDevs_body tr td {
  vertical-align: top;
}
.bookedDevs_body .star {
  fill: #2e86de;
}
.bookedDevs_body .starCtr {
  margin-top: -7.5px;
}
.bookedDevs_body .icon.devImg {
  bottom: 0 !important;
  font-size: 20px !important;
  margin-right: 6.5px !important;
}
.bookedDevs_body_notice {
  margin: 10px 0 20px 0;
}
.requirementCard select {
  font-size: 11.5px;
  font-weight: 600 !important;
  letter-spacing: 0;
}
.log p {
  font-size: 13px !important;
  font-weight: 500;
  letter-spacing: -0.15px;
  opacity: 0.55;
  margin-bottom: 0;
}
#fs {
  align-items: flex-start;
}
#fs p {
  margin-left: 0;
  margin-bottom: 2px;
}
#th {
  font-size: 11.5px;
  margin-right: 3.5px;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-top: 3.5px;
}
.row a {
  font-weight: 600;
}
.emptyDev.column h4 {
  color: #212529;
}
#price {
  opacity: 0.55;
  margin: 2.5px 0;
}
.star-rating-ctr {
  display: flex;
  align-items: center;
}
.star-rating-ctr h4 {
  color: #2e86de !important;
  margin: -13.5px 0 0 5px;
  font-size: 12px;
}
.starCtr #ratePrompt {
  background-color: #f7927b54;
  border: 0.1rem solid #fa7e63;
  color: #632011;
  font-weight: 600;
  font-size: 10.5px;
  margin-top: 15px;
}
.starCtr #ratePrompt:hover {
  background-color: #fa7e63;
  border: none;
  color: #fff;
  box-shadow: 2px 2px 3px rgb(0 0 0 / 70%);
}
.devImg {
  margin-right: 10px;
}
.star,
.star.checked {
  font-size: 17px !important;
}
.cta.small {
  margin-top: 12.5px;
}
.cta.small a {
  font-size: 12.5px;
}
.note {
  line-height: 13.5px;
  width: 100%;
  width: 250px;
  margin-top: 15px !important;
  font-weight: 500 !important;
}
.note span {
  font-weight: 800;
}
.request_form.column.ctr {
  top: 75%;
}
.request_form.column.ctr input,
.request_form.column.ctr textarea {
  font-size: 12.5px;
}
.tag-ctr {
  width: 250px;
  display: flex;
  flex-wrap: wrap;
}
#blue-btn {
  background-color: #2e86de;
  border: none;
  padding: 1.5px 12.5px;
  border-radius: 2.5px;
  color: white;
  font-weight: 600;
  font-size: 13.5px;
  margin-top: 20px;
  margin-right: 6.5px;
}
#select {
  font-size: 12.5px;
  font-weight: 600;
  margin-bottom: 8.5px;
  opacity: 0.65;
}
#tdTT {
  opacity: 1 !important;
  color: #2d3436;
  font-weight: 800;
  cursor: pointer;
  position: relative;
  padding-right: 7.5px;
  width: 30px;
}
#tdTT .tdTT-content {
  position: absolute;
  bottom: 93.5%;
  left: 20%;
  transform: translateX(-50%);
  background-color: #2d3436;
  padding: 1.5px 8.5px;
  border-radius: 1.5px;
  transition: all 0.15s;
  opacity: 0;
  visibility: hidden;
}
#tdTT:hover .tdTT-content {
  opacity: 1;
  bottom: 100%;
  visibility: visible;
}
#tdTT .tdTT-content p {
  color: white;
  margin: 0;
  font-size: 11.5px;
  font-weight: 600;
  letter-spacing: 0;
}
#reqs {
  width: 215px;
  padding-right: 20px;
}
#reqssm {
  min-width: 95px;
  padding-right: 10px;
}
#mid {
  width: 110px;
}
#sm {
  width: 82.5px;
}
.partnerStepID {
  height: 23.5px;
  width: 23.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d3436;
  border-radius: 1.5px;
  margin-right: 6.5px;
  margin-top: 2.5px;
}
.partnerStepID h4 {
  color: white;
  margin: 0;
  font-weight: 700;
}
.listName {
  font-size: 26.5px !important;
  opacity: 0.85;
  font-weight: 700 !important;
  margin-bottom: 6.5px;
}
.requirementCard .option {
  margin-top: -8.5px;
  margin-bottom: 11.5px;
  border-radius: 2.5px;
  padding: 7.5px;
  text-align: center;
  background: #070788ce;
  background: -webkit-linear-gradient(to right, #1cb5e0d0, #070788ce);
  background: linear-gradient(to right, #1cb5e0d0, #070788ce);
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 15px;
}
.requirements.bg {
  background-color: #f7fafc;
  width: 112.5%;
  margin-left: -5rem;
  margin-right: -5rem;
  padding-left: 25px;
  padding-right: 65px;
  margin-top: -5px;
}
#null {
  opacity: 0.55;
  font-style: italic;
  margin: 0;
}
.devImg_img {
  object-fit: cover;
}
#dev-row {
  margin-top: 25px;
}
.bookedDevs_body table {
  border-collapse: separate;
  border-spacing: 0 20px;
}
