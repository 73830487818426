/**
 * Typography
 */

@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700,800&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// *::-webkit-scrollbar-track {
//     border-radius: 10px;
//     background-color: #383838;
// }

// *::-webkit-scrollbar {
//     width: 8px;
//     background-color: #383838;
// }

// *::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background-color: #6b6b6b;
// }

html {
  overflow: hidden;
  overflow-y: auto;
}

body {
  overflow: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  font-style: normal !important;
  font-weight: 300 !important;
  width: 100%;
  letter-spacing: -0.25px;

  @media #{$layout-tablet} {
    font-size: $fz-body - 2;
  }

  @media #{$layout-mobile-lg} {
    font-size: $fz-body - 3;
  }
}

img {
  max-width: 100%;
}

// @include placeholder-color($color-body);

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//     font-family: $ff-heading;
//     color: $color-heading;
// }

h1 {
  font-size: 3.2857rem;
  line-height: 4rem;
  font-family: "Montserrat", sans-serif;
  margin: 0;

  @media #{$layout-tablet} {
    font-size: 2.9857rem;
    line-height: 3.4rem;
  }

  @media #{$layout-mobile-lg} {
    font-size: 2.6857rem;
    line-height: 3.1rem;
  }
}

h2 {
  font-size: 2.57rem;
  line-height: 3.2857rem;
  font-family: "Montserrat", sans-serif;
  margin: 0;

  @media #{$layout-tablet} {
    font-size: 2.37rem;
    line-height: 3.0857rem;
  }

  @media #{$layout-mobile-lg} {
    font-size: 2.17rem;
    line-height: 2.857rem;
  }
}

h3 {
  font-size: 2rem;
  line-height: 2.7rem;
  font-family: "Montserrat", sans-serif;
  margin: 0;

  @media #{$layout-mobile-lg} {
    font-size: 1.91rem;
    line-height: 2.357rem;
  }
}

h4 {
  font-size: 1.71rem;
  line-height: 2.43rem;
  font-family: "Montserrat", sans-serif;
  margin: 0;

  @media #{$layout-mobile-lg} {
    font-size: 1.51rem;
    line-height: 2.13rem;
  }
}

h5 {
  font-size: 1.43rem;
  line-height: 2.14rem;
  font-family: "Montserrat", sans-serif;
  margin: 0;

  @media #{$layout-mobile-lg} {
    font-size: 1.23rem;
    line-height: 2.04rem;
  }
}

h6 {
  font-size: 1.14rem;
  line-height: 1.857rem;
  font-family: "Montserrat", sans-serif;
  margin: 0;

  @media #{$layout-mobile-lg} {
    font-size: 1.04rem;
    line-height: 1.657rem;
  }
}
p {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
}
input,
textarea {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
}
p.small {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
}

a {
  text-decoration: none;
  outline: none;
  margin: 0;
  //   color: $color_prim !important;

  &:hover {
    text-decoration: underline;
  }
}
.link {
  color: $color_prim !important;
}
a,
button {
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  outline: none;
}
button {
  padding: 1rem 1.6rem;
  // border-radius: 5px;

  &:disabled {
    cursor: not-allowed;
    background-color: gray;
  }
}

[class^="flaticon-"],
[class*=" flaticon-"],
[class^="flaticon-"],
[class*=" flaticon-"] {
  line-height: 1;

  &::before,
  &::after {
    margin-left: 0;
    font-size: inherit;
    vertical-align: middle;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 5px;
}

.stylish-list {
  padding-left: 0;

  li {
    list-style: none;
    line-height: 30px;
    position: relative;
    padding-left: 35px;
  }

  i {
    position: absolute;
    left: 0;
    top: 7px;
    font-size: 16px;
    // color: $color-theme;
  }
}

.sp_row {
  @include row;
}

.sp_col {
  @include column;
}
.sp_spbtw {
  justify-content: space-between;
}
.sp_centerAl {
  align-items: center;
}
.sp_rowcenter {
  width: 100%;
  @include row;
  justify-content: center;
  align-items: center;
  background-color: sp_colorLight($sp_lightGreen, 0.3);
}

.sp_equalBoxes {
  @include equal_Boxes_rows(2);
}

.sp_hover {
  cursor: pointer;
}

.sp_green {
  color: $sp_green;
}

.sp_grey {
  color: $sp_grey;
}

.sp_bold {
  font-weight: 600;
}

.sp_bolder {
  font-weight: 700;
}

.sp_light {
  color: 400;
}

.sp_extraBold {
  font-weight: 800;
}

.sp_extraLight {
  font-weight: 200;
}

.sp_lightGrey {
  color: $sp_lightGrey;
}
.sp_lighterGrey {
  color: $sp_lighterGrey;
}
.sp_darkGrey {
  color: $sp_darkGrey;
}
