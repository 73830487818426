.Register {
    margin: 0 auto;
    width: 515px;
    overflow: hidden;

    .vendor-signup {
        margin-top: 30px;
    }

    .primary-contact {
        margin-top: 30px;
        width: 100%;

        .form {
            width: 100%;
            margin-top: 24px;

            &-left {
                margin-right: 2%;
                width: 49%;
                margin-bottom: 24px;
            }

            &-right {
                width: 49%;
            }
        }
    }
}