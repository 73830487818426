@mixin clearfix() {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

@mixin row {
  display: flex;
}

@mixin flexContainer(
  $flexDirection: row,
  $gap: 1rem,
  $alignItem: initial,
  $justify: initial
) {
  display: flex;
  justify-content: $justify;
  flex-direction: $flexDirection;
  align-items: $alignItem;
  gap: $gap;
}

@mixin middlePosition() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin column {
  display: flex;
  flex-direction: column;
}

@mixin row_spbtw {
  @include row;
  justify-content: space-between;
}

@mixin col_spbtw {
  @include column;
  justify-content: space-between;
}

@mixin equal_Boxes_rows($num: 2) {
  display: grid;
  grid-template-columns: boxers($num);
}

/**
box can be 1px or 1fr or 1rem or minmax(20px, 100px)
*/
@mixin equal_2Boxes_rows($box1, $box2) {
  display: grid;
  grid-template-columns: $box1 $box2;
}
