.resources_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  margin-top: 5rem;
  &_left {
    .title {
      display: flex;
      // align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 1rem;

      .icon {
        font-size: 2rem;
      }
    }
    p{
      color: #8E8E8E;
    }
  }
  &_right {
    button {
      padding: 0.5rem;
      border: none;
      border-radius: 0.3rem;
      background-color: $color_prim;
      color: $white;
      font-size: 1.2rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}
