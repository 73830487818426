.errorPage {
  background-color: #fafafa;
  height: 89vh;
  width: 100vw;
  overflow: hidden;
  font-family: "Open Sans" !important;
}
.errorPage .errorDiv {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeInUp;
  animation-fill-mode: both;
  animation-delay: 0.35s;
  animation-duration: 0.35s;
}
.errorPage .errorDiv img {
  height: 450px;
  width: 750px;
  filter: grayscale(100%);
  /* transform: rotate(25deg); */
  margin-right: 20px;
  animation-name: floating;
  animation-duration: 1.25s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: -10%;
  opacity: 0.5;
}
.errorPage .errorDiv .error-cta {
  width: 25%;
  margin-left: 0px;
  z-index: 50;
}
.error-cta h1 {
  font-weight: 800;
  font-size: 12.5rem;
  color: #0985e3;
}
.error-cta h2 {
  font-weight: 600;
  font-size: 3rem;
  margin-top: 75px;
}
.error-cta p {
  font-weight: 400;
  margin-bottom: 35px;
}
.error-cta a {
  padding: 15px 75px;
  color: white;
  font-weight: 700;
  font-size: 1.5rem;
  border-radius: 35px;
  background: linear-gradient(to left, #0985e3, #0321b8b8);
  /* margin: 15px; */
  text-decoration: none;
}
@keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
