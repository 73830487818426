.requirements {
  //   background-color: aquamarine;
  padding-bottom: 5rem;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &-agreement {
    margin: 1re, 0;
    display: flex;
    align-items: center;
    gap: 1rem;

    &-content {
      padding: 1rem 2rem;
      border: 1px solid black;

      p {
        font-size: 1.4rem;
        color: #484848;

        span,
        a {
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
    &-signBy {
      font-size: 1.4rem;
      color: #484848;
      font-weight: 500;
      display: flex;
      align-items: center;
      svg {
        width: 3rem;
        height: 3rem;
      }

      span {
        font-weight: 600;
        color: #000000;
      }
    }
  }

  &-filters {
    display: flex;
    gap: 1rem;
    width: 100%;

    &__search {
      flex: 1;
    }
    &__status,
    &__types {
      width: 200px;
    }
    input,
    select {
      border: 1px solid #000000 !important;
      border-radius: 0 !important;
      outline: none;
      padding: 0.5rem 1rem;
      font-size: 1.6rem;
      height: 40px !important;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;

    &__title {
      flex: 1;
    }
    &__filters {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: flex-end;

      &__status,
      &__types {
        width: 200px;
      }
      input,
      select {
        border: 1px solid #000000 !important;
        border-radius: 0 !important;
        outline: none;
        padding: 0.5rem 1rem;
        font-size: 1.6rem;
        height: 40px !important;
      }
    }
  }

  &_body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  // &_all {
  //   &-top {
  //     &__filter {
  //       float: right;
  //       font-weight: 600;
  //       gap: 0.5rem;
  //       h4 {
  //         font-weight: 600;
  //       }
  //       select {
  //         border: none;
  //         background-color: transparent;
  //         font-size: 1.3rem;
  //         cursor: pointer;
  //         outline: none;
  //       }
  //     }
  //   }
  //   &-table {
  //     background-color: #fafafa;
  //     min-height: 50vh;
  //     padding: 5rem 2rem;
  //     overflow: auto;
  //     table {
  //       border-collapse: separate !important;
  //       border-spacing: 0 0px !important;
  //       min-width: 915px;

  //       th {
  //         font-weight: 700;
  //         font-size: 12px;
  //         padding: 1rem;
  //         min-width: 110px;
  //       }
  //     }
  //     hr {
  //       border: 0.3px solid rgba($color_prim, 0.3);
  //     }
  //     td {
  //       padding: 1rem;
  //     }
  //     #req-prio {
  //       padding: 0.5rem 1rem;
  //       background-color: var(--prioBg);
  //       color: var(--prioColor);
  //       border: none;
  //       outline: none;
  //       border-radius: 5px;
  //       // min-width: 99px;
  //       // width: 95px !important;
  //       font-weight: 600;
  //       margin: 0;
  //       font-size: 1.4rem;
  //       option {
  //         background-color: white;
  //         color: #000000;
  //       }
  //     }

  //     .reqShowMore {
  //       .req-client {
  //         display: flex;
  //         flex-direction: row !important;
  //         width: 100% !important;
  //         &-el {
  //           display: flex;
  //           flex-direction: column;
  //           // justify-content: space-between;
  //           p {
  //             margin-bottom: 0;
  //           }
  //         }
  //       }
  //     }

  //     .expendedRow {
  //       background-color: #f5f7f8;
  //       margin: -1rem;
  //       // display: block;
  //       width: 100%;
  //       border-top: 1px solid blue;

  //       h3,
  //       h4 {
  //         font-weight: 600;
  //       }

  //       &-wrapper {
  //         @include flexContainer(column, 2rem);
  //         h3,
  //         p {
  //           margin: 0;
  //         }
  //         &_reqDates {
  //           margin-top: 1rem;
  //           @include flexContainer(row, 1rem, center, space-between);
  //           & > div {
  //             @include flexContainer(row, 1rem, baseline);
  //             p {
  //               font-size: 1.6rem;
  //               font-weight: 600;
  //             }
  //             // &:not(:last-child) {
  //             p {
  //               color: #4361e9;
  //             }
  //             // }
  //             // &:last-child {
  //             //   p {
  //             //     background-color: white;
  //             //     color: #4361e9;
  //             //     padding: 0.5rem 1rem;
  //             //     border-radius: 5px;
  //             //     margin: 0;
  //             //   }
  //             // }
  //           }
  //         }
  //         &_reqDetails {
  //           @include flexContainer(row-reverse, 2rem, unset, space-between);
  //           width: 100%;
  //           &--left {
  //             @include flexContainer(column, 2rem, flex-end);
  //             width: 330px;
  //             flex-basis: 330px;

  //             &__top {
  //               width: 330px;
  //               // height: 100%;
  //               @include flexContainer(row, 1rem, unset, space-between);
  //               .req-num-el {
  //                 @include flexContainer(column, 0.5rem, center);

  //                 div {
  //                   @include flexContainer(row, 0, center, center);
  //                   width: 102px;
  //                   height: 70px;
  //                   min-width: 60px;
  //                   background-color: white;
  //                   padding: 0.5rem 1rem;
  //                   border-radius: 5px;
  //                   color: rgba(#4361e9, 0.9);
  //                   text-align: center;
  //                   font-weight: 700;
  //                   font-size: 40px;
  //                 }
  //               }
  //             }
  //             &__btm {
  //               h3 {
  //                 span {
  //                   font-weight: 400;
  //                 }
  //               }
  //               h4 {
  //                 font-size: 16px;
  //                 font-weight: 500;
  //                 margin: 0;
  //               }
  //               .req-client {
  //                 background-color: white;
  //                 border-radius: 5px;
  //                 padding: 9px 19px;
  //                 width: 320px;
  //                 @include flexContainer(column, 2rem, unset, space-between);

  //                 &-el {
  //                   h4 {
  //                     margin-bottom: 0.5rem;
  //                   }
  //                   .req-client-score {
  //                   }
  //                   ._spreq-tags {
  //                     @include flexContainer();
  //                     flex-wrap: wrap;
  //                     span {
  //                       background-color: var(--reqtagbg);
  //                       color: var(--reqtagcol);
  //                       padding: 0.2rem 1rem;
  //                       border-radius: 5px;
  //                       font-weight: 500;
  //                     }
  //                   }
  //                   ._spreq-location {
  //                     @include flexContainer();
  //                     flex-wrap: wrap;
  //                     p {
  //                       width: fit-content;
  //                       color: #4361e9;
  //                       font-weight: 700;
  //                       font-size: 16px;
  //                       padding-right: 1rem;
  //                       &:not(:last-child) {
  //                         border-right: 1px solid rgba(196, 196, 196, 1);
  //                       }
  //                     }
  //                   }
  //                   ._spreq-locationEmpty {
  //                     color: $danger;
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //           &--right {
  //             @include flexContainer(column, 0.5rem);
  //             flex-basis: calc(100% - (330px + 2rem));
  //           }
  //         }
  //       }
  //     }
  //     .req-actions {
  //       width: 45px;
  //       min-width: 45px;
  //       max-width: 45px;
  //     }
  //     ._spReq-row {
  //       position: relative;
  //       padding: 0 1rem;

  //       // td:first-child {
  //       //   min-width: fit-content;
  //       //   color: #ff0000;
  //       //   font-weight: 700;
  //       // }
  //       p {
  //         margin: 0;
  //       }
  //       &._sp-row_exp {
  //         background-color: #f5f7f8;
  //       }

  //       &._sp-row_colap {
  //         background-color: #ffffff;
  //       }
  //     }
  //     .clientType {
  //       gap: 0.5rem;
  //       // background-color: aqua;
  //       &_tags {
  //         gap: 0.5rem;
  //         &_tag {
  //           background-color: rgba(141, 250, 250, 0.876);
  //           padding: 0 0.5rem;
  //           border-radius: 1rem;
  //           color: rgb(46, 134, 222);
  //           font-size: 1.15rem;
  //         }
  //       }
  //     }
  //   }
  // }
  &_single {
    position: relative;
    width: 100%;
    &-content {
      &_desc {
        margin-top: 3rem;
        background-color: white;
        border-radius: 3.5px;
        margin-bottom: 25px;
        border: 2.15px solid rgba(71, 31, 31, 0.075);
        padding: 1rem;
        label {
          font-size: 1.5rem;
          font-weight: 600;
        }
        .row {
          align-items: baseline;
          gap: 1rem;
          flex-wrap: nowrap;
        }
      }
      &-steps {
        .spColaps {
          // height: 28rem !important;
          &.short {
            @media screen and (max-width: "1390px") {
              max-height: 9rem;
            }
          }
          &-head {
            &__right {
              width: 15%;
              &-icon {
                position: absolute;
                right: 0.5rem;
              }
            }
            &__left {
              width: 85%;
              margin-top: -0.5rem;
              margin-bottom: 3rem;
              .header {
                justify-content: space-between;
                width: 100%;
                // column-gap: 1rem;
                font-size: 1.3rem;
                min-height: 3rem;
                &_left {
                  max-width: 110rem;
                }

                .el {
                  gap: 0.5rem;
                }

                .confirmed {
                  position: absolute;
                  right: 4rem;
                  gap: 0.5rem;
                  color: #259e57;
                  align-items: center;
                }
              }
              label {
                font-weight: 600;
              }
              span {
                font-weight: 500;
              }
            }
          }
        }
        .stepOne {
          &_action {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            &-confirm {
              background-color: #2ecc71;
              color: white;
              width: 11rem;
              text-align: center;
              border-radius: 0.5rem;
              padding: 0.5rem;
            }
          }
        }
        .bookedDevs {
          &_body {
            display: flex;
            gap: 2rem;

            &_box {
              border: 2.15px solid rgba(71, 31, 31, 0.075);
              flex-basis: 65%;
              border-radius: 0.2rem;
              padding: 2.5rem;

              &--top {
                float: right;
              }
              table {
                clear: both;
              }
            }
            &_notice {
              border-radius: 0.2rem;
              border: 2.15px solid rgba(49, 34, 34, 0.075);
              flex-basis: 35%;
              padding: 2.5rem;
            }
          }
        }
        .resourceCtr {
          .singleTuc {
            .devList {
              .team {
                .single_dev {
                  margin: 2rem 0;
                  .dev {
                    margin: 0;
                  }
                  &-btm {
                    margin-left: 6.5rem;
                    gap: 0.2rem;
                    .status {
                      align-items: baseline;
                      gap: 0.5rem;
                      &-label {
                        font-size: 1.3rem;
                        font-weight: 600;
                      }
                    }
                    .interview {
                      .interSect {
                        align-items: center;
                        gap: 0.5rem;
                        .sp-ttp_content {
                          width: 15rem;
                          height: 3rem;
                          padding: 1rem 3rem;
                          text-align: center;
                          bottom: 80%;
                          overflow: hidden;
                        }
                        span {
                          font-size: 1.3rem;
                          font-weight: 600;
                        }
                        button {
                          background-color: #1f924f20;
                          color: #166d3a;
                          border: 1px solid #2ecc71;
                          padding: 0 1rem;
                          margin: 0;
                          transition: background-color 0.3s;
                          &:hover {
                            background-color: #2a8f54;
                            border: none;
                            color: #fff;
                            box-shadow: 2px 2px 3px
                              rgba($color: #000000, $alpha: 0.7);
                          }
                        }
                      }
                    }
                  }
                }
                .interviewBox {
                  // background-color: aqua;
                  label {
                    font-size: 1.3rem;
                    font-weight: 600;
                  }
                  input {
                    padding: 0.5rem;
                  }
                  h3 {
                    font-size: 1.7rem;
                  }

                  &_dialog {
                    // background-color: aqua;

                    &-top {
                      align-items: center;
                      gap: 4rem;
                      &_right {
                        align-items: baseline;
                        gap: 1rem;
                      }
                    }
                    &-bottom {
                      .emails {
                        gap: 1rem;
                        align-items: baseline;
                        input {
                          width: 50rem;
                          border: 1px solid #727272 !important;
                          font-size: 1.2rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &_selResource {
      width: 70vw;
      background-color: $sidebare;
      border-radius: 2rem;
      position: fixed;
      padding: 2rem;
      z-index: 2000;
      // min-height: 30vh;
      transition: all 3s;
      box-shadow: 1px 2px 1.5rem 0.1rem rgba($color: #00000025, $alpha: 0.35);

      &-box {
        width: 100%;
        min-height: 60vh;
        &--top {
          .desc {
            flex-wrap: nowrap;
            &-content {
              flex-basis: 85%;
            }
            &-slot {
              text-align: center;
              h3 {
                font-weight: 600;
              }
              &_num {
                font-size: 1.6rem;
                font-weight: 500;
              }
            }
          }
        }

        &--table {
          width: 100%;
        }
      }
    }
  }
}
