.spCard {
  background-color: white;
  -webkit-box-shadow: 5px 5px 17px -6px rgba(0, 0, 0, 0.73);
  box-shadow: 5px 5px 17px -6px rgba(0, 0, 0, 0.73);
  padding: 2rem;
  border-radius: 0.5rem;
}
.spImgPrev {
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
