.spDevMatches {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__top {
    margin-top: 30px;
  }
  &_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    label {
      font-weight: 600;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    button {
      display: flex;
      align-items: center;
      border-radius: 0;
      font-weight: 600;
    }

    &__filter {
      select {
        width: 200px;
        font-weight: 500;
        border-radius: 0;
        border: 1px solid black;
        background-color: white;
        height: 40px;
      }
    }

    &__slides {
      label {
        min-width: fit-content;
      }
      .MuiSlider-root {
        max-width: 200px !important;
        min-width: 150px !important;
      }
    }
  }
}
