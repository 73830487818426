.main {
  .loading {
    position: fixed;
    width: 100%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 75px;
      width: 75px;
      animation: rotate 1s linear infinite;
    }
  }
}
.resource {
  .techs-data_grid {
    flex-basis: 48%;
    flex-shrink: 4;
    min-width: 670px;
  }
  .devInfo {
    transition: all 0.8s;
    transform: translateX(0);
    // width: 80vw;

    .account-div {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      gap: 2rem;
      .account-photo {
        flex-basis: 20rem;
        height: 20rem;
        max-height: 30rem;
        min-width: 20rem;
        overflow: hidden;
        z-index: 200;
        .avatar {
          width: 100%;
          height: 100%;

          &_no_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 1rem;
            z-index: 1;
          }
          &:hover .upload-btn-wrapper {
            transform: translateY(0);
          }
          .upload-btn-wrapper {
            left: 0%;
            transform: translateY(5rem);
            .btn {
              border: none;
              color: #fff;
              background-color: #1e96f5;
              font-size: 3rem;
              border-radius: 0%;
              height: 3rem;
              width: 20rem;
              p {
                margin-top: -10.5px;
                font-weight: 500;
              }
            }
          }
        }
      }

      .account-intro {
        width: 100%;
        column-gap: 2rem;
        flex-wrap: nowrap;
        margin: 0;
        justify-content: space-between;
        .request_form {
          width: 60vw;
        }

        &_info {
          flex-basis: 40vw;
          .e-editable-value {
            font-size: 1.4rem;
            font-family: "Open Sans", sans-serif;
            font-weight: 500;
          }
          &-btm {
            gap: 2rem;
            align-items: stretch;
          }
          .elements {
            // column-gap: 5%;
            // align-items: baseline;
            // justify-content: space-between;
            // justify-content: baseline;
            .el {
              align-items: baseline;
              // justify-content: space-between;
              gap: 1rem;
              p {
                font-size: 1.4rem;
                // font-weight: 500;
              }
            }
          }
          .dev_resume {
            align-items: baseline;
            gap: 0.3rem;
            h3 {
              font-weight: 500;
            }
            .icon {
              font-size: 1.8em;
              color: $color_prim;
              font-weight: 600;
            }
          }
          &-top {
            .dev_name {
              h1 {
                font-weight: 600;
              }
              &--edit {
                background-color: transparent;
                border: 1px solid $color_prim;
                border-radius: 5px;
                min-width: 4rem;
                font-weight: 600;
                color: $color_prim;
                margin-left: 1rem;
                transition: all 0.3s;
                &:hover {
                  background-color: $color_prim;
                  color: white;
                }
              }
            }
            .dev_posi {
              margin-top: -0.5rem;
              .position {
                color: $color_prim;
                font-weight: 600;
                font-size: 1.3rem;
              }
            }
          }
          .info_right {
            &-head {
              gap: 0.5rem;
              align-items: baseline;

              svg {
                color: $color_prim;
                &:hover {
                  color: $danger;
                  cursor: pointer;
                }
              }
              // align-items: cen;
            }
            &-content {
              p {
                font-size: 1.2rem;
              }
            }
          }

          h3 {
            font-size: 1.5rem;
            font-weight: 500;
          }
          .crossVendor {
            &_toggle {
              column-gap: 1rem;
              align-items: baseline;
            }
            &_des {
              font-size: 1.2rem;
              margin-top: -0.5rem;
            }
          }
        }

        &_progress {
          flex-basis: 15vw;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          // justify-content: flex-end;
          font-size: 1.5rem;
          align-content: center;
          li {
            color: $danger;
          }
        }
      }
    }
    .account-leadData {
      // background: chartreuse;
      // margin-top: 3rem;
      // flex-basis: 45vw;
      // max-width: 60vw;
      display: flex;
      gap: 2rem;

      &_switches {
        overflow: hidden;

        // &__assess {
        //   padding: 0 10px;
        // }
      }
      &_empty {
        flex-basis: 20rem;
        min-width: 20rem;
      }
      .column {
        margin-top: 1.5rem;
      }
      p {
        font-size: 1.2rem;
      }
      .isBookable {
        margin-top: 1rem;
      }

      .switch_box {
        align-items: baseline;
      }
      h3 {
        font-size: 1.5rem;
        font-weight: 500;
      }
      .togglebox {
        column-gap: 0.5rem;
        align-items: center;
      }
      .leadData {
        column-gap: 1rem;
        align-items: center;

        &_time {
          column-gap: 0.5rem;
        }
      }
    }
    .hide_page {
      display: none;
      transform: translateY(2000);
    }
  }

  #view_dev,
  #upload_dev {
    background-color: $color_prim;
    padding: 0 0.5rem;
    transition: all 0.2s;

    .e-tbar-btn-text {
      color: $white;
      font-weight: 500;
    }
    &:hover {
      background-color: $danger;
    }
  }
  .control-section {
    transition: all 0.8s;
    transform: translateX(0);

    .grid-bulk {
      overflow: visible;
      .bulk-info {
        display: flex;
        gap: 2rem;
        justify-content: flex-end;
        align-items: center;
        overflow: visible;
        // margin-bottom: 1.5rem;

        .icon-content {
          position: relative;
          overflow: visible;
          transition: all 0.5s;
          .icon {
            font-size: 1.5rem;
          }

          .icon:hover + .info-content {
            display: block;
            position: absolute;
            top: -8rem;
            left: -37rem;
            width: auto;
            padding: 1rem;
            z-index: 300;
            font-size: 1.2rem;
            box-shadow: 1rem 1rem 3rem rgba($color: $black, $alpha: 0.3);
          }
          .info-content {
            display: none;
          }
        }
      }
    }
  }
  .slide-left {
    transition: all 0.8s;
    transform: translateX(-3000px);
    position: absolute;
  }
  .slide-right {
    transition: all 0.8s;
    transform: translateX(3000px);
    position: absolute;
  }

  .techs-data {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;

    &_grid {
      flex-basis: 48%;
      flex-shrink: 4;
    }
    &_caroussel {
      flex-shrink: 1;
      flex-basis: 50%;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }
  #ProjectsExp {
    // background-color: red;
    .content-two {
      // background-color: red;
      display: grid;
      align-items: stretch;
      grid-template-columns: repeat(2, 50%);
      gap: 2rem;
    }
    .content-three {
      display: grid;
      grid-template-columns: repeat(3, auto);
      gap: 1rem;
    }
  }
  @media screen and (max-width: 1440px) {
    .techs-data {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-direction: column-reverse;
      gap: 2rem;
      width: 100%;
    }
  }

  /* ===================================================================New Css For Status */

  // .e-bigger #overviewgrid .statustxt {
  //   position: relative;
  // }
  // .rating {
  //   text-align: center;
  // }
  // .rating .star {
  //   font-size: 132%;
  //   color: lightgrey;
  //   text-align: center;
  // }

  // .rating .star.checked {
  //   color: #ffa600;
  // }

  // .rating .star:before {
  //   content: "★";
  // }

  // #overviewgrid td.e-rowcell.e-selectionbackground.e-active {
  //   background-color: transparent;
  // }

  // .e-image {
  //   height: 13px;
  //   width: 14px;
  // }

  // td.e-rowcell .statustxt.e-activecolor {
  //   color: #00cc00;
  //   position: relative;
  // }

  // td.e-rowcell .statustxt.e-inactivecolor {
  //   color: #e60000;
  //   position: relative;
  // }

  // .statustemp.e-inactivecolor {
  //   background-color: #ffd7cc;
  //   text-align: center;
  // }

  // .statustemp.e-activecolor {
  //   background-color: #ccffcc;
  // }

  // .statustxt.e-activecolor {
  //   color: #00cc00;
  // }

  // .statustxt.e-inactivecolor {
  //   color: #e60000;
  // }

  // .statustemp {
  //   position: relative;
  //   height: 19px;
  //   border-radius: 5px;
  //   text-align: center;
  // }

  // .e-dlg-overlay {
  //   position: fixed !important;
  // }

  #devProgress {
    // background-color: red;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 2%;
  }
}

@keyframes right {
  0% {
    display: block;
  }
  100% {
  }
}

.dash_iframe {
  width: 88vw !important;
  height: 100vh !important;
  position: fixed;
  border: 0;
}

// =============================================GridDialog Form===================================================== //

input[type="number"] {
  width: 100% !important;
}

.MuiFormHelperText-root.Mui-error {
  font-size: 11px !important;
  position: absolute;
  top: 25px;

  $medium: 900px;
  @media screen and (max-width: $medium) {
    font-size: 9px !important;
  }
}

.MuiInputBase-input {
  padding: 7px 6px !important;
}

select.dropdown {
  border: none;
  outline: none;
  width: 120%;
  box-shadow: none;
  background-image: none;
  border-radius: 0.25em;
  padding: 0.5em 0.5em;
  font-size: 1.25rem;
  cursor: pointer;
  background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  margin-top: -4px;
}

select.dropdown:focus {
  outline: none;
}

select.dropdown > option {
  color: rgb(73, 72, 72);
  /* background-color: transparent; */
}

.internalID {
  margin: auto;

  .internalID-div {
    width: 100%;
  }
}

.internalID-edit {
  margin-left: auto;
  position: relative;
  bottom: 77px;

  .internalID-div-edit {
    width: 100%;
  }
}

._spResource_dialog_error {
  position: absolute;
  color: #f44336 !important;
  font-size: 11px !important;
  font-weight: 200 !important;
  padding-top: 0 !important;
  margin-left: 75px;
  top: 210px;

  $medium: 900px;
  @media screen and (max-width: $medium) {
    font-size: 9px !important;
  }
}
