.partnManagement {
  position: relative;
  transition: all 0.5s;
  width: 100%;
  &_partnerInfo {
    background-color: white;

    border-radius: 2rem;
    padding: 1rem;
    z-index: 100;
    width: 50rem;
    transition: all 3s;
    position: fixed;
    box-shadow: 1px 2px 1rem 0.2rem rgba($color: #000000, $alpha: 0.7);

    &-details {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      h4 {
        font-weight: 700;
      }
    }
  }
  .partnerInfo-hide {
    height: 10rem;
    right: -100rem;
    visibility: hidden;
    animation-duration: 1s;
    animation-name: slideout;
  }
  .partnerInfo-show {
    animation-duration: 2s;
    animation-name: slidein;
    right: 1rem;
    opacity: 1;
    min-height: 20rem;
    visibility: visible;
  }

  @keyframes slidein {
    0% {
      right: -100rem;
      height: 10rem;
      display: block;
    }

    50% {
      right: 5rem;
      height: 40rem;
    }

    100% {
      right: 1rem;
      min-height: 40rem;
    }
  }

  @keyframes slideout {
    from {
      right: 1rem;
      height: 40rem;
    }

    to {
      right: -100rem;
      height: 0;
      width: 0;
      display: none;
    }
  }
}
