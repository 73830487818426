.spd {
  //   background-color: blue;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  &_box {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    // height: 100%;
    background-color: $danger;
    text-align: center;
    color: White;
  }
}
