._spDevPage {
  display: flex;
  // align-items: center;
  // flex-direction: column;
  min-height: 100lvh;
  width: 100%;
  gap: 20px;
  padding-bottom: 50px;

  &--assessement {
    &-empty {
      background-color: #f0f0f0;
      border: 1px solid #dfdfdf;
      padding: 1.7rem;
      display: flex;
      flex-direction: column;
      gap: 20px;
      h3 {
        font-weight: 00 !important;
        font-size: 14px !important;
      }
      &__infos {
        display: flex;
        gap: 30px;
        align-items: center;
        padding: 30px 50px;

        &_content {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          h5 {
            font-weight: 600;
            font-size: 14px;
          }

          p {
            font-size: 12px !important;
            font-weight: 400;
            max-width: 375px;
          }

          button {
            background-color: #fa5e2f;
            max-width: fit-content;
            padding: 5px;
            color: white;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }
  }

  &--content {
    // max-width: 1366px;
    width: 100%;

    display: grid;
    grid-template-columns: 210px 1fr;
    gap: 10px;

    &:not(:last-child) {
      padding-top: 60px;
    }
  }

  &--left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
    &-backAction {
      &_btn {
        background-color: $color_prim;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        padding: 10px 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-profile {
      position: relative;
      &_avatar {
        width: 100%;
        height: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }

      &_upload {
        background-color: rgba($color: $sp_lightGreen, $alpha: 0.5);
        position: absolute;
        bottom: 0;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        display: none;
        top: 0;
        cursor: pointer;
        ._spUploader {
          border: none !important;
        }
      }

      &:hover &_upload {
        display: flex;
      }
    }

    &-branded {
      background-color: #aeadad32;
      padding: 10px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      transition: box-shadow 0.3s ease;
      &:hover {
        box-shadow: 0px 0px 10px 0px #0000001a;
        text-decoration: none;
        border: 1px solid #dfdfdf;
      }

      p {
        font-weight: 500;
        font-size: 10px;
        color: #888888;
      }

      &_info {
        display: flex;
        gap: 10px;
        align-items: center;
        h4 {
          font-weight: 600;
          font-size: 14px;
        }

        &__img {
          width: 40px;
          height: auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
          }
        }
      }

      &_link {
        a {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }

    &-unbranded {
      border: 1.5px solid black;
      padding: 10px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      background-color: white;
    }

    &-resume {
      border: 1px solid #dfdfdf;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      cursor: pointer;
      max-height: 173px;

      &__text {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;

        h3 {
          font-size: 2rem;
          font-weight: 700;
          color: #888888;
        }
      }

      &__info {
        p {
          font-size: 12px;
          font-weight: 400;
          color: #888888;
        }
      }
    }

    &-options {
      padding: 10px;
      border: 1px solid #dfdfdf;

      h3 {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
      }

      &__items {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      &__item {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s ease;

        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  &--right {
    background-color: $white;
    border: 1.1px solid #dfdfdf;
    padding: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 50px;

    h4 {
      font-weight: 600;
      font-size: 14px;
      color: #7e7e7e;
      text-transform: uppercase;
    }

    &-endorsment,
    &-completion {
      padding: 10px;
      background-color: white;
      color: #7e7e7e;
    }

    &-endorsment {
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &_top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-weight: 600;
        }
      }

      &_ratingText {
        font-size: 30px !important;
      }

      button {
        padding: 0;
        background-color: transparent;
        border: none;
        border-bottom: 0.7px solid #7e7e7e;
        font-size: 10px;
      }
    }

    &-completion {
      display: flex;
      flex-direction: column;
      gap: 20px;
      h4 {
        font-weight: 600;
        font-size: 14px;
      }

      &_progress {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &_missing {
        display: flex;
        gap: 10px;
        flex-direction: column;

        &__item {
          display: grid;
          grid-template-columns: 20px 1fr;
          gap: 5px;
          align-items: center;
          svg {
            width: 20px;
            height: 20px;
          }
          p {
            font-size: 12px;
            font-weight: 400;

            span {
              color: #fa5e2f;
            }
          }
        }
      }
    }

    &-devInfo {
      display: grid;
      grid-template-columns: 1fr 210px;
      gap: 20px;

      // &_right {

      // }

      &_left {
        overflow: hidden;
        label {
          text-transform: uppercase;
          color: #7e7e7e !important;
          font-size: 12px;
        }
        &_section {
          padding-bottom: 20px;

          &.devDocs {
            & > div:not(._spDevPage--right-devInfo_left_section_rate) {
              flex: 1;
            }
          }

          .namepos {
            &--top {
              display: grid;
              grid-template-columns: 1fr 100px;
              gap: 10px;
            }
          }

          &:not(&.namepos) {
            border-top: 1px solid #dfdfdf;
            padding-top: 20px;
            display: flex;

            & > div {
              padding: 0 20px;

              &:first-child {
                padding-left: 0;
              }

              &:not(:last-child) {
                border-right: 1px solid #dfdfdf;
              }
            }
          }

          &:last-child {
            border-bottom: 1px solid #dfdfdf;
          }

          &_rate {
            &__top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;
            }
          }
          .docs {
            &__info {
              display: flex;
              gap: 10px;
              align-items: center;

              button {
                color: #7e7e7e;
                font-size: 12px;
                display: flex;
                align-items: center;
                gap: 5px;
              }
            }
          }
        }
      }

      &_right {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    &-devAbout,
    &-assess {
      &_title {
        display: flex;
        gap: 10px;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
      }
    }

    &-assess {
      margin-top: 20px;
    }

    &-avail {
      display: flex;

      @media (max-width: 1600px) {
        flex-direction: column;
        gap: 20px;
      }
    }

    &-steper {
      display: flex;
      gap: 10px;
      flex-direction: column;
      margin-top: 30px;
      &_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        &_title {
          display: flex;
          gap: 10px;
          align-items: center;
        }

        &_action {
          button {
            font-size: 12px;
            font-weight: 400;
            border: none;
            cursor: pointer;
            color: $color_prim;
            gap: 5px;
            display: flex;
            align-items: center;
          }
        }
      }

      &_empty {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 20px;
        background-color: #f0f0f0;
        border: 1px solid #dfdfdf;
        font-size: 12px;
        font-weight: 400;
        color: #7e7e7e;

        &__content {
          display: flex;
          gap: 10px;
          align-items: center;

          &-text {
            display: flex;
            flex-direction: column;
            gap: 5px;
            color: black;

            h3 {
              font-size: 23px;
              font-weight: 500;
            }
            p {
              font-weight: 400;
            }
          }
        }
      }

      ._spStepperElement {
        gap: 0 !important;
        &__left {
          justify-content: flex-end;
          display: flex;
        }

        &__right {
          margin-left: 50px;

          &::before {
            left: calc(((50px + 17px) / 2) * -1) !important;
          }

          button.delete-button,
          button.add-button {
            left: calc(((50px + 17px) / 2) * -1) !important;
          }

          &::after {
            left: calc(((50px + 2px) / 2) * -1) !important;
          }
        }
      }
    }
  }

  &--borderedCard {
    border: 1.1px solid #dfdfdf;
  }
}
