._sp-matches {
  &-sectionTitle {
    font-size: 18px;
    font-weight: 700;
  }

  &--matchInfo {
    background-color: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 10px;
    &-top {
      display: flex;
      gap: 10px;
      align-items: baseline;

      border-bottom: 1px solid #ccc;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      p {
        font-size: 16px;
        .addedDate {
          border-right: 1px solid black;
          padding-right: 10px;
        }
      }
      h3 {
        font-size: 20px;
      }
    }

    &-infos {
      display: flex;
      gap: 10px;
      justify-content: space-between;
    }

    .matchStats {
      &__y {
        color: #47ed58;
      }

      &__n {
        color: #ff5c5c;
      }
      &__stats {
        & > div {
          display: flex;
          gap: 5px;
          p,
          span {
            font-size: 14px;
            font-weight: 600;
          }
          align-items: baseline;
          &:not(:last-child) {
            border-right: 1px solid #ccc;
            padding-right: 5px;
          }
        }

        span {
          color: #c8c8c8;
        }
      }
    }
  }
}
