@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(7.5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.customReqBg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
}
.customReqBg .customReqModal {
  padding: 25px 100px 25px 25px;
  border-radius: 2.5px;
  background-color: white;
  position: relative;
  animation: fadeInUp;
  animation-fill-mode: both;
  animation-duration: 0.325s;
  animation-delay: 0.1s;
  animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  max-width: 775px;
}
.customReqBg .customReqModal .help {
  position: absolute;
  bottom: 16.5px;
  right: 16.5px;
  cursor: pointer;
}
.customReqBg .customReqModal .help .helpContent {
  position: absolute;
  right: 137.5%;
  bottom: 7.5px;
  background-color: #2d3436;
  padding: 8.5px;
  border-radius: 2.5px;
  width: 400px;
  padding-left: 15px;
  transition: all 0.125s;
  visibility: hidden;
  opacity: 0;
  z-index: 3;
}
.customReqBg .customReqModal .help:hover .helpContent {
  right: 125%;
  opacity: 1;
  visibility: visible;
}
.customReqBg .customReqModal .help .helpContent .helpItem {
  margin: 8.5px 0;
}
.customReqBg .customReqModal .help .helpContent .helpItem h6 {
  color: #459df5;
  font-weight: 600;
  letter-spacing: 0;
}
.customReqBg .customReqModal .help .helpContent .helpItem p {
  color: white;
  font-weight: 400;
  letter-spacing: -0.15px;
  opacity: 1;
}
.customReqBg .customReqModal .help .helpContent .helpItem p,
.customReqBg .customReqModal .help .helpContent .helpItem h6 {
  font-size: 13px;
}
.customReqBg .customReqModal #title {
  margin-bottom: 18.5px;
}
.customReqBg .customReqModal .checkboxCtr {
  margin: 13.5px 0;
  position: relative;
}
.customReqBg .customReqModal .checkboxCtr .vendorPopup {
  position: absolute;
  bottom: 125%;
  right: 39%;
  background-color: #2d3436;
  padding: 10px;
  border-radius: 1.5px;
  opacity: 0.95;
}
.customReqBg .customReqModal .checkboxCtr .vendorPopup input {
  padding: 7.5px 12.5px;
  background-color: rgba(128, 128, 128, 0.5);
  border: none;
  border-radius: 2.5px;
  margin-bottom: 10px;
  font-size: 13.5px;
  font-weight: 400;
  color: white;
  opacity: 0.65;
}
.customReqBg .customReqModal .checkboxCtr .vendorPopup p {
  color: white;
  font-weight: 600;
  font-size: 13.5px;
  opacity: 1;
  margin-left: 3.5px;
  cursor: pointer;
}
.customReqBg .customReqModal .checkboxCtr input {
  width: fit-content;
  margin-right: 6.5px;
  cursor: pointer;
}
.customReqBg .customReqModal .checkboxCtr span {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.25px;
  text-transform: uppercase;
  display: inline-block;
  transform: translateY(-2px);
}
.customReqBg .customReqModal .close {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
  padding: 5px;
  opacity: 0.5;
}
.customReqBg .customReqModal p {
  font-size: 11px;
  letter-spacing: 0.15px;
  font-weight: 600;
  opacity: 0.7;
}
.customReqModal .input {
  padding: 5px;
  border-radius: 2.5px;
  width: 215px;
  border: 1.5px solid rgba(71, 31, 31, 0.25);
  font-size: 13px;
  font-weight: 600;
  margin: 5px 0 15px 0;
}
.customReqModal textarea::placeholder {
  font-weight: 500;
  opacity: 0.65;
  padding-right: 15px;
}
.customReqModal button {
  background-color: #2d3436;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  padding: 3.5px 15px;
  border-radius: 1.5px;
  letter-spacing: -0.15px;
  opacity: 0.935;
  margin: 0;
  transition: none;
}
.customReqModal .inputCtr,
.customReqModal .teamLocation {
  display: flex;
}
.customReqModal .inputCtr {
  position: relative;
}
.customReqModal .teamLocation .location {
  color: grey;
  background-color: rgba(128, 128, 128, 0.103);
  padding: 2px 10px;
  border-radius: 1.5px;
  font-size: 11px;
  margin-right: 5.5px;
  border: 1px solid grey;
  letter-spacing: 0;
  margin-top: 7.5px;
  transition: all 0.1s ease;
  cursor: pointer;
}
.customReqModal .teamLocation .location.active {
  color: #459df5;
  background-color: #459df527;
  border: 1px solid #459df5;
}
.customReqModal .inputCtr input {
  width: 102.5px;
  margin-right: 10px;
}
.customReqModal textarea {
  background-color: white;
  padding: 7.5px;
  border-radius: 2.5px;
  width: 215px;
  height: 90px;
  border: 1.5px solid rgba(71, 31, 31, 0.25);
  font-size: 13px;
  font-weight: 600;
  margin: 5px 0 15px 0;
}
.positionsCtr {
  position: absolute;
  bottom: 115%;
  left: 0;
  background-color: #2d3436;
  padding: 5px 35px 5px 15px;
  border-radius: 2.5px;
}
.positionsCtr .position {
  margin: 0 12.5px 7.5px 0;
  font-weight: 500;
  font-size: 12.5px;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: -0.15px;
  cursor: pointer;
  color: white;
  margin: 6.5px 0;
}
.positionCount {
  margin-left: 8.5px;
  background-color: rgba(128, 128, 128, 0.45);
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 3.5px 10px;
  border-radius: 3.5px;
  position: relative;
  cursor: pointer;
}
.positionCount .positionInfo {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 125%;
  bottom: 0;
  background-color: #2d3436;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 2.5px;
  z-index: 3;
  transition: all 0.125s;
}
.positionCount:hover .positionInfo {
  left: 120%;
  opacity: 1;
  visibility: visible;
}
.positionCount .positionInfo h6 {
  color: white;
  font-size: 12.5px;
  font-weight: 500;
  white-space: nowrap;
  margin: 5.5px 0;
}
.positionCount .positionInfo .selectedPositionCtr {
  display: flex;
  align-items: center;
}
.customReqBg .customReqModal hr {
  border: none;
  height: 2px;
  background: rgba(128, 128, 128, 0.115);
  margin-bottom: 15px;
}
.errorContainer {
  display: flex;
  align-items: center;
}
.errorContainer .err {
  color: red;
  opacity: 0.65;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 0;
  margin-left: 8.5px;
  transform: translateY(-1.5px);
}
