.project_images {
  //   background-color: aqua;
  margin-top: 2rem;
  &-preview {
    // max-height: 60rem;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 2rem;
    &__imgBox {
      height: 10rem;
      overflow: hidden;
      position: relative;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .icon {
        color: black;
        background-color: white;
        border-radius: 50%;
        opacity: 1;
        position: absolute;
        right: 0.1rem;
        cursor: pointer;
        // top: -0.5rem;
      }
    }
  }
  .btn {
    margin-top: 0.5rem;
    background-color: $color_prim;
    color: white;
    font-weight: 600;
    font-size: 1.2rem;
    width: auto;
  }
}
