@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.registrationCtr {
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: -6rem -5rem 0 -5rem;
}
.logo {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.logo img {
  height: 35px;
  width: 35px;
  opacity: 0.55;
}
.logo h3 {
  font-size: 22.5px !important;
  margin-left: 10px;
  color: grey;
  font-weight: 600;
}
.registrationCtr .regLeft {
  min-height: 100vh;
  min-width: 535px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 75px;
}
.registrationCtr .regLeft input {
  width: 100%;
  border: 1.75px solid rgba(128, 128, 128, 0.35);
  border-radius: 5px;
  padding: 5.5px 10px;
  font-size: 16px;
  font-weight: 500;
}
.registrationCtr .regLeft input::placeholder {
  opacity: 0.35;
}
.registrationCtr .regLeft input,
.registrationCtr .regLeft label {
  display: block;
}
.registrationCtr .regLeft label {
  margin-top: 15px;
  margin-bottom: 7.5px;
  font-weight: 500;
  font-size: 13.5px;
  color: #95a5a6;
  letter-spacing: -0.5px;
}
.registrationCtr .regLeft button {
  margin: 25px 0;
  border-radius: 2.5px;
  height: 34px;
  width: 100%;
  background-color: #388de2;
  color: white;
  /* font-size: 16px; */
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.25s;
}
.registrationCtr .regLeft button:hover {
  background-color: #2e86de;
}
.registrationCtr .regLeft button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}
.rememberCtr {
  display: flex;
  margin-top: 25px;
}
.rememberCtr input {
  width: fit-content !important;
  margin-right: 5.5px;
  margin-top: 2.5px;
}
.rememberCtr p,
.signUp {
  font-size: 13.5px;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.remember,
.signUp span {
  font-weight: 500;
  font-size: 13.5px;
  color: #95a5a6;
  letter-spacing: -0.5px;
  width: 100%;
}
.rememberCtr .forgot {
  color: grey;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: -0.75px;
}
.registrationCtr .regLeft h2 {
  font-size: 25.5px;
  font-weight: 600;
  margin-bottom: 25px;
}
.registrationCtr .regRight {
  min-height: 100vh;
  padding: 75px 0;
  width: 100%;
  background: linear-gradient(45deg, #1b5792, #2e86de);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.registrationCtr .regRight .ssCtr {
  height: 300px;
  width: 300px;
  background-color: hsl(0, 10%, 94%);
  border-radius: 8.5px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.009),
    0 6.7px 5.3px rgba(0, 0, 0, 0.016), 0 12.5px 10px rgba(0, 0, 0, 0.03),
    0 22.3px 17.9px rgba(0, 0, 0, 0.019), 0 41.8px 33.4px rgba(0, 0, 0, 0.024),
    0 100px 80px rgba(0, 0, 0, 0.06);
  margin-bottom: 75px;
  z-index: 100;
  position: relative;
  animation: fadeInUp;
  animation-delay: 0.15s;
  animation-fill-mode: both;
  animation-duration: 0.65s;
  animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  padding: 20px;
}
.registrationCtr .regRight .ssCtr .topSs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.registrationCtr .regRight .ssCtr .topSs h3 {
  color: rgb(185, 185, 185);
  font-size: 15px;
  font-weight: 600;
}
.registrationCtr .regRight .ssCtr .topSs p {
  color: rgba(128, 128, 128, 0.7);
  margin: 0;
  padding: 0;
  font-size: 13px;
  margin: 15px 0;
}
.registrationCtr .regRight .ssCtr .topSs .date {
  font-weight: 600;
}
.registrationCtr .regRight .ssCtr .topSs h2 {
  color: grey;
  font-size: 17px;
}
.registrationCtr .regRight .ssCtr .topSs .activeChip {
  font-weight: 600;
  opacity: 1;
  color: #1c9b51;
  background-color: #59db8f5d;
  padding: 2.5px 15px;
  border-radius: 8.5px;
  font-size: 12px;
}
.registrationCtr .regRight .ssCtr .email {
  margin: 0;
  text-align: left;
  padding: 0;
  color: rgba(128, 128, 128, 0.7);
  font-size: 13px;
  margin-top: -15px;
}
.registrationCtr .regRight .ssCtr hr {
  border: none;
  height: 2px;
  width: 100%;
  background-color: rgba(185, 185, 185, 0.25);
  margin-top: -5px;
}
.registrationCtr .regRight .ssCtr .topSs .editBtn {
  border: 2px solid rgba(128, 128, 128, 0.15);
  border-radius: 3.5px;
  padding: 2.5px 7.5px;
}
.registrationCtr .regRight .ssCtr .editSkills {
  width: 100%;
  border-radius: 3.5px;
  background-color: rgba(128, 128, 128, 0.15);
  padding: 7.5px 0;
  margin-top: 10px;
}
.registrationCtr .regRight .ssCtr .editSkills h4 {
  color: grey;
  font-weight: 600;
  text-align: center;
  font-size: 13.5px;
}
.registrationCtr .regRight h3 {
  color: white;
  font-weight: 600;
  letter-spacing: -0.65px;
}
.registrationCtr .regRight #showcase {
  animation: fadeInUp;
  animation-delay: 0.25s;
  animation-fill-mode: both;
  animation-duration: 0.65s;
  animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}
.registrationCtr .regRight p {
  color: white;
  opacity: 0.55;
  margin: 25px 0;
  letter-spacing: -0.65px;
  max-width: 450px;
  text-align: center;
}
.registrationCtr .regRight #showcaseP {
  animation: fadeInUp;
  animation-delay: 0.35s;
  animation-fill-mode: both;
  animation-duration: 0.65s;
  animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}
.registrationCtr .regRight .lightBox {
  position: absolute;
  top: 0;
  right: 0;
  height: 200px;
  width: 225px;
  background-color: #388de2;
  border-bottom-left-radius: 5.5px;
}
.registrationCtr .regRight .stepsCtr {
  display: flex;
}
.registrationCtr .regRight .stepsCtr .dot {
  height: 7px;
  width: 7px;
  background-color: white;
  opacity: 0.35;
  border-radius: 50%;
  margin: 0 5.5px;
  margin-top: 15px;
}
.registrationCtr .regRight .stepsCtr .dot.active {
  opacity: 1;
}
.dotFillerCtr {
  position: absolute;
  top: -50px;
  right: -50px;
}
.ssB {
  position: absolute;
  bottom: -50px;
  left: -50px;
}
.dotFiller {
  height: 125px;
  width: 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.dotFiller .dot {
  min-height: 8px;
  min-width: 8px;
  margin: 5px;
  background-color: white;
  border-radius: 1.5px;
  opacity: 0.135;
}
.buttonCtr {
  display: flex;
  align-items: center;
}
.buttonCtr .backBtn {
  width: 12.5%;
  margin-right: 5px;
  height: 34px;
  background-color: #388de2;
  border-radius: 2.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.buttonCtr .backBtn h3 {
  color: white;
}
.signIn {
  text-align: center;
  color: #95a5a6;
}
.signIn span {
  color: #2e86de;
  font-weight: 600;
  font-size: 13.5px;
  cursor: pointer;
}
.error {
  font-size: 13.5px;
  font-weight: 600;
  letter-spacing: -0.75px;
  color: #c03a2bd7;
  padding-top: 15px;
}
@media (max-width: 1075px) {
  .registrationCtr .regRight {
    display: none;
  }
  .registrationCtr .regLeft {
    width: 100vw;
  }
}
@media (max-width: 550px) {
  .registrationCtr .regLeft {
    padding: 25px;
    min-width: inherit;
  }
  .registrationCtr .regLeft h2 {
    margin-bottom: 0px;
  }
}
