.avatar{
    width: 100%;
    height: 45rem;
    padding: 0;
    position: relative;
    border: 2px solid $color_border;
    border-radius: 1rem;


    &_image{
    
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
    


    }
    

    .upload-btn-wrapper{
        position: absolute;
        bottom: 0rem;
        left: 16rem;
        z-index: 200;
        
    }
}
