.control-section .resources_header .resources_header_left .pallette, .grid-div .resources_avail_header.availability .resources_avail_header_left .pallette {
    display:flex;
    /* justify-content: space-evenly; */
    flex-wrap: wrap;
    width:100%;
    align-items: center;
  }
  .grid-div .resources_avail_header.availability .resources_avail_header_left .pallette {
    justify-content: center;
  }
  .avail .resources_avail_header p {
    margin-bottom: 20px;
  }
  .control-section .resources_header .resources_header_left .title h3{
    font-weight: bold;
  }
  
  
  .control-section .resources_header .resources_header_left .pallette p, .control-section .resources_header .resources_header_left .pallette .colorBox, .grid-div .resources_avail_header.availability .resources_avail_header_left .pallette .colorBox {
    margin: 0 0 30px 0;
    font-size: 1.5rem!important;
  }
  .control-section .resources_header .resources_header_left .pallette .colorBox, .grid-div .resources_avail_header.availability .resources_avail_header_left .pallette .colorBox {
    height: 15px;
    width: 15px;
    background-color: red;
    margin-right: 10px;
    margin-left: 10px;
  }
  .control-section .resources_header .resources_header_left .pallette .colorBox.planned, .grid-div .resources_avail_header.availability .resources_avail_header_left .pallette .colorBox.planned {
    background-color: #636e72;
  }
  .control-section .resources_header .resources_header_left .pallette .colorBox.draft, .grid-div .resources_avail_header.availability .resources_avail_header_left .pallette .colorBox.draft {
    background-color: #fdcb6e;
  }
  .control-section .resources_header .resources_header_left .pallette .colorBox.booked, .grid-div .resources_avail_header.availability .resources_avail_header_left .pallette .colorBox.booked {
    background-color: #82ccdd;
  }
  .control-section .resources_header .resources_header_left .pallette .colorBox.ongoing, .grid-div .resources_avail_header.availability .resources_avail_header_left .pallette .colorBox.ongoing {
    background-color: #2d98da;
  }
  .control-section .resources_header .resources_header_left .pallette .colorBox.compl, .grid-div .resources_avail_header.availability .resources_avail_header_left .pallette .colorBox.compl {
    background-color: #1dd1a1;
  }
  .control-section .resources_header .resources_header_left .pallette .colorBox.cancelled, .grid-div .resources_avail_header.availability .resources_avail_header_left .pallette .colorBox.cancelled {
    background-color: #ff6b6b;
  }