/**
 * Form Styles
 */

.sp-form {
  &_boxer {
    position: relative;
  }
  &_item {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    label {
      font-size: 1.2rem;
      font-weight: 600;
      padding-bottom: 8px;
    }
    &--inp,
    &--txtarea {
      border: none;
      outline: none;
      border-radius: 0%;
      padding: 0.5rem;
      font-size: 1.2rem;
      margin-bottom: 1rem;

      &:focus {
        outline: none;
      }
    }

    &--inp_btm,
    &--txtarea_btm {
      border-bottom: 2px solid $color_prim;

      &:focus {
        border-bottom: 2px solid $danger;
      }
    }
  }
  &_dropdown-s {
    padding: 10px;
    margin-top: -20px;
    margin-bottom: 20px;

    p {
      font-size: 12px;
    }

    &-switch {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &_dropdown {
    position: absolute;
    bottom: 50px;
    background-color: white;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: -20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    max-height: 200px;
    overflow-y: auto;

    p {
      font-size: 12px;
    }

    &-switch {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spbtn {
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
