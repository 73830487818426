._spDevVideoCard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 999;
    // background-color: white;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &-videoContainer {
    width: 100%;
    height: 100%;
    cursor: pointer;
    ._spVideoPlayer {
      padding-top: 0 !important;
      height: 100% !important;
      &-video {
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &-infoOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;

    z-index: 999;

    &--head {
      border-bottom: 1px solid #fefefe;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;

      cursor: pointer;

      &--left {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 5px;
        h4 {
          margin: 0 !important;
        }
      }
    }

    &--body {
      &._spAssBody--singleTab-content {
        min-width: unset !important;
        background-color: transparent;
      }
      padding: 10px;
      ._spAssBody--singleTab-content--difficulty {
        & > div {
          background-color: transparent !important;
          padding: 0 !important;
        }
      }
      ._spAssBody--singleTab-content--question {
        h5 {
          color: white !important;
        }
        p {
          color: white !important;
        }
      }
    }
  }
}
