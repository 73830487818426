.sptable {
  // overflow-x: auto;
  &_container {
    width: 100%;
    &-header {
      &-row {
        td {
          font-weight: 600;
        }
      }
    }

    td {
      padding: 0.2rem;
      // font-weight: 500;
      font-size: 1.3rem;
    }
  }
}
