.stable{
    // background-color: rebeccapurple;
    margin-top: 2rem;
    border: none;
    width: 100%;
    text-align: center;
    margin-bottom: 4rem;
    padding: 1rem;
    font-size: 1.3rem;

    &_header{
        border-bottom: 3px solid $color_border;
        // background-color: turquoise;
    }
    td{
        padding: .5rem 1rem !important;
    }


    
}