.brand_dialog-box h1,
.header-intro h1,
.resource-header h3 {
  font-weight: 700;
  letter-spacing: -1px;
  font-size: 25px;
  color: black;
}
h1,
h3 {
  letter-spacing: -1px;
}
.header-intro h1 {
  margin-top: 15px;
}
#bold {
  font-weight: 700;
  margin-bottom: -2.5px;
}
.column.avat img {
  height: auto;
  width: 300px;
}
.description h3 {
  font-size: 1.35rem;
  font-weight: 700;
  margin-top: -5px;
  font-size: 20px;
  letter-spacing: -1px;
}
.clientDescCtr {
  background-color: #fcf9e9;
  padding: 20px;
  border-radius: 2px;
  margin: 20px 0 25px 0;
  border: 1px solid rgba(128, 128, 128, 0.125);
}
.clientDescCtr.blue {
  background-color: #f7fafc;
}
.clientDescCtr.blue p {
  margin-bottom: 0 !important;
}
kbd {
  opacity: 0.425;
}
.clientDescCtr .warningCtr {
  display: flex;
  /* align-items: center; */
}
.warningCtr .warning {
  background-color: rgb(196, 133, 17);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  margin-top: 2.5px;
}
.clientDescCtr .warningCtr .warning h4 {
  color: white;
  margin: 0;
}
.clientDescCtr p,
.description p,
.clientDescCtr a {
  font-weight: 500;
  font-size: 13.5px;
  opacity: 0.9;
  margin-bottom: 0;
}
.clientDescCtr a {
  font-weight: 700;
}

.css-yk16xz-control {
  border: 1px solid rgba(128, 128, 128, 0.1) !important;
  font-weight: 500 !important;
}
.cta {
  margin-top: 15px;
}
.cta span {
  font-weight: 700;
  color: rgb(66, 158, 255);
  cursor: pointer;
}
.header svg {
  margin-right: -10px;
}
.header__right span {
  font-weight: 500 !important;
}
.header__right > div {
  display: flex;
  align-items: center;
}

.header__left-vendor {
  display: flex;
  margin-left: 30.5px;
  padding-bottom: 5px;
}
.header__left-vendor span {
  letter-spacing: -0.35px;
  text-transform: none;
  font-size: 13.5px;
  opacity: 0.85;
}
.header__left-search--input input {
  padding: 0.1rem 1rem;
  min-width: 30rem;
}
.header__left-result {
  position: absolute;
  background-color: white;
  max-height: 50vh;
  padding: 2rem;
  min-width: 30rem;
  overflow: auto;
  top: 5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.header__left-result--el {
  padding: 0.3rem;
  transition: background-color 0.2s;
}

.header__left-result--el:hover {
  cursor: pointer;
  background-color: rgba(66, 157, 255, 0.521);
}
.header__left-search--input input::placeholder {
  font-size: 13.5px !important;
  font-weight: 500;
  opacity: 0.75;
}
.header__right span {
  font-weight: 600;
  font-size: 12.5px;
  color: grey;
  margin-left: 3.5px;
}
.icon {
  font-size: 17.5px !important;
  opacity: 0.5;
  height: 1.7rem;
  width: 1.7rem;
}
.ttp {
  margin-left: 4.5px;
  cursor: pointer;
  padding: 5px;
  position: relative;
}
.ttp-icon {
  margin: 0;
  padding: 0;
  height: fit-content;
  font-size: 18.5px !important;
  margin-top: -4.5px;
  opacity: 0.35;
}
.ttp .ttp-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  z-index: 100;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.005),
    0 6.7px 5.3px rgba(0, 0, 0, 0.007), 0 12.5px 10px rgba(0, 0, 0, 0.0085),
    0 22.3px 17.9px rgba(0, 0, 0, 0.011), 0 41.8px 33.4px rgba(0, 0, 0, 0.0125),
    0 100px 80px rgba(0, 0, 0, 0.0175);
  border-radius: 3.5px;
  border: 1px solid rgba(128, 128, 128, 0.225) !important;
  transition: all 0.25s;
  opacity: 0;
  visibility: hidden;
  text-align: center;
}
.ttp:hover .ttp-content {
  opacity: 1;
  visibility: visible;
  top: 110%;
}
label {
  color: black !important;
}
.ttp .content p {
  margin: 0;
  font-weight: 500;
  font-size: 13.5px;
  opacity: 0.9;
  text-align: center;
  color: black !important;
}
.brand_dialog-box button {
  background-color: white !important;
  border: 1px solid rgba(128, 128, 128, 0.5) !important;
  font-size: 13.5px;
  font-weight: 600;
  color: black !important;
  opacity: 0.9;
  box-shadow: 0 37px 80px rgba(0, 0, 0, 0.11);
}
.btn_slid,
.btn_photo {
  width: 245px;
}
.btn_slid {
  margin-bottom: 25px;
}
.info_row-main {
  flex: inherit !important;
}
.info_row-main-preview,
.avat-preview {
  height: 165px;
}
.light-ttp {
  margin: 10px 0;
  opacity: 0.75;
  display: flex !important;
  align-items: center;
}
.light-ttp h4 {
  margin: 0;
  color: white;
}
.light-ttp .infoCtr {
  height: 16.5px;
  width: 16.5px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 8.5px;
}
.light-ttp p {
  margin: 0;
  font-size: 1.35rem;
  font-weight: 600;
}
.img-wrap {
  display: flex;
  flex-wrap: wrap;
  /* gap: 35px; */
}
/* YOURPRICES */
.markets_content-yourPrice h3,
.ctr_el_header h3,
.row.prods h3,
.stats .stat_header .title h3,
.domain h3,
.grid-div .title h3,
.unsubs-caroussel h3 {
  font-size: 2rem;
  font-weight: 700;
}
.grid-div .title {
  align-items: center;
}
.grid-div .title .icon {
  margin-top: -4px !important;
  opacity: 0.25;
}
.row.prods h3 {
  margin-bottom: 0;
}
.row.prods {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px !important;
}
.keywords,
.cardName {
  /* background-color: green; */
  width: 380px;
  padding-right: 20px;
}
.combinations {
  /* background-color: red; */
  width: 115px;
}
.variants {
  /* background-color: teal; */
  width: 75px;
}
.status,
.resources,
.locations {
  width: 85px;
}
.wide {
  width: 185px;
}
.rates {
  /* background-color: indigo; */
  width: 112.5px;
}
.row.prices_table-header {
  width: 950px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.225) !important;
  margin-top: 15px !important;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
}
#border:last-child {
  border-bottom: 1px solid rgba(128, 128, 128, 0.225) !important;
  width: 950px;
}
.row.prices_table-header h4,
.bottom th,
th,
.e-grid .e-headercelldiv {
  font-size: 11.175px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
}
.bottom table {
  margin-bottom: 30px;
  margin-left: 121.5px;
  border: 1px solid rgba(128, 128, 128, 0.35) !important;
  background-color: #f7fafc;
  padding: 15px !important;
  border-radius: 3.5px !important;
}
.spbtn.spbtn-blackbkg {
  background-color: white;
  color: black;
  opacity: 0.8;
  border: 1px solid rgba(128, 128, 128, 0.35) !important;
  border-radius: 3.5px;
  box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.165);
  font-size: 1.25rem;
}
.markets_content-yourPrice__head.row {
  width: 950px;
  justify-content: space-between;
}
.keywords.keywords_el,
.cardName.cardName_el,
table h4,
.contract_bottom td {
  font-size: 1.35rem;
  font-weight: 600;
}
.rates.rates_el p {
  font-weight: 600;
}
.status.status_el,
#statusBox {
  width: 55px;
  height: 20px;
  text-align: center;
  margin-right: 30px !important;
  border-radius: 3.5px;
}
#statusBox {
  width: 85px !important;
  margin-right: 10px !important;
  font-weight: 500 !important;
}
.status.status_el p {
  font-size: 1.15rem;
  margin: 0 !important;
  font-weight: 600;
}
.edit-dots {
  display: flex;
  margin-top: 8px;
  cursor: pointer;
}
.edit-dots.nm {
  margin-top: 0;
}
.edit-dots .dot {
  margin-right: 3px;
  height: 3.5px;
  width: 3.5px;
  background-color: black;
  opacity: 0.5;
  border-radius: 50%;
}
.length {
  font-size: 1.35rem;
  font-weight: 500;
  opacity: 0.85;
  /* margin-top: 15px; */
  margin-bottom: -20px;
}
.length span {
  font-weight: 600;
}
.bottom p,
.bottom a {
  font-size: 1.35rem !important;
  /* color: red; */
}
.stats .stat .bottom p {
  margin-bottom: 1.5rem !important;
}
.bottom.blue {
  background-color: #f7fafc;
  padding: 15px;
  border-radius: 3.5px;
  border: 1px solid rgba(128, 128, 128, 0.125);
}
.bold {
  font-weight: 600;
}
.bottom.blue a {
  font-weight: 600;
  margin: 0 !important;
}
.bottom.blue .arrow {
  color: #0057b3c0;
  margin-left: 3.5px;
  font-size: 15px !important;
}
.row.top {
  align-items: flex-start !important;
}
.bottom.light p,
.account-about p {
  color: grey;
  margin-top: 10px;
  font-size: 1.25rem !important;
}
/* account managers select dropdown*/
select.account_managers {
  border: none;
  outline: none;
  width: 120%;
  box-shadow: none;
  background-image: none;
  border-radius: 0.25em;
  padding: 0.5em 0.5em;
  font-size: 1.25rem;
  cursor: pointer;
  background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  margin-top: -4px;
}

select.account_managers:focus {
  outline: none;
}

select.account_managers > option {
  color: rgb(73, 72, 72);
  /* background-color: transparent; */
}

#label-row {
  width: 125px;
  text-align: right;
  margin-left: -15px;
}
table.account-users {
  margin-top: -4px !important;
}
table.account-users tr td p {
  color: grey;
  font-size: 1.25rem;
}
table.account-users thead tr th {
  border: none;
}
.account-users-del-btn {
  color: red;
  border: none;
  background-color: #fbfdfe;
}
.strike-through {
  text-decoration: line-through;
  color: green;
}
.user-del-icon {
  cursor: pointer !important;
}
.users {
  margin-bottom: 5px;
}
.invite_button {
  margin-top: -8px !important;
}
.stats .row.top .choice,
.stats .spbtn.add,
.account .e-editable-value,
.account .stats .stat .top p,
.uniqueID {
  margin-left: 12.5px;
  background-color: rgba(128, 128, 128, 0.175);
  border-radius: 3.5px;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: 700;
  color: grey !important;
}
.account .e-editable-value {
  font-weight: 500 !important;
  font-size: 12px !important;
}
#map-btn {
  margin-left: -5px;
  background-color: rgba(0, 0, 0, 0.75);
  height: 28px !important;
  width: 60px !important;
}
.stats input {
  padding-left: 10px;
}
.stats .agreement {
  margin-left: 121.5px;
}
.stats .agreement input {
  margin-right: 7.5px;
}
.stats .agreement label {
  font-size: 1.35rem;
  font-weight: 500;
}
.stats .agreement button {
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 1.35rem !important;
  font-weight: 700;
}
.stats td {
  font-size: 1.35rem;
  font-weight: 600;
}
.e-inplaceeditor .e-editable-value-wrapper .e-editable-overlay-icon::before {
  content: "edit";
}
.stats .icon {
  height: 100%;
  align-self: center;
  margin-left: 8.5px;
}
.row.btn {
  margin-left: 114px !important;
  margin-top: 15px !important;
}
#cancel {
  background-color: rgba(128, 128, 128, 0.15) !important;
  color: grey !important;
  border-radius: 3.5px;
  font-size: 1.35rem;
  margin-right: 10px;
  border: none;
}
#create {
  border-radius: 3.5px;
  font-size: 1.35rem;
}
.bottom.blue.terms {
  margin-left: 121.5px;
  width: 650px;
}
#status {
  margin-bottom: 15px !important;
  align-items: center !important;
  /* margin-left: 121.5px !important; */
}
#status
  .e-inplaceeditor
  .e-editable-value-wrapper
  .e-editable-overlay-icon::before {
  margin-bottom: -2.5px;
  min-width: 50px;
  padding-left: 2.5px;
}
.account-topnav-container {
  display: flex;
  justify-content: space-between;
}
.account-topnav {
  display: flex;
  align-items: center;
  margin-bottom: -15px;
  margin-left: 5.5px;
  margin-top: 15px;
}
.account-topnav .arrow {
  margin: 0 10px;
  margin-top: -2.5px;
}
.account-topnav h4,
.welcome {
  font-weight: 600;
  font-size: 1.35rem;
}
.welcome {
  position: relative;
  bottom: -16px;
}
.account-topnav .edit {
  color: #0057b3c0;
  cursor: pointer;
}
.admin-account-name {
  margin-right: 15px !important;
}
.admin-account-name,
.account-name {
  margin-left: auto;
}
.row.top.togglebox {
  align-items: center !important;
}
.account .stats {
  background-color: #f7fafc77;
  border: 1px solid rgba(128, 128, 128, 0.125);
  border-radius: 5px;
}
.account .stats .stat {
  padding: 0;
}
.account .stat_header {
  padding: 15px;
  border-radius: 5px;
}
.account .stats .stat_content_data {
  padding: 15px;
}
.e-switch-wrapper .e-switch-handle.e-switch-active {
  background-color: #0057b3;
}
.e-switch-wrapper .e-switch-on {
  background-color: #0057b3b7;
}
.stat_header.settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stat_header.settings .current-user {
  display: flex;
  align-items: center;
}
.stat_header.settings .current-user p,
.stat_header.settings .current-user .userIcon {
  font-size: 11.15px;
  color: grey;
  font-weight: 600;
  margin: 0 3.5px;
  font-family: "Courier New", Courier, monospace;
}
.account .bottom.light {
  margin-top: -7.5px;
}
.reviews .testimonials .testimonial {
  justify-content: flex-start;
}
.reviews .testimonials .testimonial:first-child {
  margin-top: 15px !important;
}
.reviews .testimonials .testimonial_description {
  flex-basis: 25%;
}
.reviews .testimonials .testimonial_author {
  flex-basis: 25%;
}
.testimonial_author__avatar-img {
  border: 3px solid rgba(128, 128, 128, 0.25);
  border-radius: 50%;
  margin-top: -3px;
}
.reviews .testimonials h3,
.reviews .testimonials p {
  font-size: 1.35rem;
}
.reviews .testimonials h3 {
  font-weight: 700;
}
.reviews .testimonials p {
  color: grey;
}
#company {
  font-weight: 600 !important;
}
#italic {
  font-style: italic;
}
.reviews .testimonials .testimonial_actions {
  align-items: flex-start;
  margin-left: 1rem;
  gap: 1rem;
}
.account .btn {
  font-weight: 600;
}
.stats .stat_header .description,
.resource-header .description {
  font-size: 1.35rem;
  font-weight: 500;
  color: grey;
  letter-spacing: -0.25px;
  line-height: 125%;
  margin-top: -2px;
}
.desc-icon {
  display: flex;
  align-items: flex-start;
  margin-top: 2.5px;
}
.desc-icon .descIcon {
  font-size: 1.35rem;
  color: grey;
  margin-right: 3.5px;
  margin-bottom: -5px;
}
.e-grid .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  color: grey;
}
.e-btn {
  text-transform: none;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 10%), 0 2px 2px 0 rgb(0 0 0 / 7%),
    0 1px 5px 0 rgb(0 0 0 / 6%);
}
.e-control.e-btn.bulkUpload-btn {
  background-color: white;
  border: 1.5px solid rgba(128, 128, 128, 0.3);
}
.resource-header:first-child {
  margin-top: 15px;
}
.account .row.top {
  align-items: center !important;
}
.account-div_info .account-intro {
  flex-basis: 100%;
}

.account .avatar_image,
.account .account-photo,
.account .account-div_info .account-photo .avatar {
  height: 125px;
  width: 125px;
  border: none;
}
.account-div_info .account-intro_top {
  justify-content: space-between;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 10px;
}
.account-div_info .account-intro_top .name .personal-info-content h3,
.account-div_info .account-intro_bottom .account-about_header h2,
.completed.row p {
  color: #1b1f36;
  font-weight: 600;
  font-size: 1.5rem;
  width: 85px;
}
.account-div_info .account-intro_bottom .account-about_header h2,
.completed.row p {
  width: fit-content;
  margin: 0;
}
.account-div_info .account-intro_bottom .account-about_header {
  align-items: center;
  margin-bottom: 17.5px;
}
.account-div_info .account-intro_top .completed {
  align-items: flex-start;
}
.indicator h2 {
  font-size: 1.25rem;
  font-weight: 600;
}
.account-div_info .account-intro_top .name .personal-info-content {
  margin-bottom: 10px;
}

/* LOGIN */
.login {
  width: 600px;
  border-radius: 5px;
  box-shadow: 0 0px 3.7px rgba(0, 0, 0, 0.018),
    0 0px 12.3px rgba(0, 0, 0, 0.032), 0 0px 55px rgba(0, 0, 0, 0.06);

  background-color: white;
  height: fit-content;
}
.login .login-left .login-div {
  align-items: flex-start;
}
.circle {
  display: none;
}
.input-div {
  width: 100%;
}
.login-div h1 {
  font-weight: 700;
  letter-spacing: -1.25px;
  opacity: 0.85;
  margin-bottom: 35px;
}
.login .login-left p {
  font-weight: 600;
  color: #1b1f36;
  opacity: 0.85;
  font-size: 1.325rem;
}
.login .login-left .button-div a {
  background: rgb(66, 158, 255) !important;
  border-radius: 5px !important;
  margin-left: 0px !important;
}
.pw-forget {
  padding: 0 !important;
  background: transparent !important;
  font-weight: 600 !important;
  margin: 0 !important;
  margin-top: -15px !important;
}
#pw-input {
  margin-bottom: 10px;
}
.login-left {
  overflow: visible !important;
}
.login-left .spryte-ctr {
  position: absolute;
  top: -45px;
  left: 5px;
}
.login-left .spryte {
  font-weight: 800 !important;
  letter-spacing: -1px;
  color: #1b1f36 !important;
  opacity: 0.925;
  font-size: 2rem;
  margin-bottom: 0 !important;
}
.login-left .register {
  position: absolute;
  bottom: -50px;
  left: 5px;
  opacity: 0.85 !important;
}
.login {
  margin-top: -20px;
}
.input-div input {
  padding-left: 15px !important;
  font-size: 1.7rem !important;
}
.register span {
  font-weight: 600 !important;
  color: rgb(66, 158, 255);
}
#root .nav-content .nav-logo h2 {
  font-weight: 700 !important;
  letter-spacing: -1px !important;
  opacity: 0.85;
}
.login-left .spryte-circle {
  height: 25px;
  width: 25px;
  background-color: #1b1f36 !important;
  border-radius: 50%;
  margin-left: 8.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* opacity: 0.35; */
}
.signed-in {
  display: flex;
  align-items: center;
  margin: 25px 0 40px 0px !important;
}
.signed-in input {
  width: fit-content !important;
  margin: 0 !important;
  margin-right: 7.5px !important;
}
.signed-in p {
  margin: 0 !important;
}
.login .login-left .login-div .error {
  width: 100%;
  bottom: 9%;
  opacity: 0.75;
  font-size: 1.25rem;
}
@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0.25;
    transform: scale(0.75);
  }
}
.pulse-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pulse-bubble {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e6e6e6;
  margin: 0 3px;
}
.pulse-container.small .pulse-bubble {
  height: 6.5px;
  width: 6.5px;
  margin: 0 1.75px;
}
.pulse-bubble-1 {
  animation: pulse 0.325s ease 0s infinite alternate;
}
.pulse-bubble-2 {
  animation: pulse 0.325s ease 0.2s infinite alternate;
}
.pulse-bubble-3 {
  animation: pulse 0.325s ease 0.4s infinite alternate;
}
/* .e-grid .e-rowcell {
  padding: 0 !important;
} */
/* .e-row .e-rowcell {
  padding: 0;
} */
table .small {
  width: 25px;
}
table .med {
  width: 135px;
}
table .lg {
  width: 300px;
}
.accRow td h4 {
  margin-bottom: 0;
}
.accRow .row {
  align-items: center !important;
  margin-bottom: 0 !important;
  height: 26px;
}
.accRow .row svg {
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 50%;
  margin: 0 5px;
  opacity: 0.55;
}
.indicator h2 {
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 700;
  opacity: 0.35;
}
.account-div_info .account-intro_top .name h1 {
  font-size: 2.5rem;
  letter-spacing: -1px;
  margin-bottom: -7.5px;
}
.link {
  font-weight: 600;
  background-color: rgba(66, 157, 255, 0.15);
  padding: 1.5px 10px;
  border-radius: 3.5px;
}
.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background-color: rgb(41, 41, 41);
}
.stable {
  text-align: left;
}
.stable td {
  padding-left: 0 !important;
}
.contract_cont .ctr_el_header svg {
  margin-bottom: 0.3rem;
}
.add_btn svg {
  color: rgb(71, 70, 70);
}
.contract_bottom svg {
  color: rgba(255, 0, 0, 0.25);
}
.marketSearch .stackItem h3 {
  font-size: 1.35rem;
  letter-spacing: -0.5px;
}
.marketSearch .selectedStackDiv .selectedStackItem .close h3 {
  color: white;
  font-size: 15px;
  margin: 0 !important;
}
.marketSearch .selectedStackDiv .selectedStackItem .close {
  transition: none;
}
.marketSearch .selectedStackDiv .selectedStackItem p {
  margin: 0;
  font-size: 1.15rem;
  font-weight: 500;
}
.marketSearch_close-icon {
  color: grey;
}
.marketSearch .stackItem {
  margin: 0;
}
.download {
  border-radius: 2.5px;
  font-weight: 500;
}
.e-grid .e-gridheader .e-sortfilter .e-headercelldiv {
  padding: 0;
}
.e-pagenomsg,
.e-pagecountmsg {
  color: grey;
  font-weight: 500;
}
.header {
  position: relative;
}
.header .link {
  background: none;
}
.request .markets_content-yourPrice {
  margin: 0;
}
.request .markets_content-yourPrice__head.row {
  width: fit-content;
}
.request .markets_content-yourPrice__head.row button {
  margin-left: 15px;
}
.request .markets_content-yourPrice,
.request .row.prices_table-header,
.request #border:last-child {
  width: 800px;
}
.request .cardName {
  padding: 0;
  width: 250px;
  margin-left: 7.5px;
}
.request .row.prices_table-header {
  margin-left: 13.5px !important;
}
.request_form {
  width: fit-content;
}
.lighter {
  font-weight: 500;
  color: grey !important;
  margin-top: -2.5px;
  line-height: 135%;
}
.ctr.security .add_btn {
  font-size: 1.6rem;
}
.markets .row {
  flex-wrap: nowrap;
}
.certification_header .stats .stat {
  padding: 0;
}
.contract_bottom a {
  background-color: grey;
}
.control-section .resources_header .resources_header_left .pallette p {
  font-size: 1.15rem !important;
  font-weight: 600;
  margin-right: 10px;
}
.control-section .resources_header .resources_header_left .pallette .colorBox {
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-left: 0;
  /* margin-bottom: 10px; */
}
.brand_dialog {
  padding: 0;
}
.img-wrap {
  position: relative;
  overflow: visible;
}
.brand_dialog-box__inputs section .info_row-main-preview {
  padding-left: 241px;
}
.img-wrap button {
  position: absolute;
  bottom: 0;
}
.wrapper {
  border-radius: 0;
  box-shadow: none !important;
  font-size: 1.35rem !important;
}
.wrapper svg {
  display: none;
}
.rVMKv {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.01),
    0 6.7px 5.3px rgba(0, 0, 0, 0.014), 0 12.5px 10px rgba(0, 0, 0, 0.015),
    0 22.3px 17.9px rgba(0, 0, 0, 0.021), 0 41.8px 33.4px rgba(0, 0, 0, 0.025),
    0 100px 80px rgba(0, 0, 0, 0.035);
}
.rVMKv {
  font-weight: 600;
  font-size: 12.5px;
  opacity: 0.85;
}
.column.mb {
  margin-bottom: 15px;
}
.row.top.center {
  align-items: center !important;
}
.uniqueID {
  font-weight: 700 !important;
}
.lmOZQa {
  opacity: 0.15;
}
.login .login-left .login-div {
  padding: 40px;
}
.login-div a {
  font-size: 1.35rem !important;
}
.login .login-left input {
  padding: 5px 0;
}
.checkbox.priceCard_header {
  margin-top: 3.5px !important;
}
.row.top.volume {
  width: 988.59px;
}
.market-info {
  color: grey;
  margin-right: 6.5px;
}
.info-ctr {
  display: flex;
}
.info-ctr p {
  text-align: left;
  color: grey;
  opacity: 0.75 !important;
  margin-bottom: 0 !important;
}
.info-ctr span {
  font-weight: 600;
}
.row.back {
  align-items: center !important;
}
.row.back .arrow {
  margin-right: 5px;
}
.brand_dialog-box__inputs section .info .avat {
  background-color: rgba(128, 128, 128, 0.075);
  width: 24.5rem;
}
.brand_dialog-box__inputs section .info_row-main-preview {
  background-color: rgba(128, 128, 128, 0.075);
  margin-right: 15px;
}
.brand_dialog-box__actions {
  justify-content: flex-start;
  margin-bottom: 40px;
}
.brand_dialog-box.add #addCase_sub {
  background-color: #1e96f5 !important;
  color: white !important;
  border: none !important;
}
.css-g1d714-ValueContainer,
.css-yt9ioa-option {
  font-weight: 600;
  font-size: 11px;
  padding-left: 10px;
}
.spbtn.spbtn-term {
  font-size: 1.35rem !important;
  font-weight: 500;
}
.term-text {
  width: 375px;
  font-style: italic;
  opacity: 0.8;
}
.info_row-main-preview_imgBox {
  position: relative;
}
.brand_dialog-box__inputs section .info_row-main-preview_imgBox {
  height: 165px !important;
}
.info_row-main-preview_imgBox .delete {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 2.5rem;
  z-index: 100;
}
.avat-preview svg,
.brand_dialog-box__inputs section .info_row-slides-preview__imgBox svg {
  height: 25px;
  width: 25px;
  background-color: white;
  color: black;
  border-radius: 50%;
}
.avat-preview svg {
  z-index: 100;
  margin-left: -15px;
}
.account .account-photo {
  margin-right: 15px;
  margin-left: 10px;
}
.brand_dialog-box__inputs section .info_row-slides-preview__imgBox--img {
  height: 250px;
  width: 300px;
}
.brand_dialog-box__inputs section .info_row-slides-preview {
  padding: 0;
  height: 265px;
}
.img-wrap img {
  height: 150px !important;
  width: 250px !important;
  object-fit: cover;
}
.bottom.blue a,
.bottom.blue .arrow {
  margin-top: 15px !important;
}
.dev_name.row {
  align-items: center;
}
.dev_name.row h1 {
  margin-right: 10px;
}
.account-intro_info h3,
.column.discovery h3,
.column.bookable h3,
#completion {
  font-weight: 600 !important;
  font-size: 11.5px !important;
  letter-spacing: 0 !important;
  text-transform: uppercase;
  margin: 0 !important;
}
.account-intro_info p,
.column.discovery p,
.column.bookable p {
  opacity: 0.55;
  font-size: 12.5px !important;
}
.row.top.togglebox {
  margin-bottom: 5px !important;
}
.account-intro_info .position {
  font-weight: 600 !important;
  text-transform: uppercase;
  opacity: 1;
}
#label {
  font-weight: 500;
  letter-spacing: 0;
  font-size: 11px;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: -2.5px;
  margin-top: 7.5px;
}
#expboxinput {
  width: 100%;
  padding: 5px;
  border: 1px solid rgba(128, 128, 128, 0.25) !important;
}
#expboxinput:focus {
  outline: none;
}
#add {
  background-color: #1e96f5 !important;
  color: white !important;
  border-radius: 3.5px;
  font-size: 1.35rem;
  margin-right: 10px;
  border: none;
  font-weight: 600;
  padding: 5px 10px;
  margin-right: 0;
}
#listDef {
  margin-top: -6.5px;
  font-weight: 500;
  font-style: italic;
  opacity: 0.55;
  font-size: 13.25px;
}

/* GridComponent search bar and icon */
.e-control .e-input-group input,
.e-control .e-input-group input.e-input {
  width: 350px;
  padding: 5px 10px;
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
  width: 300px;
}

.e-grid
  .e-toolbar-items
  .e-toolbar-item.e-search-wrapper
  .e-search
  .e-search-icon {
  font-size: 20px;
  margin: -8px 3px 0 0;
}
