.stats {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 1.4rem;
  flex-wrap: nowrap;

  // .row {
  //   margin: 0;
  // }

  .stat {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    padding: 1rem;

    p {
      margin: 0;
    }

    &_header {
      border-bottom: 1px solid $color_border;
      padding-bottom: 1rem;

      .title {
        display: flex;
        gap: 0.5rem;
        // margin-top: 2rem;

        h1 {
          font-weight: 700;
        }
        svg {
          font-size: 1.4rem;
          // margin-top: 1rem;
        }
      }
      .description {
        font-size: smaller;
        margin-top: -0.5rem;
      }
    }

    &_content_data {
      margin-top: 1rem;
      // margin-left: 0.1rem;
      // margin-right: 0.1rem;
      border-bottom: 1px solid $color_border;
      font-size: 1.4rem;
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      gap: 2rem;
      padding: 1rem 0;

      &-title {
        flex-basis: 20%;
        max-width: 13.5rem;
        h4 {
          font-weight: 600;
        }
      }

      &-content {
        flex-basis: 80%;
      }
    }

    .bottom {
      p {
        // margin-top: -0.7rem;
        // margin-left: -1.5rem;
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
      }
    }
    .leadTime {
      gap: 3rem;
      // background-color: rebeccapurple;
    }
    .top {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      // margin-bottom: 0.5rem;
      p {
        font-weight: 500;
      }
    }

    label {
      font-weight: 600;
      margin: 0;
    }
  }
}
