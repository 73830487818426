#bulk-upload{
    .bulk{
        width: 100vw;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: .7);
        position: fixed;
        left: 0%;
        top: 0%;
        z-index: 1000;
        
        // display: flex;
        // align-items: center;
        // align-content: center;
        // justify-content: center;

        &-dialog{
            position: relative;
            top: 5%;
            left: 10%;
            background-color: $white;
            height: 80vh;
            width: 60vw;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            


            &_close{
                position: absolute;
                top: 0%;
                right: 0;
                font-size: 2rem;

                :hover{
                    cursor: pointer;
                    color: $danger;
                }
            }
            &_header{
                flex-basis: 5%;
            }

            &_content{
                // height: 95%;
                flex-basis: 85%;
                // min-height: 70%;
                flex-shrink: 3;
                overflow: auto;
                width: 100%;
                font-size: 1.3rem;
                // background-color: rosybrown;
                textarea{
                    width: 100%;
                    padding-top: 2rem;
                    border: none;
                    border-bottom: .2rem solid $color_border;
                    overflow: hidden;
                    outline: none;

                    :active{
                        border-color: red;
                        background-color: red;
                    }
                    :focus{
                        border-color: red;
                        background-color: red;
                    }

                    
                }
            }

            &_footer{
                max-height: 70%;
                // min-height: 10%;
                // overflow: hidden;
                flex-grow: 3;
                flex-basis: 10%;
                display: flex;
                align-self: flex-end;
                align-items: flex-end;
                align-content: flex-end;
                justify-content: space-between;
                width: 100%;

                svg{
                    font-size: 1rem !important;
                }

                &__btn{
                    display: flex;
                    gap: .3rem;
                    &-sub{
                        background-color: $color_prim;
                        color: $white;

                        &:hover{
                            background-color: darken($color: $color_prim, $amount: 20);
                        }

                    }

                    &-close{
                        background-color: $danger;
                        color: $white;

                        &:hover{
                            background-color: darken($color: $danger, $amount: 20);
                        }
                    }

                }
            }

        }
    }
}