.grid {
  &_auto_8 {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(8, auto);
  }
  &_auto_7 {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(7, auto);
  }
  &_auto_6 {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(6, auto);
  }
  &_auto_5 {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(5, auto);
  }
  &_auto_4 {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, auto);
  }
  &_auto_3 {
    display: grid;
    align-items: baseline;
    gap: 1rem;
    grid-template-columns: repeat(3, auto);
  }
  &_auto_2 {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, auto);
  }
}
