.singleMatchPosition {
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 10px;

  &--info {
    display: flex;
    gap: 10px;
    align-items: center;

    &-match {
      display: flex;
      flex-direction: column;
      padding: 5px 10px;

      .count {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: -5px;
      }
    }

    &-posInfo {
      display: flex;
      flex-direction: column;
      gap: 5px;
      flex: 1;
    }
    &-price {
      background-color: #e0e0e0;
      display: flex;
      flex-direction: column;
      padding: 5px 10px;
      align-items: center;

      .price {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  &--extra {
    border: 1px solid #d7d7d7;
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;

    p {
      flex: 1;
      display: flex;
      justify-content: center;
      padding: 5px 10px;
      &:not(:last-child) {
        border-right: 1px solid #ccc;
        padding-right: 5px;
      }
    }
  }
}
