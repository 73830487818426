.spColaps {
  position: relative;
  background-color: aqua;
  transition: all 1s;
  overflow: hidden;
  &.full {
    height: 100%;
  }
  &.short {
    max-height: 2rem;
  }
  &-head {
    width: 100%;
    &__right {
      position: absolute;
      right: 0;
      top: 1rem;
      &-icon {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
