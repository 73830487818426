.dataSearch {
  //   background-color: red;
  position: relative;
  &_searchedValues {
    max-height: 80rem;
    overflow: auto;
    position: absolute;
    background-color: white;
    width: 100%;
    padding: 2rem;
    bottom: 3.5rem;
    box-shadow: 1px -2px 12px -3px rgba(0, 0, 0, 0.84);
    -webkit-box-shadow: 1px -2px 12px -3px rgba(0, 0, 0, 0.84);
    -moz-box-shadow: 1px -2px 12px -3px rgba(0, 0, 0, 0.84);

    p {
      padding: 0.5rem;
      &:hover {
        background-color: rgba($color_prim, 0.2);
        cursor: pointer;
      }
    }
  }
}
