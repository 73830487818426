.editPopup {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.65);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 400;
  }
  .editPopup {
    background-color: rgba(0, 0, 0, 0.75);
  }
  .editPopup .editPopupContent {
    /* height: 500px; */
    width: 500px;
    border-radius: 3.5px;
    background-color: white;
    position: relative;
    animation: fadeInUp;
    animation-fill-mode: both;
    animation-duration: 0.325s;
    animation-delay: 0.1s;
    animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    padding: 32.5px;
  }
  .editPopup .editPopupContent.search {
    width: 650px;
    height: 575px;
  }
  .editPopup .editPopupContent.search input {
    margin-bottom: 0 !important;
  }
  .editPopup .editPopupContent.search button {
    position: absolute;
    bottom: 26.5px;
    right: 20px;
  }
  .editPopup .editPopupContent.search .bottom {
    height: fit-content;
    overflow: hidden;
  }
  .editPopup .editPopupContent.search .bottom p {
    margin-bottom: 5px;
    text-transform: uppercase;
    opacity: 0.9;
    letter-spacing: 0;
    font-weight: 500;
    font-size: 10.5px;
    color: #2d3436;
    opacity: 0.9;
  }
  .editPopup .editPopupContent.search .bottom .chipDiv:first-child {
    padding-bottom: 45px;
    padding-top: 25px;
  }
  .editPopup .editPopupContent.search .bottom .chipDiv.row {
    display: flex;
    border: none;
    padding-bottom: 0;
  }
  .editPopup .editPopupContent.search .bottom .chipDiv.row .fixed-width {
    margin-right: 10px;
  }
  #wideDesc {
    height: 150px !important;
    width: 600px !important;
    padding: 10px !important;
  }
  .chipDivNote {
    font-size: 10px;
    width: 500px;
    font-weight: 600;
    opacity: 0.5;
    margin: -10px 0 10px 0;
  }
  .editPopup .editPopupContent.search .bottom .chipDiv:last-child {
    border-bottom: none;
  }
  .editPopup .editPopupContent {
    width: 525px;
    height: 375px;
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #9a9fa57c;
  }
  .editPopup .editPopupContent .top {
    border-radius: 3.5px 3.5px 0 0;
    background-color: #d8d8d854;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #9a9fa521;
  }
  .editPopup .editPopupContent .close {
    top: 350px !important;
  }
  .editPopup .editPopupContent .top button {
    margin: 0;
    border-radius: 2.5px;
    font-size: 12px;
    padding: 3.5px 15px;
    letter-spacing: 0;
    margin-left: -4px;
    background-color: #2d3436;
  }
  .editPopup .editPopupContent .top p {
    color: #cbd3c8;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .editPopup .editPopupContent .bottom {
    height: 82.5%;
    overflow-y: auto;
  }
  .editPopup .editPopupContent .bottom p {
    color: #9a9fa5;
    font-size: 12.5px;
    letter-spacing: 0;
  }
  .editPopup .editPopupContent .bottom .fixed-width {
    width: 175px;
  }
  .editPopup .editPopupContent .bottom .chipDiv {
    padding: 20px 17.5px;
    border-bottom: 1px solid #9a9fa57c;
    display: flex;
  }
  .editPopup .editPopupContent .bottom.mail button {
    background-color: #2d3436;
    font-size: 12.25px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 4.5px 11.5px;
    border-radius: 1.5px;
    letter-spacing: -0.15px;
    margin-top: 25px;
  }
  .editPopup .editPopupContent .bottom .darkBtn {
    background-color: #2d3436;
    color: #ffffff;
    font-size: 12.25px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 4.5px 11.5px;
    border-radius: 1.5px;
    letter-spacing: -0.15px;
  }
  .editPopup .editPopupContent .bottom.mail h4 {
    margin-bottom: 5px;
  }
  .editPopup .editPopupContent .bottom.mail {
    padding: 17.5px;
    height: fit-content;
  }
  .editPopup .editPopupContent .bottom.mail input {
    padding: 4px 8.5px;
    border: 1.5px solid rgba(128, 128, 128, 0.35);
    font-size: 14px;
    font-weight: 500;
    border-radius: 1.5px;
    margin: 25px 0 0 0;
    width: 450px;
  }
  .editPopup .editPopupContent .bottom.mail .chipDiv {
    border-bottom: none;
  }

  .editPopup .editPopupContent .close {
    position: absolute;
    top: 15px;
    right: 25px;
    cursor: pointer;
  }

  .editPopup .editPopupContent.mail {
    height: 260px !important;
  }

  @media (max-width: 1250px) {
    .editPopup .editPopupContent.search .bottom .chipDiv:first-child {
      padding-top: 0;
    }
  }