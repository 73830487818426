.dft {
  width: 40vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  .form-row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-content: space-between;
    .form-group {
      flex-grow: 1;
    }
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

#overviewgrid_dialogEdit_wrapper {
  background-color: rgb(169, 253, 225) !important;
  border-radius: 20px !important;
  max-height: 100vh !important;
}
