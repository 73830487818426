.docUpload {
  &_docs {
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: stretch;

    &-doc {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: space-between;
      align-content: center;

      &__icons {
        position: relative;
        .MuiSvgIcon-root.docUpload_docs-doc__icons-file {
          font-size: 6rem;
          color: $color_prim !important;
          &:hover {
            color: $color_prim !important;
            cursor: auto !important;
          }
        }

        .MuiSvgIcon-root.docUpload_docs-doc__icons-del {
          position: absolute;
          left: 0;
          color: $danger !important;
          z-index: 100;
        }
      }
      p {
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}
