.confirmationCtr {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .confirmationCtr .confirmationCtrContent {
    width: 550px;
    background-color: rgb(248, 248, 248);
    border-radius: 5px;
    animation: fadeInUp;
    animation-fill-mode: both;
    animation-duration: 0.325s;
    animation-delay: 0.1s;
    animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  }
  .confirmationCtr .confirmationCtrContent .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: white;
    border-radius: 5px 5px 5px 5px;
  }
  .confirmationCtr .confirmationCtrContent .top .warning {
    fill: #e74c3c;
    font-size: 32.5px;
    margin: 7.5px 0;
  }
  .confirmationCtr .confirmationCtrContent .top h2 {
    font-weight: 600;
    color: #e74c3c;
    font-size: 20.5px;
  }
  .confirmationCtr .confirmationCtrContent .bottom {
    padding: 35px;
  }
  .confirmationCtr .confirmationCtrContent .bottom h4 {
    font-size: 14.5px;
  }
  .confirmationCtr .confirmationCtrContent .bottom ul {
    padding-left: 35px;
    margin: 15px 0;
  }
  .confirmationCtr .confirmationCtrContent .bottom ul li {
    font-size: 15px;
    font-weight: 500;
    opacity: 0.75;
    margin: 5px 0;
  }
  .confirmationCtr .confirmationCtrContent .bottom .btnCtr {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    margin-top: 20px;
  }
  .confirmationCtr .confirmationCtrContent .bottom .btnCtr button {
    padding: 3.5px 7.5px;
    border-radius: 3.5px;
    width: 125px;
    margin: 0 7.5px;
    font-weight: 500;
    font-size: 11.5px;
  }
  .confirmationCtr .confirmationCtrContent .bottom .btnCtr #yes {
    background-color: #2d3436;
    opacity: 0.85;
  }
  .confirmationCtr .confirmationCtrContent .bottom .btnCtr #no {
    background-color: transparent;
    border: 2px solid grey;
    color: grey;
    opacity: 0.425;
  }
  .confirmationCtr .confirmationCtrContent .bottom .btnCtr #delete {
    background-color: #dc2430;
    opacity: 0.75;
    color: white;
  }
  .confirmationCtr .confirmationCtrContent .bottom input {
    background-color: transparent;
    border: 1.5px solid #2d343628;
    border-radius: 1.5px;
    padding: 2.5px 5px;
    font-size: 13.5px;
    font-weight: 500;
    margin: auto;
  }
  .confirmationCtr .confirmationCtrContent .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }