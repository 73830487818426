.css-14pt78w-MuiSlider-rail {
  height: 30% !important;
}

._spTechstackGrid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 2rem;

  &SingleRow {
    border: 1px solid #dfdfdf;
    background: #fff;
    padding: 20px;

    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &:hover {
      cursor: pointer;

      ._spTechstackGrid-row-close-icon {
        display: block;
      }
    }
  }

  &-item--details-range {
    // display: flex;
    // align-items: center;
    flex: 1;
    margin-top: 7px;
  }
  &-item--details-interestBox {
    display: flex;
    align-items: center;
    // flex: 1;
    margin-top: -7px;
    margin-left: -20px;
  }

  .stacks_pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .MuiOutlinedInput-input {
      padding: 2px 5px !important;
      padding-right: 32px !important;
    }

    .MuiSelect-nativeInput {
      padding: 0px !important;
    }
  }

  &-row {
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // margin-bottom: 10px;
    // min-height: 150px;
    width: 100%;

    &--item {
      display: grid;
      // grid-template-columns: 80px 1fr 320px;
      grid-template-columns: 80px 1fr 150px;
      gap: 10px;

      ._spTechstackGrid-item--col {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        ._spTechstackGrid-item--circle {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: #026173;
          margin-bottom: 10px;
          // padding: 17px;

          img {
            width: 100%;
            height: 100%;
            // max-width: 100%;
            // max-height: 100%;
            object-fit: contain;
          }
        }

        ._spTechstackGrid-item--text {
          background-color: black;
          color: white;
          padding: 0px 10px;
          height: 22px;
          font-size: 14px;
        }
      }

      // ._spTechstackGrid-item--desc {
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: space-between;
      // }
      ._spTechstackGrid-item--desc-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__infoText {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
        ._spTechstackGrid-item--desc {
          ._spTruncatedText--more {
            color: #6449ff;
            font-weight: 500;
          }
          p {
            font-size: 14px;
            font-weight: 400;
          }
        }
        ._spTechstackGrid-item--title {
          display: flex;
          align-items: center;
          gap: 10px;
          ._spTechstackGrid-item--main-lang {
            font-weight: bold;
            font-size: 16px;
          }

          ._spTechstackGrid-item--lang {
            font-size: 12px;
          }
          ._spTechstackGrid-item--interest {
            align-items: center;
            .interest--text {
              font-size: 12px;
              font-weight: 500;
            }

            .MuiButtonBase-root {
              &.MuiSwitch-switchBase.Mui-checked {
                color: #6449ff !important;

                + .MuiSwitch-track {
                  background-color: rgba(#6449ff, 0.8) !important;
                }
              }
            }
          }
        }

        ._spTechstackGrid-item--details {
          display: flex;
          flex-direction: row;
          // justify-content: space-between;
          align-items: center;
          max-width: 1021px;
          gap: 30px;
          font-weight: bold;

          &-labels,
          &-experience,
          &-projects,
          &-interest {
            display: flex;
            gap: 20px;
            flex-direction: row;
            align-items: center;
            align-self: center;
            width: 100%;
            max-width: 200px;
            height: 10px;

            label {
              font-size: 14px;
              // width: 100%;
            }
          }

          &-skills {
            display: flex;
            display: flex;
            gap: 10px;
            flex-direction: row;
            align-items: center;
            align-self: center;
            label {
              font-size: 14px;
              // width: 100%;
            }

            .MuiRating-root {
              label {
                font-size: 20px !important;
                color: #6449ff !important;
              }
            }
          }

          &-data {
            flex: 1;
            min-width: 110px;
            // margin-top: 2px;
            display: flex;
            align-items: center;
          }
          .MuiSlider-thumb {
            color: #6449ff !important;
          }
          .MuiSlider-rail {
            background-color: #6449ff !important;
          }
          .MuiSlider-track {
            border: 1px solid #6449ff !important;
            background-color: #6449ff !important;
          }
          .MuiSlider-root {
            width: 90% !important;
          }
        }
      }

      ._spTechstackGrid-item--gridcards {
        display: flex;
        flex-direction: row;
        column-gap: 10px;

        ._spTechstackGrid-item--gridcard-card {
          position: relative;
          background: #f5f5f5;
          padding: 4px 8px;
          border-radius: 2px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 5px;
          height: 100%;

          p {
            font-size: 12px;
            font-weight: 400;
          }
          ._spTechstackGrid-item--gridcard-assessments {
            display: flex;
            align-items: center;
            gap: 10px;
            background-color: #fa5e2f;
            padding: 4px 8px;
            position: relative;

            p {
              color: #fff;
              font-size: 14px;
              line-height: 15px;
            }

            &_icon {
              color: #fff;
              svg {
                font-size: 23px;
              }
            }
            &_single {
              position: absolute;
              top: 70px;
              max-height: 270px;
              overflow: auto;
              /* right: 37px; */
              z-index: 100;
              background-color: #fff;
              width: 134px;
              padding: 5px;

              .assessments {
                display: flex;
                gap: 3px;
                flex-direction: column;
                // position: absolute;
                // justify-content: center;
                // align-items: center;
                // width: 130px;

                p {
                  padding: 3px 7px;
                  font-size: 12px;
                  background-color: #ffad94;
                  text-align: center;
                  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                }
              }
            }
          }

          ._spTechstackGrid-item--gridcard-bigtext {
            font-size: 32px;
            font-weight: 400;
          }

          ._spTechstackGrid-item--gridcard-info,
          ._spTechstackGrid-item--gridcard-opportunities {
            // position: absolute;
            // top: 70px;
            display: flex;
            flex-direction: row;
            // justify-content: center;
            align-items: center;
            column-gap: 5px;
            width: 100%;
            justify-content: space-between;
          }

          ._spTechstackGrid-item--gridcard-info {
            &-icon {
              margin: 0px;
              color: rgb(64, 64, 64);
              font-size: 8px;
            }
            p {
              font-size: 8.5px;
            }
          }

          ._spTechstackGrid-item--gridcard-opportunities {
            color: rgb(165, 77, 247);

            ._spTechstackGrid-item--gridcard-icon {
              margin: 0px;
              color: rgb(64, 64, 64);
            }
          }
        }

        &-assedTitle,
        &-assedFooter {
          font-size: 14px;
          display: flex;
          justify-content: space-between;
        }

        &-assedFooter {
          justify-content: space-between;
          align-items: center;

          &__retakeBtn {
            font-size: 14px;
            display: flex;
            text-decoration: underline;
            text-decoration-color: rgb(64, 64, 64);
          }
        }

        &-assedScore {
          font-size: 30px;
          font-weight: 500;
        }
      }
    }
    ._spTechstackGrid-row-deleteicon {
      ._spTechstackGrid-row-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 999;
        color: black;
        padding: 20px;
        box-sizing: border-box;

        p {
          color: #fff;
        }

        ._spTechstackGrid-row--container {
          display: flex;
          gap: 10px;
          margin-top: 10px;

          button {
            width: 120px !important;
            height: 32px !important;
            padding: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          ._spTechstackGrid-row-btndelete {
            background-color: coral;
            color: #fff;
          }

          ._spTechstackGrid-row-btncancel {
            background-color: transparent;
            border: 1px solid #fff;
            color: #fff;
          }
        }
      }
    }

    ._spTechstackGrid-row-close-icon {
      position: absolute;
      top: 1px;
      right: 1px;
      color: rgb(251, 126, 126);
      display: none;
      background-color: #f4ebeb;
      padding: 2px 4px;
      font-size: 12px;

      svg {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}

._spTechSkills_wrap {
  margin-top: 8rem;

  h4 {
    font-weight: bold;
  }

  ._spTechskillsSection {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .stacks_pagination {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    // margin-top: 50px;

    &--head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #cdcdcd;
      padding-bottom: 1rem;

      h4 {
        font-size: 1.5rem;
        font-weight: 500;
        color: #000;

        @media (max-width: 768px) {
          font-size: 1.2rem;
        }

        @media (max-width: 576px) {
          font-size: 1rem;
        }
      }

      button {
        margin: 0;
        border: 1px solid #000;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        background-color: transparent;
        color: #000;
        font-size: 1rem;
        font-weight: 500;

        @media (max-width: 768px) {
          padding: 0.5rem;
          font-size: 14px;

          svg {
            font-size: 1rem;
          }
        }

        @media (max-width: 576px) {
          padding: 0.5rem;
          font-size: 12px;
        }
      }
    }

    ._spTechskills__container-body {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 1rem 0;
      flex: 1;
      gap: 1rem;

      ._spTechskills-chip-available {
        padding: 1rem 1.6rem;
        background-color: #696767bd !important;
        cursor: not-allowed !important;
      }

      ._spTechskills-chip {
        display: flex;
        align-items: stretch;
        background-color: #fff;
        max-width: fit-content;
        // height: 30px;
        cursor: pointer;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        position: relative;
        border-radius: 0px;
        padding: 0 !important;

        &:hover {
          .addInfo {
            opacity: 1;
            display: flex;
          }
        }

        .addInfo {
          background-color: #000;
          display: none;
          padding: 0px 10px;
          // height: 100%;
          // display: flex;
          align-items: center;
          color: white;
          opacity: 0;
          transition: opacity 0.5s;
        }

        // &:disabled {
        //   cursor: not-allowed;
        //   opacity: 0.5;
        // }

        ._spTechskills-chip__text {
          padding: 0px 10px;
          height: 35px;
          flex: 1;
          max-width: fit-content;
          min-width: fit-content;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          // height: 100%;
          flex: 1;
          // padding: 12px;

          img {
            width: 20px;
            height: 20px;

            @media (max-width: 768px) {
              width: 15px;
              height: 15px;
            }

            @media (max-width: 576px) {
              width: 10px;
              height: 10px;
            }
          }
          span {
            font-size: 14px;
            font-weight: 500;

            @media (max-width: 768px) {
              font-size: 12px;
            }

            // @media (max-width: 576px) {
            //   font-size: 10px;
            // }
          }

          .techskill_logo {
            width: 20px;
            height: 20px;

            @media (max-width: 500px) {
              width: 15px;
              height: 15px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .techskill_name {
            font-size: 14px;
            font-weight: 600;

            @media (max-width: 500px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

._spTechStacks--newStack {
  background-color: #f8f8f8;
  padding: 20px 10px;
  margin-top: 50px;

  &-title {
    margin-bottom: 20px;

    h6 {
      font-size: 16px;
    }
  }
  &-container {
    display: flex;
    column-gap: 5px;
    width: 100%;

    ._spTechStacks--newStack-name,
    ._spTechStacks--newStack-url {
      width: 40% !important;
    }

    button {
      width: 20%;
      background-color: #000;
      color: #fff;
      font-weight: 500;
    }
  }
}

.no-tech-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #d9d9d9, $alpha: 0.2);
  padding: 30px 12px;
  margin-top: 20px;

  &_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
