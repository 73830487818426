@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(8.5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.successPopup {
  width: 400px;
  border-radius: 4px;
  position: fixed;
  bottom: 25px;
  left: 25px;
  background-color: #2d3436;
  padding: 22.5px 15px;
  border-radius: 5px;
  animation: fadeInUp;
  animation-fill-mode: both;
  animation-duration: 0.225s;
  animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  z-index: 1;
}
.successPopup .flex {
  display: flex;
}
.disabled {
  /* transform: translateY(8.5px);
  opacity: 0; */
}
.successPopup p {
  margin-left: 10px;
  color: white;
  font-size: 14.5px;
  width: 265px;
}
.successPopup span {
  font-weight: 600;
  color: #2e86de;
}
.successPopup .linkDiv {
  display: flex;
  margin: 10px 0;
  margin-left: 27.5px;
}
.successPopup .linkDiv p {
  margin-left: 6.5px;
}
