._optionsInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  h4 {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    color: "#6c757d";
  }
}
