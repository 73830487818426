._spStepperElement {
  input:not([type="checkbox"]):not([type="radio"]),
  textarea,
  select {
    border: initial;
    font-size: 14px;
    padding: 5px !important;
  }
  .MuiPickersDay-root {
    font-size: 10px !important;
  }
  label {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  &__left {
    max-width: 120px;
    &--dateRange {
      display: flex;
      gap: 15px;
      flex-direction: column;

      &-single {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        &_top {
          display: flex;
          gap: 5px;
          align-items: center;
        }
      }
    }
  }

  &__right {
    &_edits {
      display: flex;
      flex-direction: column;
      gap: 15px;
      &--single {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 1;

        input,
        textarea,
        select {
          border: 1px solid #484848 !important;
          padding: 5px !important;
          border-radius: 2px;
        }

        ._bulletEdit {
          position: relative;

          &--del {
            position: absolute;
            top: -10px;
            right: -10px;
            color: red;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        ._bulletAdd {
          display: flex;
          justify-content: flex-end;

          button {
            text-decoration: underline;
            font-weight: 500;
          }
        }
      }

      &--row {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 100%;
      }

      &--uploadAction {
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          padding: 5px 10px;
          border-radius: 2px;
          border: 1px solid #6349ff;
          font-size: 12px;
          font-weight: 500;
          color: #6349ff;
          transition: all 0.3s;

          &:hover {
            background-color: #6349ff;
            color: white;
          }
        }
      }

      &--images {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        align-items: center;

        ._image {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 100px;
          border: 1px solid #484848;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          button {
            position: absolute;
            top: 5px;
            right: 5px;
            border: none;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
    }
    ._editActions {
      margin-top: 15px;
      gap: 10px;
      justify-content: space-between;
      max-width: 200px;
      display: flex;

      button {
        padding: 5px 10px;
        width: 70px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
      }

      &_cancel {
        background-color: white;
        border: 1px solid black;
      }
      &_save {
        background: #484848;
        color: white;
      }
    }
    ._editError {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-top: 10px;
      flex-wrap: wrap;
      span {
        color: red;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
