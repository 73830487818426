.imagesPreview {
  &-dialog {
    // background-color: aqua !important;
    max-width: 80vw;
    // position: relative;
    .icon {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
      opacity: 1;
      font-size: 2rem !important;
    }

    &--container {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(5, auto);
      &__imgBox {
        overflow: hidden;
        height: 20rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
