.certification {
  h4 {
    color: $color_prim;
    font-weight: 700;
  }
  .resource-header {
    min-height: 5rem;
    display: flex;
    justify-content: space-between;

    &_right {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      flex-shrink: 3;
      flex-basis: 90%;
      gap: 4rem;
      h4 {
        color: $color_prim;
        font-weight: 700;
      }

      .header_cet__info {
        @include flexContainer("", center, row);
        gap: 4rem;
        flex-wrap: wrap;
        span {
          font-size: 1.2rem;
          color: $black;
          font-weight: 500;
        }
        .info {
          @include flexContainer(space-between, center, row);
          gap: 2rem;
          &_title {
            color: $color_prim;
            font-weight: 600;
          }
        }
      }
    }
    &_left {
      min-width: 35rem;
      display: flex;
      justify-content: flex-end;
      flex-grow: 3;

      .score {
        @include flexContainer("", center, column);
        gap: 1rem;
      }
    }
  }
  &_header {
    .domain {
      margin-top: 5rem;
      h4 {
        color: $color_prim;
        font-weight: 700;
      }
      p {
        font-size: 1.4rem;
        color: $black;
        font-weight: 500;
      }
    }
    .stat_content_data {
      gap: 2rem !important;
      margin-left: 2rem;
      margin-right: 2rem;
      border-bottom: 0;
    }
    .stat_content_rev {
      flex-basis: 50%;
      gap: 2rem;
      p {
        margin-bottom: 0.5rem;
      }
      .stat_content_data-title {
        min-width: 25rem;
        margin-bottom: 0;
        padding: 0;
      }
      .stat_content_data-content {
        flex-basis: 40%;
      }
    }
  }
  .unsubs {
    &-caroussel {
      margin-top: 3rem;
      &_header {
        font-size: 1.8rem;
      }
      &_data {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // gap: 3rem;
        &--content {
          width: 49%;
          &__title {
            margin-top: 2rem;
            font-size: 1.5rem;
            font-weight: 600;
            border-bottom: 1px solid $color_border;
          }
          .logoClient {
            max-height: 7rem;
            // margin-right: 1rem;
          }
          .BrainhubCarousel__container {
            .BrainhubCarousel__arrows {
              background-color: transparent;
              font-size: 1.2rem;
              span {
                border-color: $color_prim;
                // font-size: 1.2rem;
                padding: 3px;
              }
            }
          }
        }
      }

      .hidCa {
        display: none;
        visibility: hidden;
      }
    }
  }
}
