.blur {
  &_text {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
  &_star {
    filter: blur(2px);
    -webkit-filter: blur(2px);
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
}
