@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(7.5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.requirementsSteps {
  /* margin: 65px 0; */
  width: 100%;
  /* margin: 0px 150px; */
  margin-bottom: 50px;
}
.posOptions {
  position: relative;
}
.posOptions .options {
  position: absolute;
  top: -4.5px;
  right: -192px;
  box-shadow: 0 2.8px 2.2px rgba(77, 69, 69, 0.018),
    0 6.7px 5.3px rgba(0, 0, 0, 0.024), 0 12.5px 10px rgba(0, 0, 0, 0.03),
    0 22.3px 17.9px rgba(0, 0, 0, 0.036), 0 41.8px 33.4px rgba(0, 0, 0, 0.04),
    0 100px 80px rgba(0, 0, 0, 0.006);
  border: 2.15px solid rgba(71, 31, 31, 0.075);
  background-color: #2d3436;
  border-radius: 2.5px;
  padding: 3.5px 10px;
  padding-right: 35px;
  min-width: 185px;
  z-index: 1;
  opacity: 1;
}
.posOptions .options p {
  font-weight: 600;
  font-size: 13.5px;
  cursor: pointer;
  text-align: left;
  color: white;
  margin: 3.5px 0;
}
.posOptions .options p:hover {
  opacity: 1;
}
.posOptions .posSubOptions {
  position: absolute;
  top: 100%;
  right: 100%;
  background-color: #2d3436;
  z-index: 1;
  width: 275px;
  box-shadow: 0 2.8px 2.2px rgba(77, 69, 69, 0.018),
    0 6.7px 5.3px rgba(0, 0, 0, 0.024), 0 12.5px 10px rgba(0, 0, 0, 0.03),
    0 22.3px 17.9px rgba(0, 0, 0, 0.036), 0 41.8px 33.4px rgba(0, 0, 0, 0.04),
    0 100px 80px rgba(0, 0, 0, 0.006);
  border: 2.15px solid rgba(71, 31, 31, 0.075);
  border-radius: 3.5px;
}
.posOptions .posSubOptions p {
  font-size: 13.5px;
  font-weight: 500;
  padding: 5px 15px;
  color: white;
}
.posOptions .posSubOptions li {
  font-size: 13.5px;
  font-weight: 500;
  padding: 5px 15px;
  color: white;
  cursor: pointer;
}
.posOptions .posSubOptions .subOptionTop {
  padding: 15px;
  display: flex;
  align-items: center;
}
.posOptions .posSubOptions .subOptionTop h4 {
  color: white;
  margin: 0;
  margin-left: 5px;
  opacity: 0.5;
}
.posOptions .posSubOptions input,
.posOptions .posSubOptions textarea {
  padding: 7.5px 12.5px;
  background-color: rgba(128, 128, 128, 0.5);
  border: none;
  border-radius: 2.5px;
  margin: 7.5px 15px;
  margin-bottom: 25px;
  font-size: 13.5px;
  font-weight: 400;
  color: white;
  opacity: 0.65;
}
.posOptions .posSubOptions textarea {
  width: 235px;
  min-height: 100px;
}
.posOptions .posSubOptions input::placeholder {
  color: white;
}
.posOptions .posSubOptions li:last-child {
  margin-bottom: 25px;
}
.posOptions .posSubOptions hr {
  opacity: 0.125;
}
.posOptions .posSubOptions .option {
  cursor: pointer;
}
.posOptions .posSubOptions .option:hover {
  background-color: rgba(128, 128, 128, 0.085);
}
.posOptions .posSubOptions.full {
  position: absolute;
  left: 100%;
  top: -3.5px;
}
.posOptions .posSubOptions.full button {
  background-color: #2e86de;
  border-radius: 1.5px;
  padding: 2.5px 10px;
  margin: 10px 15.5px 15px 15.5px;
}
.posOptions .posSubOptions.full input {
  margin-bottom: 10px;
}
.requirementCard.clientData {
  width: 550px !important;
}
.requirementCard.clientData .avatar {
  height: 60px;
  width: 60px;
  background-color: #2d3436;
  border-radius: 50%;
  margin-right: 10.5px;
}
.requirementCard.clientData p {
  font-size: 14.5px;
  font-weight: 500;
}
.requirementsSteps .stepID {
  margin-right: 7.5px;
  height: 23.5px;
  width: 23.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5px;
  opacity: 0.75;
  border: 2px solid #2d3436;
}
.requirementsSteps .stepID h5 {
  color: #2d3436;
  font-weight: 700;
  font-size: 15px;
}
#date {
  margin-bottom: -10px;
}
.requirementsSteps .requirementCard {
  width: 100%;
  height: 305px;
  background-color: white;
  border-radius: 3.5px;
  margin-bottom: 5px;
  border: 2.15px solid rgba(71, 31, 31, 0.075);
  position: relative;
  padding: 20px;
  margin-right: 10px;
}
.requirementsSteps .requirementCard:last-child {
  margin-right: 0;
}
.requirementsSteps #priceCard {
  width: 365px;
  padding: 10px 12.5px;
  height: 355px;
}
#priceCard .optionBar {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 3.5px;
  padding: 7.5px;
}
.titleDesc p {
  font-size: 12px;
  letter-spacing: -0.15px;
  margin-top: 3.5px;
  font-weight: 500;
  opacity: 0.75;
}
#priceCard input,
#priceCard textarea {
  height: 35px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 3.5px;
  padding: 3.5px 12.5px;
  font-size: 14px;
  border: 2.15px solid rgba(71, 31, 31, 0.075);
  font-weight: 500;
}
#priceCard textarea {
  margin-bottom: 0;
  height: 100px;
  padding-left: 4.5px;
}
#priceCard .optionInputCtr {
  display: flex;
  align-items: center;
}
#priceCard .optionInputCtr .approve {
  background-color: #2d3436;
  height: 35px;
  width: 42.5px;
  margin-left: -10px;
  border-radius: 0 3.5px 3.5px 0;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.requirementsSteps .requirementCard.one .optionBar {
  background: #f8f8f8; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f8f8f8,
    #ece9e6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f8f8f8,
    #ece9e6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.requirementsSteps .requirementCard.two .optionBar {
  background: #2e86ded0;
  background: -webkit-linear-gradient(to right, #514a9dc9, #2e86ded0);
  background: linear-gradient(to right, #514a9dc9, #2e86ded0);
}
.requirementsSteps .requirementCard.three .optionBar {
  background: #ff5f6cd0;
  background: -webkit-linear-gradient(to right, #ff5f6cd0, #ffc471cb);
  background: linear-gradient(to right, #ff5f6cd0, #ffc471cb);
}
.newReq {
  margin-left: 12.5px;
  color: #2e86de;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  letter-spacing: -0.15px;
}
.priceCardCtr {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  padding-right: 50px;
  /* overflow-x: auto; */
}
.priceCardCtr .requirementCard {
  margin-right: 10px;
}
.requirementsSteps .requirementCard .confirm {
  border-radius: 3px;
  padding: 2px 25px;
  width: 210px;
  font-size: 11.5px;
  font-weight: 600;
  background-color: #1e272edc;
}
.requirementsSteps .requirementCard .confirm.confirmed {
  background-color: #27ae60;
}
.requirementsSteps h4 {
  font-weight: 600;
  font-size: 14.5px;
  margin-bottom: 6.5px;
  white-space: nowrap;
}
#sb h4 {
  width: 135px !important;
  white-space: unset;
}
.requirementsSteps .requirementCard .dateStackCtr {
  display: flex;
  justify-content: space-between;
}
.requirementsSteps .requirementCard .dateDiv {
  display: flex;
  margin-bottom: 25px;
}
#dateRangeZIndex {
  z-index: 2;
}
.requirementsSteps .requirementCard .dateDiv .dateItem {
  width: 237.5px !important;
}
.editCtr {
  display: flex;
}
#edit {
  border: 1px solid #2d3436;
  color: #2d3436;
  font-size: 9.5px;
  font-weight: 600;
  background: transparent;
  border-radius: 1.25px;
  padding: 0;
  margin: 0;
  height: 14px;
  padding: 0 5px;
  margin-left: 7.5px;
  letter-spacing: 0;
  position: absolute;
  right: 26.5px;
}
#delete {
  border: 1px solid red;
  color: red;
  font-size: 9.5px;
  font-weight: 600;
  background: transparent;
  border-radius: 1.25px;
  padding: 0;
  margin: 0;
  height: 14px;
  padding: 0 5px;
  margin-left: 7.5px;
  letter-spacing: 0;
  position: absolute;
  right: 75px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem.criteria {
  width: fit-content !important;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .experienceCtr {
  width: 315px;
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: flex-start;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr {
  display: flex;
  align-items: center;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr.team {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #2d3436;
  font-size: 12px;
  padding: 4px 7.5px;
  width: 360.5px;
  height: 23.5px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr.team #upTo {
  border: 1px solid white;
  color: white;
  background-color: rgba(255, 255, 255, 0.125);
  font-size: 11px;
  font-weight: 600;
  padding: 1px 5px;
  border-radius: 1.5px;
  letter-spacing: 0.25px;
  opacity: 0.85;
  margin-left: 8.5px;
  position: absolute;
  right: 36.5px;
  transform: translateY(-1px);
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr.team h4,
#note {
  color: white;
  font-weight: 500;
  letter-spacing: 0;
  font-size: 12.5px;
  margin: 0;
}
.noteCtr {
  width: 360.5px;
  padding: 8.5px 7.5px 2.5px 7.5px;
  margin-top: -0.5px;
}
#note {
  font-style: italic;
  font-size: 11.5px;
  letter-spacing: 0;
  width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
  color: #2d3436;
  opacity: 0.55;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr h4 {
  white-space: nowrap;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr .helpCtr {
  position: relative;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .qaCtr
  .helpCtr
  .helpContent {
  position: absolute;
  top: 110%;
  left: 10px;
  padding: 12.5px;
  padding-right: 20px;
  background-color: #2d3436;
  border-radius: 3.5px;
  box-shadow: 0 2.8px 2.2px rgba(77, 69, 69, 0.009),
    0 6.7px 5.3px rgba(0, 0, 0, 0.012), 0 12.5px 10px rgba(0, 0, 0, 0.015),
    0 22.3px 17.9px rgba(0, 0, 0, 0.018), 0 41.8px 33.4px rgba(0, 0, 0, 0.02),
    0 100px 80px rgba(0, 0, 0, 0.003);
  width: 300px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.175s;
  z-index: 1;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .qaCtr
  .helpCtr:hover
  .helpContent {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .qaCtr
  .helpCtr
  .helpContent
  p {
  font-weight: 500;
  font-size: 14px;
  color: white;
}
#thumbHvr:hover {
  opacity: 1 !important;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .qaCtr .icon {
  fill: rgba(128, 128, 128, 0.5);
  margin-left: 7.5px;
  margin-top: -3.5px;
  cursor: pointer;
  font-size: 18px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem h4 {
  color: #459df5;
  font-size: 13.5px;
  margin: 0;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem {
  background-color: #459df517;
  padding: 1.5px;
  padding-left: 10px;
  border-radius: 2.5px;
  width: fit-content;
  width: 135px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .editPrice {
  background-color: white;
  padding: 1.5px;
  padding-left: 10px;
  border-radius: 2.5px;
  width: 135px;
  height: 19px;
  border: 2.15px solid rgba(71, 31, 31, 0.15);
}
.requirementsSteps .requirementCard .dateDiv .dateItem .editCtr {
  display: flex;
  position: relative;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .editCtr .errorMessage {
  position: absolute;
  top: 125%;
  left: 0px;
  padding: 12.5px;
  padding-right: 20px;
  background-color: #2d3436;
  border-radius: 3.5px;
  box-shadow: 0 2.8px 2.2px rgba(77, 69, 69, 0.009),
    0 6.7px 5.3px rgba(0, 0, 0, 0.012), 0 12.5px 10px rgba(0, 0, 0, 0.015),
    0 22.3px 17.9px rgba(0, 0, 0, 0.018), 0 41.8px 33.4px rgba(0, 0, 0, 0.02),
    0 100px 80px rgba(0, 0, 0, 0.003);
  width: 300px;
  /* visibility: hidden;
  opacity: 0; */
  transition: all 0.175s;
  z-index: 1;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .editCtr input {
  background-color: white;
  padding: 1.5px;
  padding-left: 5px;
  border-radius: 2.5px;
  width: 135px;
  height: 19px;
  border: 2.15px solid rgba(71, 31, 31, 0.15);
  font-size: 13px;
  font-weight: 600;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .editCtr
  .dropdownOptions {
  position: absolute;
  top: 125%;
  left: 0;
  width: 215px;
  background-color: white;
  border-radius: 3.5px;
  box-shadow: 0 2.8px 2.2px rgba(77, 69, 69, 0.009),
    0 6.7px 5.3px rgba(0, 0, 0, 0.012), 0 12.5px 10px rgba(0, 0, 0, 0.015),
    0 22.3px 17.9px rgba(0, 0, 0, 0.018), 0 41.8px 33.4px rgba(0, 0, 0, 0.02),
    0 100px 80px rgba(0, 0, 0, 0.003);
  z-index: 1;
  border: 2.15px solid rgba(128, 128, 128, 0.15);
  padding: 10px 0;
  visibility: visible;
  /* opacity: 0; */
}
/* .requirementsSteps .requirementCard .editCtr .edit:hover ~ .dropdownOptions,
.requirementsSteps .requirementCard .dropdownOptions:hover {
  top: 110%;
  visibility: visible;
  opacity: 1;
} */
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .editCtr
  .dropdownOptions
  h4 {
  color: grey;
  padding: 3.5px 15px;
  font-size: 13.5px;
  cursor: pointer;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .editCtr
  .dropdownOptions
  h4:hover {
  color: inherit;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .edit {
  background-color: #2d3436;
  height: 18px;
  width: 18px;
  padding: 2.5px;
  fill: white;
  border-radius: 2.5px;
  margin-left: 2.5px;
  opacity: 0.85;
  cursor: pointer;
}
.requirementsSteps
  .requirementCard
  .dateDiv
  .dateItem
  .highlightItem.dropdown
  h4 {
  color: grey;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem.dropdown {
  background-color: rgba(128, 128, 128, 0.125);
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem.price h4 {
  color: #3e9416;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .highlightItem.price {
  background-color: #4bbb1723;
}
.highlightItem.criteria {
  background-color: transparent !important;
  padding: 0 !important;
  display: flex;
  justify-content: flex-end;
}
.highlightItem.criteria img {
  height: 22.5px;
  width: 22.5px;
  margin-left: 12.5px;
}
.dateDiv p {
  font-size: 13.5px;
  margin-left: 5px;
  font-weight: 500;
}
.dateDiv span {
  color: #459df5;
  font-weight: 600;
}
.requirementsSteps .requirementCard .dateDiv .dateItem h2 {
  margin-top: -4px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .experienceItem {
  display: flex;
  align-items: center;
  margin: 0 7.5px 7.5px 0;
  /* width: 450px; */
}
.requirementsSteps .requirementCard .dateDiv .dateItem .experienceItem img {
  height: 18.5px;
  width: 18.5px;
  margin-right: 4px;
}
.requirementsSteps .requirementCard .dateDiv .dateItem .experienceItem h4 {
  margin: 0;
  font-size: 13.5px;
}
#large {
  width: 450px;
}
#flexEnd {
  justify-content: flex-end;
  align-items: flex-end;
  width: fit-content;
}
.dev {
  display: flex;
}
#sb {
  width: 155px;
  cursor: pointer;
}
.devOptionCtr {
  display: flex;
  flex-direction: column;
}
.devOptionCtr button {
  background-color: white !important;
  border: 1.5px solid #2d3436;
  color: #2d3436;
  font-weight: 600;
  padding: 0.85px !important;
}
.emptyDev .dev {
  /* margin: 20px 0 0 0 !important; */
  padding-left: 15px;
  padding-bottom: 20px;
}
.dev:last-child {
  margin-bottom: 0;
}
.devImg {
  height: 46.5px;
  width: 46.5px;
  background-color: #2d343634;
  border-radius: 50%;
  margin-right: 6.5px;
  position: relative;
  overflow: hidden;
  border: 2px solid rgba(128, 128, 128, 0.15);
}
.devWrapper {
  position: relative;
  height: 65px;
}
.devWrapper .statusIcon {
  position: absolute;
  top: 57.5%;
  left: 47.5%;
  transform: translateX(-50%);
  height: 22.5px;
  width: 25.5px;
  background-color: rgba(128, 128, 128, 1);
  border-radius: 2.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.devWrapper .statusIcon .statusText {
  position: absolute;
  top: 110%;
  transition: all 0.135s;
  color: white;
  background-color: #2d3436;
  font-size: 12px;
  opacity: 1;
  z-index: 1;
  border-radius: 1.5px;
  padding: 1.5px 10px;
  border-radius: 1.5px;
  min-width: 90px;
  text-align: center;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  margin: 0;
}
.devWrapper .statusIcon:hover .statusText {
  opacity: 1;
  visibility: visible;
  top: 105%;
}
.devImg.img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.devImg .icon {
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 48.5px;
  fill: white;
  /* opacity: 0.25; */
}
.dev h4 {
  margin-bottom: 0;
}
.dev p {
  font-size: 13.5px;
  opacity: 0.55;
  font-weight: 500;
  margin-bottom: 8.5px;
}
.dev .starCtr {
  display: flex;
  margin-top: -8.5px;
}
#ratePrompt {
  font-weight: 500;
  padding: 1px 15px;
  background-color: #2d3436;
  color: white;
  cursor: pointer;
  opacity: 0.85;
  border-radius: 1.5px;
  font-size: 12px;
  margin-right: -25px;
}
.dev .starCtr .star {
  fill: #459df5;
}
.requirementCard.devs {
  width: 365px;
  height: fit-content;
  padding: 0;
}
.requirementCard.devs.confirmed {
  margin-right: 15px;
  padding-top: 11.5px;
}
.confirmAndBookCtr {
  display: flex;
  margin-bottom: 25px;
}
.confirmInfoCtr {
  display: flex;
  margin-bottom: 32.5px;
}
.confirmInfoCtr p {
  font-size: 13.5px;
  font-weight: 500;
  opacity: 0.55;
  font-weight: 500;
}
.confirmAndBook {
  /* background-color: #2d3436; */
  background-color: white;
  border: 2.15px solid rgba(71, 31, 31, 0.075);
  height: 100%;
  width: 100%;
  border-radius: 3.5px;
  position: relative;
}
.confirmAndBook .finalNextPriceCtr {
  position: absolute;
  bottom: 15px;
  left: 20px;
}
.confirmAndBook .subPriceCtr {
  position: absolute;
  bottom: 15px;
  right: 20px;
}
.confirmAndBook .subPriceCtr .subPrice {
  display: flex;
  align-items: center;
  margin-top: 3.5px;
  justify-content: flex-end;
}
.confirmAndBook .subPriceCtr h2 {
  font-size: 16.5px !important;
  opacity: 0.5;
}
.confirmAndBook .confirmAndBookTop {
  background-color: #2d3436;
  padding: 25px;
  border-radius: 3.5px 3.5px 0 0;
  position: relative;
}
.confirmAndBook .confirmAndBookTop .priceChip {
  background-color: #459df5;
  position: absolute;
  top: 13.5px;
  right: 13.5px;
  padding: 1.5px 15px;
  border-radius: 2.5px;
}
.confirmAndBook .confirmAndBookTop .priceChip h4 {
  margin: 0;
  font-size: 13.5px;
  font-weight: 500;
}
.confirmAndBook .confirmAndBookTop h2,
.confirmAndBook .confirmAndBookTop .priceChip h4 {
  color: white;
}
.confirmAndBook .confirmAndBookTop p {
  opacity: 0.75;
  color: white;
  font-size: 14.5px;
}
.confirmAndBook .confirmAndBookBottom {
  padding: 25px;
}
.confirmAndBook .confirmAndBookBottom .note {
  width: 550px;
  text-align: left;
  font-size: 14.5px;
  opacity: 0.55;
  font-weight: 500;
  margin-left: 35px;
}
.confirmAndBook .confirmAndBookBottom .checkCtr {
  margin-bottom: 17.5px;
  display: flex;
  align-items: center;
}
.confirmAndBook .confirmAndBookBottom .finalPriceCtr {
  display: flex;
  align-items: center;
}
.confirmAndBook .confirmAndBookBottom .finalPriceCtr p,
.confirmAndBook .confirmAndBookBottom .subPriceCtr p,
.confirmAndBook .confirmAndBookBottom .due {
  font-size: 14.5px;
  font-weight: 500;
  opacity: 0.55;
}
.confirmAndBook .confirmAndBookBottom .due {
  margin-left: 3.5px;
}
.confirmAndBook .confirmAndBookBottom .finalPriceCtr h2 {
  margin: 0 5.5px;
  font-size: 35px;
}
.confirmAndBook .confirmAndBookBottom .checkCtr h4 {
  margin: 0;
  font-weight: 500;
}
.confirmAndBook .confirmAndBookBottom button {
  border-radius: 3.5px;
  font-size: 14.5px;
  background-color: #459df5;
}
.confirmAndBook .confirmAndBookBottom .checkCtr span {
  opacity: 0.55;
}
.confirmAndBook .confirmAndBookBottom hr {
  margin: 25px 0;
  border: none;
  height: 1.5px;
  background-color: rgba(71, 31, 31, 0.075);
}
.dev .thumb {
  font-size: 25px;
  margin-left: 10px;
  opacity: 0.25;
}

.resourceCtr {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  padding-right: 50px;
  /* overflow-x: auto; */
}
.resourceCtr .infoBox {
  width: 400px;
  height: fit-content;
  border-radius: 3.5px;
  background-color: rgba(128, 128, 128, 0.075);
  margin-left: 30px;
  padding: 15px;
}
.resourceCtr .infoBox span {
  font-weight: 600;
}
.scoreChip {
  position: absolute;
  bottom: 15px;
  left: 15px;
  border: 1px solid #2d3436;
  padding: 1.5px 15px;
  border-radius: 2.5px;
}
.scoreChip h4 {
  color: #2d3436;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}
.infoBox .infoBoxContent {
  display: flex;
}
.infoBox .infoBoxContent p {
  font-size: 14px;
  margin-left: 15px;
  font-weight: 500;
  opacity: 0.65;
}
.infoBox .infoBoxContent span {
  font-weight: 700;
  opacity: 1;
  color: black;
}
.infoBox .infoBoxContent ul {
  padding-left: 30px;
  margin-top: 15px;
}
.infoBox .infoBoxContent li {
  margin-bottom: 5.5px;
}
.infoBox .infoBoxContent li p {
  margin-left: 0;
}
.confirmationCtr {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.confirmationCtr .confirmationCtrContent {
  width: 550px;
  background-color: rgb(248, 248, 248);
  border-radius: 5px;
  animation: fadeInUp;
  animation-fill-mode: both;
  animation-duration: 0.325s;
  animation-delay: 0.1s;
  animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}
.confirmationCtr .confirmationCtrContent .top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: white;
  border-radius: 5px 5px 5px 5px;
}
.confirmationCtr .confirmationCtrContent .top .warning {
  fill: #e74c3c;
  font-size: 32.5px;
  margin: 7.5px 0;
}
.confirmationCtr .confirmationCtrContent .top h2 {
  font-weight: 600;
  color: #e74c3c;
  font-size: 20.5px;
}
.confirmationCtr .confirmationCtrContent .bottom {
  padding: 35px;
}
.confirmationCtr .confirmationCtrContent .bottom h4 {
  font-size: 14.5px;
}
.confirmationCtr .confirmationCtrContent .bottom ul {
  padding-left: 35px;
  margin: 15px 0;
}
.confirmationCtr .confirmationCtrContent .bottom ul li {
  font-size: 15px;
  font-weight: 500;
  opacity: 0.75;
  margin: 5px 0;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin-top: 20px;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr button {
  padding: 3.5px 7.5px;
  border-radius: 3.5px;
  width: 125px;
  margin: 0 7.5px;
  font-weight: 500;
  font-size: 11.5px;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr #yes {
  background-color: #2d3436;
  opacity: 0.85;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr #no {
  background-color: transparent;
  border: 2px solid grey;
  color: grey;
  opacity: 0.425;
}
.confirmationCtr .confirmationCtrContent .bottom .btnCtr #delete {
  background-color: #dc2430;
  opacity: 0.75;
  color: white;
}
#rate {
  margin-bottom: 10px !important;
}
.confirmationCtr #rate .top {
  align-items: flex-start;
  padding: 25px;
  height: fit-content;
}
.confirmationCtr #rate .top .active {
  border-radius: 3.5px;
  width: 65px;
  height: 6px;
  background-color: #2d3436;
}
.confirmationCtr #rate .top .activityCtr {
  display: flex;
  align-items: center;
}
.confirmationCtr .tagCtr {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  position: relative;
}
.confirmationCtr .tagCtr .tagCtrOptions {
  position: absolute;
  top: 142.5%;
  left: 0;
  width: 60%;
  background-color: #2d3436;
  border-radius: 3.5px;
  z-index: 1 !important;
}
.confirmationCtr .tagCtr .tagCtrOptions hr {
  height: 1px;
  width: 100%;
  background-color: rgba(128, 128, 128, 0.35);
  border: none;
}
.confirmationCtr .tagCtr .tagCtrOptions .option {
  padding: 11.5px 0;
  cursor: pointer;
}
.confirmationCtr .tagCtr .tagCtrOptions .option .close {
  position: absolute;
  right: 10px;
  fill: white;
  opacity: 0.35;
}
.confirmationCtr .tagCtr .tagCtrOptions .option .check {
  position: absolute;
  left: 10px;
  fill: white;
  opacity: 0.5;
}
.confirmationCtr .tagCtr .tagCtrOptions .option p {
  font-size: 11.5px;
  color: rgba(255, 255, 255, 0.885);
  letter-spacing: -0.15px;
}
.confirmationCtr .tagCtr .tagCtrOptions .option:hover {
  background-color: #353e41;
}
.confirmationCtr .tagItems {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 6.5px;
}
.confirmationCtr .tagCtr h5,
.confirmationCtr .tagItems h5 {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: -0.25px;
  color: #2d343683;
  margin-right: 7.5px;
}
.confirmationCtr .tagItems h5 {
  font-size: 11.5px;
  font-weight: 500;
}
.confirmationCtr #rate .top .activityCtr .thumb {
  fill: #2d343683;
  border: 1.85px solid rgba(128, 128, 128, 0.35);
  border-radius: 5px;
  font-size: 31.5px;
  padding: 5px;
  margin-right: 5px;
  cursor: pointer;
  transition: all 0.15s;
}
.confirmationCtr #rate .top .activityCtr .checkC {
  font-size: 22.5px;
  fill: #27ae60;
}
.confirmationCtr #rate .top .activityCtr .checkE {
  font-size: 35px;
  fill: #e74c3c;
  margin-right: 5px;
}
.confirmationCtr #rate .top .activityCtr .undl {
  text-decoration: underline;
  cursor: pointer;
}
.confirmationCtr #rate .top .activityCtr .thumb.chosen {
  fill: white;
  border: none;
  padding: 8px;
}
.confirmationCtr #rate .top .activityCtr .thumb.up.chosen {
  background-color: #2ecc71;
}
.confirmationCtr #rate .top .activityCtr .thumb.down.chosen {
  background-color: #ee5253;
}
.confirmationCtr #rate .top .inactive {
  border-radius: 3.5px;
  width: 42.5px;
  height: 6px;
  background-color: #2d343621;
}
.confirmationCtr #rate .top h4 {
  font-size: 15.25px;
  margin: 0;
  letter-spacing: -0.5px;
  margin: 12.5px 0;
  font-weight: 500;
}
.confirmationCtr #rate .top textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1.95px solid rgba(128, 128, 128, 0.25);
  width: 100%;
  min-height: 150px;
  font-size: 14.5px;
  font-weight: 500;
}
.confirmationCtr #rate .top button {
  background-color: #2d3436;
  padding: 5px 35px;
  border-radius: 4.5px;
}
.confirmationCtr #rate .top textarea::placeholder {
  font-size: 14.5px;
  opacity: 0.55;
  font-weight: 500;
}
.emptyDev {
  margin-bottom: 0px;
}
.emptyDev .desc {
  font-weight: 500;
  opacity: 0.55;
  font-style: italic;
  font-size: 13px;
  margin-bottom: 15px;
  margin-top: -5px;
}
.emptyDev .noDevP {
  padding: 15px;
  background-color: rgba(128, 128, 128, 0.115);
  margin-top: 7.5px;
  border-radius: 2.5px;
  font-size: 12.5px;
  letter-spacing: -0.15px;
  font-weight: 500;
  margin-bottom: 18.5px;
  opacity: 0.7;
  margin: 0 15px 25px 15px;
}
.emptyDev button {
  background-color: #2d3436;
  border-radius: 2.5px;
  margin-top: 5px;
  font-size: 12.5px;
  padding: 5px 15px;
}
.confirmationCtr .confirmationCtrContent .bottom input {
  background-color: transparent;
  border: 1.5px solid #2d343628;
  border-radius: 1.5px;
  padding: 2.5px 5px;
  font-size: 13.5px;
  font-weight: 500;
  margin: auto;
}
.confirmationCtr .confirmationCtrContent .bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.requirementCard .description {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #2d3436;
  cursor: pointer;
}
.requirementCard .description h6 {
  color: white;
  font-weight: 500;
  font-size: 12.5px;
  padding: 3.5px 15px;
}
.requirementCard .description .descriptionContent {
  position: absolute;
  bottom: 110%;
  right: 0;
  background-color: #2d3436;
  height: 135px;
  width: 265px;
  border-radius: 1.5px;
  padding: 10px;
}
.requirementCard .description .descriptionContent textarea {
  background: transparent;
  padding: 0 !important;
  margin: 0 !important;
  height: fit-content !important;
  border: none;
  width: 100%;
  resize: none;
  color: white;
  height: 90% !important;
}
.smallText {
  font-weight: 600 !important;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  opacity: 0.85;
  font-size: 11px !important;
}
.creditCardCtr {
  display: flex;
  align-items: center;
  margin-top: 8.5px;
}
.creditCardCtr h4 {
  margin: 0 5px;
}
.requirementCard .description .descriptionContent textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.requirementCard .description .descriptionContent .approveCtr {
  position: absolute;
  align-items: center;
  bottom: 2.5px;
  right: 2.5px;
  cursor: pointer;
}
.settingsCtr {
  position: relative;
}
.settingsCtr .settingsContent {
  position: absolute;
  width: 225px;
  padding: 10px 15px;
  border-radius: 2.5px;
  background-color: white;
  top: 105%;
  right: 25%;
  z-index: 1;
  box-shadow: 0 2.8px 2.2px rgba(77, 69, 69, 0.018),
    0 6.7px 5.3px rgba(0, 0, 0, 0.024), 0 12.5px 10px rgba(0, 0, 0, 0.03),
    0 22.3px 17.9px rgba(0, 0, 0, 0.036), 0 41.8px 33.4px rgba(0, 0, 0, 0.04),
    0 100px 80px rgba(0, 0, 0, 0.006);
}
.settingsCtr .settingsContent ul {
  list-style: none;
  text-align: right;
}
.settingsCtr .settingsContent ul li {
  margin: 5px 0;
  font-size: 14.5px;
  font-weight: 500;
  cursor: pointer;
}
.confirmedCtr {
  width: 100%;
  border: 2.15px solid rgba(71, 31, 31, 0.075);
  border-radius: 2.5px;
  border-radius: 1.5px;
  margin: 15px 0 30px 0;
  background-color: white;
  padding: 0 10px;
  display: flex;
  align-items: center;
  padding-right: 100px;
  position: relative;
}
.confirmedCtr p {
  font-weight: 600;
  font-size: 14.25px;
  letter-spacing: -0.35px;
  opacity: 0.95;
  margin: 13.5px 27.5px 13.5px 0;
}
.confirmedCtr #confirmed {
  text-align: right;
  color: white;
  background-color: #34be6d;
  padding: 2px 15px;
  border-radius: 1.5px;
  font-size: 11.5px;
  letter-spacing: 0;
  font-weight: 500;
  margin: 0;
  transform: translateY(-50%);
  margin-right: 10px;
  position: absolute;
  top: 52.5%;
  right: 50px;
}
.confirmedCtr .confirmDropdown {
  position: absolute;
  top: 14%;
  right: 15px;
}
.completedChip {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.completedChip .checkCtr {
  width: 20px;
  border: 1px solid #2e86de;
  min-height: 17.35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.completedChip.pending .checkCtr {
  border: 1px solid rgba(128, 128, 128, 0.625);
}
.completedChip.pending p {
  background-color: rgba(128, 128, 128, 0.625);
}
.completedChip p {
  background-color: #2e86de;
  color: white;
  font-size: 11.5px;
  font-weight: 600;
  padding: 2px 10px;
  letter-spacing: 0;
  min-height: 17.35px;
}
.clientArrowCtr {
  display: flex;
  justify-content: space-between;
}
.rankCtr {
  background-color: #2d3436;
  height: 56.5px;
  margin: 0 -15px 0 10px;
  border-radius: 3.5px;
  padding: 5px 1.5px;
  opacity: 0.95;
}
.rankCtr p {
  color: white;
  opacity: 1;
  text-align: center;
  transform: translateX(-0.5px);
  margin: -5.5px 0 0 0;
  font-weight: 600;
}
