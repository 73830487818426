/**
 * Button
 */

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  bottom: 35px;
  // width:100%;
  cursor: pointer !important;
  transition: all 0.3s;
  .btn {
    border: none;
    color: #fff;
    background-color: #1e96f5;
    font-size: 3rem;
    border-radius: 100%;
    height: 3rem;
    width: 3rem;
    p {
      margin-top: -10.5px;
      font-weight: 500;
    }
  }
  input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.sp-btn {
  color: #1e96f5;

  &_small {
    padding: 0.5rem 1rem;
  }
}

.spbtn {
  padding: 0.5rem;
  min-width: 6rem;
  border: none;
  font-weight: 600;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  background-color: transparent;

  &-sub {
    // border: none;
    // padding: 0.5rem;
    // border-radius: ;
    background-color: $color_prim;
    color: white;
    &:hover {
      background-color: darken($color: $color_prim, $amount: 0.7);
    }
  }
  &-upload {
  }
  &-canc {
    background-color: $danger;
    color: white;
  }
  &-docView {
    color: $color_prim;
    padding: 0;
    &:hover {
      text-decoration: underline;
    }
  }
  &-blackbkg {
    background-color: #000;
    color: #fff;
  }
  &-term {
    color: $color_prim;
    &:hover {
      text-decoration: underline;
    }
  }
}

.add_btn {
  position: relative;
  svg {
    font-size: 2.5rem;
    color: $color_prim;
    transition: color 0.5s;

    &:hover {
      cursor: pointer;
      color: $danger;
    }
  }

  input[type="file"] {
    // font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
}
