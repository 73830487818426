.SpLabelInfo {
  //   background-color: rebeccapurple;
  margin-top: 2rem;
  &-label {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0;
  }
  &-description {
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: 400;
  }
}
