.editInputCtr {
  margin-bottom: 30px;
  margin-top: 10px;
  .inputCtr {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .approve,
  .close {
    height: 18px;
    width: 18px;
    padding: 2.5px;
    fill: white;
    border-radius: 2.5px;
    margin: 0 2px;
    opacity: 0.85;
    cursor: pointer;
  }
  .close {
    background-color: rgb(215, 59, 62);
  }
  .approve {
    background-color: #27ae60;
    margin-left: 10px;
  }
  input,
  textarea {
    margin-left: 150px;
    border: 2.15px solid rgba(71, 31, 31, 0.15);
    padding: 1.5px;
    padding-left: 7.5px;
    border-radius: 2.5px;
    font-weight: 600;
    font-size: 13.5px;
    opacity: 0.85;
    width: 295px;
  }
  textarea {
    padding: 7.5px;
    height: 125px;
  }
}

.editCtr {
  display: flex;
  align-items: center;
  h4 {
    margin-right: 15px;
    font-size: 13.5px;
    font-weight: 600;
  }
  h5 {
    color: #2e86de;
    background-color: #2e86de2f;
    padding: 2px 15px;
    border-radius: 2.5px;
    font-size: 11.5px;
    cursor: pointer;
  }
}
