.d label {
  color: #ffa600 !important;
}

.d textarea {
  resize: none;
  border: 1px solid rgba(128, 128, 128, 0.486) !important;
}

.rating-stars {
  width: 60%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.average {
  display: flex;
  justify-content: center;
  width: 40%;
}

.average-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rating-container {
  display: flex;
}

.rating-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.col {
  /* flex-basis: 40%; */
  padding: 10px;
}
