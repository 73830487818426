._sp-customReq {
    * {
        font-family: "Manrope", sans-serif;
    }
    &-newRequirementForm {
        height: 100%;
        &--popUpBox {
            background-color: white;
            border-radius: 15px 15px 15px 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 2rem;
            height: 100%;
            &_teamLocWrapper {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                align-items: center;
                width: 100%;
                &--local {
                    width: 100%;
                    &_input {
                        width: 100%;
                        @media (max-width: 640px) {
                            flex-direction: column;
                            gap: 1rem;
                            input {
                                width: 100% !important;
                                fieldset {
                                    width: 100% !important;
                                }
                            }
                        }
                    }
                    &_locations {
                        gap: 1rem;
                        flex-wrap: wrap;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}