.ProjBid{
    &_main{
        .resource-header{
            @include flexContainer(space-between, center, row);
            height:15rem;
            gap:2rem;
            &_right{
                @include flexContainer(space-between, '', column);
                height: inherit;
                h4{
                    color: $color_prim;
                    font-weight: 700;
                }
            }
            &_left{
                min-width: 20rem;
                @include flexContainer(space-between, '', row);

                
            }
        }
    }
    &_info{
        .resource-header{
            @include flexContainer(space-between, center, row);
            height:20rem;
            gap:2rem;
            &_right{
                @include flexContainer(space-between, '', column);
                height: inherit;
                .header-info{
                    &_top{
                        h4{
                            font-size: 3rem;
                            color: lighten($color: #000000, $amount: 40)
                        }
                        
                    }
                    &_bottom{
                        // @include flexContainer(space-between, center, row);
                        gap: .5rem;
                        span{
                            font-size: 1.5rem;
                            margin-right: .5rem;
                        }
                        &__title{
                            font-weight: 500;
                            
                        }
                    }
                }
            }
            &_left{
                min-width: 20rem;
                flex-basis: 40%;
                height: inherit;
                @include flexContainer(space-between, flex-end, column);
                align-content: center;
                // background-color: aqua;
                &__btns{
                    @include flexContainer(space-between, center, row);
                    // width: ;
                    gap: 1rem;
                    .btn{
                        text-transform: uppercase;
                        font-size: 1.2rem;
                        width: 10rem;
                        &_archive{
                            background-color: lighten($color: #000000, $amount: 50);
                            color: $white;
                        }
                        &_draft{
                            background-color: rgba($color: green, $alpha: .6);
                        }
                        &_submit{
                            background-color: rgba($color: pink, $alpha: .6);
                        }
                    }

                }
                &__status{
                    @include flexContainer(space-between, center, column);
                }
                
            }
        }
        &__content{
            font-size: 1.8rem;
            .title{
                margin-top: 3rem;
                border-bottom: .2rem solid $color_border;
                h2{
                    font-size: 2rem;
                }
                svg{
                    font-size: 2.5rem;
                }
            }


        }
    }
}