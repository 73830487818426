@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  }

  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
.search {
  width: 550px;
  padding: 35px 50px;
  background-color: white;
  border-radius: 15px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -65px;
  margin-bottom: -35px;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.1);
}
.horizontalSearch.cr {
  left: 0 !important;
}
.search .infoOverflow {
  height: 65px;
  width: 65px;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  border-top-right-radius: 12.5px;
}
.search .infoOverflow .info {
  background-color: rgba(0, 0, 0, 0.055);
  height: 75px;
  width: 75px;
  border-radius: 50px;
  cursor: pointer;
  position: absolute;
  top: -20px;
  right: -20px;
  transition: background-color 0.125s;
}
.search .infoOverflow .info:hover {
  background-color: rgba(0, 0, 0, 0.125);
}
.search .infoContent {
  position: absolute;
  top: 0;
  right: -53.75%;
  width: 300px;
  background-color: white;
  padding: 25px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-style: italic;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  z-index: 100;
}
.search .infoContent.active {
  right: -54.75%;
  opacity: 1;
  visibility: visible;
}
.search .infoOverflow .info h3 {
  font-size: 1.75rem;
  position: absolute;
  top: 57.5%;
  left: 42.5%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 0.25);
}
.search .btnSearch {
  border-radius: 7.5px;
  background-color: #2d3436;
  width: 100%;
  margin: 15px auto;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.2s;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search .btnSearch:hover {
  background-color: #1d2122;
}

.search input {
  width: 100%;
  padding: 10px;
  border: 2px solid #2d34362f;
  border-radius: 10px;
  margin: 15px 0;
  font-size: 1rem;
  font-weight: 500;
}
.search .btnSearch h3 {
  color: white;
  text-align: center;
}
.search p {
  opacity: 0.55;
  font-weight: 500;
  text-align: center;
}
.search .stackDiv {
  display: flex;
  flex-wrap: wrap;
}
.search .stackDiv .hrTitle {
  font-size: 0.85rem;
  margin-top: 15px;
}
.search .stackDiv hr {
  width: 100%;
  border: none;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.search .stackItem h3 {
  color: #2d3436af;
  margin-left: 4.5px;
  font-size: 1rem;
}
.horizontalSearch .selected {
  display: flex;
  align-items: center;
}
.horizontalSearch .selected .check {
  height: 16.5px;
  width: 16.5px;
  background-color: #2ecc71;
  border-radius: 50%;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.horizontalSearch .selected .check h3 {
  color: white;
  font-size: 0.75rem !important;
}
.search .stackItem {
  display: flex;
  align-items: center;
  margin: 5px 5px 5px 0;
  width: fit-content;
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
  padding: 2.5px 10px;
}
.search .stackItem::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.055);
  transition: width 0.2s cubic-bezier(0.55, 0, 0.1, 1);
}
.search .stackItem:hover::after {
  width: 100%;
}
.search .stackItem img {
  height: 20px;
  width: 20px;
}

.search .selectedStackDiv,
.horizontalSearch .selectedStackDiv {
  display: flex;
  flex-wrap: wrap;
}
.horizontalSearch .selectedStackDiv {
  padding-right: 50px;
  margin-top: 11.5px;
}
.inputCtr {
  flex-direction: column;
}
.horizontalSearch .selectedStackDiv .ssi {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #2d343810;
  padding: 1.5px 10px;
  border-radius: 2.5px;
}
.horizontalSearch .selectedStackDiv .ssi .closeBtn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  opacity: 0.5;
}
.horizontalSearch .selectedStackDiv h3 {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgb(112, 112, 112);
}
.horizontalSearch .selectedStackDiv .ssi .removeText {
  position: absolute;
  top: -28.5px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  font-weight: 600;
  padding: 1px 0;
  border: 2px solid #2d3436af;
  background-color: white;
  opacity: 0;
  transition: opacity 0.15s;
  min-width: 140px;
  text-align: center;
}
.horizontalSearch .selectedStackDiv img {
  height: 17.5px;
  width: 17.5px;
  margin-right: 5px;
}
.horizontalSearch .selectedStackDiv {
  width: 70vw;
}
.search .selectedStackDiv .selectedStackItem,
.horizontalSearch .selectedStackDiv .selectedStackItem {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d3436b4;
  border-radius: 3.5px;
  padding: 2.5px 20px 2.5px 30px;
  position: relative;
  overflow: hidden;
  margin-left: 8.5px;
  margin-bottom: 8.5px;
  cursor: pointer;
  z-index: 1;
}
.horizontalSearch .selectedStackDiv .selectedStackItem {
  padding: 10.5px 20px 10.5px 45px;
  background-color: rgb(232, 232, 232);
  background-color: white;
}
.search .selectedStackDiv .selectedStackItem::after,
.horizontalSearch .selectedStackDiv .selectedStackItem::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #e74c3c;
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
}
.horizontalSearch .selectedStackDiv .selectedStackItem:hover::after {
  background-color: #2d3436;
  width: 100%;
}
.search .selectedStackDiv .selectedStackItem .close,
.horizontalSearch .selectedStackDiv .selectedStackItem .close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 100%;
  width: 20px;
  border-radius: 3.5px;
  background-color: #e74c3c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  cursor: pointer;
  z-index: 10;
  opacity: 0.35;
  transition: all 0.2s;
}
.horizontalSearch .selectedStackDiv .selectedStackItem .close {
  width: 35px;
}
.horizontalSearch .selectedStackDiv .selectedStackItem .close h3 {
  color: white !important;
}
.search .selectedStackDiv .selectedStackItem:hover .close,
.horizontalSearch .selectedStackDiv .selectedStackItem:hover .close {
  opacity: 1;
}
.horizontalSearch .selectedStackDiv .selectedStackItem .close {
  filter: grayscale(100%);
}
.horizontalSearch .selectedStackDiv .selectedStackItem:hover .close {
  filter: grayscale(0);
}
.search .selectedStackDiv .selectedStackItem p {
  opacity: 1;
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
  z-index: 2;
}
.horizontalSearch .selectedStackDiv .selectedStackItem h3 {
  font-size: 0.865rem;
  opacity: 0.85;
  font-weight: 600;
  z-index: 10;
  transition: all 0.2s;
}
.horizontalSearch .selectedStackDiv .selectedStackItem:hover h3 {
  color: white !important;
  opacity: 1;
}
.horizontalSearch {
  position: relative;
}
@media (max-width: 735px) {
  .search p {
    font-size: 0.925rem;
  }
  .search .btnSearch h3 {
    font-size: 1rem;
  }
}
@media (max-width: 665px) {
  .search {
    width: 98.5vw;
    padding: 25px;
    margin-bottom: 35px;
  }

  .search .stackItem h3,
  .search .hrTitle {
    font-size: 0.9rem;
    margin-left: 2.5px;
  }
  .search p {
    margin-right: 2rem;
  }

  .search p,
  .search input {
    font-size: 0.925rem;
  }
}
.horizontalSearch.homepage {
  position: absolute;
  left: 150px;
  top: 50%;
}
.horizontalSearch.homepage {
  top: 285px;
  left: 50%;
  transform: translateX(-50%);
}
.horizontalSearch.homepage .inputCtr {
  width: 550px !important;
}
.horizontalSearch .betaChip {
  position: absolute;
  top: -18px;
  left: 1px;
  padding: 0 10px;
  height: 18px;
  border: 1px solid #2e86de;
  border-bottom: none;
  border-radius: 3.5px 3.5px 0 0;
}
.horizontalSearch .betaChip p {
  color: #2e86de;
  font-size: 0.75rem;
}
.horizontalSearch.homepage .inputCtr .inputFlex .pulseSearch {
  background: rgb(255, 67, 67);
  box-shadow: 0 0 0 0 rgb(255, 67, 67);
  animation: pulse 1s infinite;
  position: absolute;
  top: 1.5px;
  transform: translateY(-50%);
  right: 16px;
  height: 34px;
  width: 60px;
  transform: scale(1);
  border-radius: 3.5px;
}
.horizontalSearch.homepage .inputCtr .inputFlex .searchBtn {
  border-radius: 3.5px;
  border: 2px solid white;
  background: rgb(24, 27, 28);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 65px;
  margin-left: -4px;
  cursor: pointer;
  z-index: 1;
}
.horizontalSearch.homepage .inputCtr .inputFlex .searchBtn h3 {
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
}
.horizontalSearch input {
  border: none;
  border-radius: 4.5px;
  padding: 7.5px 10px;
  width: 475px !important;
  font-size: 1.05rem;
  font-weight: 500;
  width: fit-content;
  height: 38px;
  background-color: rgba(128, 128, 128, 0.085);
}
.horizontalSearch.homepage input {
  background-color: white;
  border: 1.75px solid rgba(0, 0, 0, 0.3);
}
.horizontalSearch input::placeholder {
  opacity: 0.45;
  color: #1e272e;
  font-size: 0.95rem;
  -webkit-text-stroke: 0.35px rgba(0, 0, 0, 0.35);
  text-shadow: #fff 0px 1px 1px;
}
.horizontalSearch .inputCtr {
  display: flex;
  align-items: flex-start;
}
.horizontalSearch .inputCtr .inputFlex {
  display: flex;
}
.horizontalSearch .inputCtr .inputFlex .searchBtn {
  border-radius: 3.5px;
  background: #2d3438;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 65px;
  margin-left: 5px;
  cursor: pointer;
  margin-right: 10px;
}
.horizontalSearch .inputCtr .inputFlex .searchBtn h3 {
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
}
.horizontalSearch .inputCtr .searchResults {
  position: absolute;
  left: 0;
  background-color: white;
  padding: 15px;
  width: 100%;
  visibility: hidden;
  top: 52.5px;
  transition: all 0.15s;
  opacity: 0;
  border-radius: 5px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.008),
    0 6.7px 5.3px rgba(0, 0, 0, 0.012), 0 12.5px 10px rgba(0, 0, 0, 0.015),
    0 22.3px 17.9px rgba(0, 0, 0, 0.036), 0 41.8px 33.4px rgba(0, 0, 0, 0.022),
    0 100px 80px rgba(0, 0, 0, 0.003);
  max-width: 535px;
  z-index: 1;
}
.horizontalSearch .inputCtr .inputFlex input:focus ~ .searchResults,
.horizontalSearch .inputCtr .searchResults:hover {
  visibility: visible;
  opacity: 1;
  top: 48.5px;
}
.horizontalSearch .inputCtr .searchResults .stackDiv .horizontalStackItem {
  display: flex;
  margin-top: 8.5px;
  border-radius: 5px;
  transition: background-color 0.15s;
  cursor: pointer;
}
.horizontalSearch
  .inputCtr
  .searchResults
  .stackDiv
  .horizontalStackItem:hover {
  background-color: rgb(245, 245, 245);
}
.horizontalSearch .inputCtr .searchResults .stackDiv .horizontalStackItem img {
  height: 31.5px;
  width: 31.5px;
  margin-right: 10px;
}
.horizontalSearch
  .inputCtr
  .searchResults
  .stackDiv
  .horizontalStackItem
  #spryte {
  filter: grayscale(75%);
}
.horizontalSearch .inputCtr .searchResults .stackDiv .horizontalStackItem h3 {
  font-size: 0.935rem;
}
.horizontalSearch .inputCtr .searchResults .stackDiv .horizontalStackItem p {
  opacity: 0.4;
  line-height: 0.9rem;
  font-weight: 600;
}
@media (max-width: 1289px) {
  .horizontalSearch.homepage {
    top: 335px;
  }
}
@media (max-width: 1250px) {
  .horizontalSearch {
    left: 25px;
    top: 35%;
  }
}
@media (max-width: 1035px) {
  .horizontalSearch.homepage {
    top: 320px;
  }
}
@media (max-width: 740px) {
  .horizontalSearch.homepage {
    top: 350px;
  }
}
@media (max-width: 620px) {
  .horizontalSearch.homepage {
    top: 285px;
  }
}
@media (max-width: 1195px) {
  .horizontalSearch .inputCtr {
    flex-direction: column;
  }
  .horizontalSearch .selectedStackDiv .ssi {
    margin-left: 0;
    margin-right: 10px;
  }
  .horizontalSearch .selectedStackDiv h3 {
    font-size: 0.9rem;
  }
  .horizontalSearch .selectedStackDiv .ssi img {
    height: 14px;
    width: 14px;
  }
  .horizontalSearch .selectedStackDiv .selectedStackItem {
    padding: 2.5px 20px 2.5px 35px;
  }
  .horizontalSearch .selectedStackDiv .selectedStackItem .close {
    height: 100%;
    width: 25px;
  }
  .horizontalSearch .selectedStackDiv {
    width: 90%;
  }
  .horizontalSearch .inputCtr input {
    margin-bottom: 12.5px;
  }
  .horizontalSearch .selectedStackDiv {
    width: 95vw;
  }
  .horizontalSearch .selectedStackDiv .selectedStackItem {
    margin-left: 0;
    margin-right: 8.5px;
  }
}
@media (max-width: 575px) {
  .horizontalSearch .inputCtr input {
    width: 67.5vw !important;
    height: 34.5px;
    padding-left: 6.5px;
    font-size: 0.935rem;
  }
  .horizontalSearch.homepage .inputCtr input {
    width: 295px !important;
  }
  .horizontalSearch.homepage .inputCtr {
    width: fit-content !important;
  }
  .horizontalSearch.homepage .inputCtr .inputFlex .searchBtn {
    height: 34.5px;
    width: 60px;
    margin-left: -6.5px;
  }
  .horizontalSearch.homepage .inputCtr .inputFlex .searchBtn h3 {
    font-size: 0.735rem;
  }
  .horizontalSearch .inputCtr .searchResults .stackDiv .horizontalStackItem h3 {
    font-size: 0.875rem;
  }
  .horizontalSearch .inputCtr .searchResults .stackDiv .horizontalStackItem p {
    font-size: 0.875rem;
  }
  .horizontalSearch
    .inputCtr
    .searchResults
    .stackDiv
    .horizontalStackItem
    img {
    height: 26.5px;
    width: 26.5px;
    margin-right: 10px;
  }
  .horizontalSearch .inputCtr .searchResults {
    width: 98.5%;
    padding: 10px;
  }
  .horizontalSearch .inputCtr .inputFlex .searchBtn {
    height: 34px;
    width: 65px;
  }
  /* .horizontalSearch .inputCtr .inputFlex .searchBtn h3 {
    font-size: 0.875rem;
  } */
  .horizontalSearch.homepage .inputCtr .inputFlex .pulseSearch {
    top: 1px;
    right: 11.5px;
    height: 32px;
    width: 56px;
  }
}
@media (max-width: 509px) {
  .horizontalSearch.homepage {
    top: 315px;
  }
}
@media (max-width: 380px) {
  .horizontalSearch.homepage .inputCtr input {
    width: 240px !important;
  }
}
