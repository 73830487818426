.requirementRow {
  padding: 1rem 2rem;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  .boldText {
    font-weight: 600;
  }

  // ._spExcerptContent-content{

  // }

  .arrowDown {
    border: 1px solid black;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    align-self: flex-end;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &--top {
    align-items: center;
  }

  &--info {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    &_match {
      width: 100px;
      display: flex;
      flex-direction: column;
      background-color: #3fdd98;
      padding: 1rem;
      align-items: center;
      justify-content: center;
      span {
        color: #484848;
      }
      &__count {
        font-size: 3rem;
        font-weight: 600;
      }
      &__text {
        font-size: 1.4rem;
        font-weight: 500;
      }
    }
    &_desc {
      flex: 1;
    }
  }

  &--matches {
    h5 {
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 2rem;
    }
    &_cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;

      .requirement--matchCard {
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 1rem;

        &_left {
          margin-top: 0 !important;
        }
        &_devInfo {
          h4 {
            font-size: 1.6rem !important;
          }
          .listDevLoc {
            span {
              font-size: 1.2rem !important;
            }
          }

          .devRate {
            display: flex;
            align-items: center;

            span {
              margin-left: 1rem;
            }
          }
        }
        &_devPrice {
          border-radius: 0 !important;
          min-width: 100px;
          font-size: 1.4rem !important;
          background-color: #e0e0e0 !important;
          height: 100%;
          .amountText {
            font-size: 1.9rem !important;
          }
          span {
            font-size: 1.4rem !important;
          }
        }
      }
    }
  }
}
