@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(7.5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .upDevModal {
    border-radius: 2.5px;
    background-color: white;
    animation: fadeInUp;
    animation-fill-mode: both;
    animation-duration: 0.325s;
    animation-delay: 0.1s;
    animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  }
  .upDevModal .nameDiv {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .upDevModal .columnDiv {
    width: 45%;
  }
  .upDevModal #title {
    color: #000002;
    font-weight: 700;
    font-size: 32px;
  }
  .upDevModal #alert {
    color: #F95E2F;
    font-weight: 400;
    font-size: 14px;
  }
  .upDevModal #infoText {
    font-size: 14px;
    font-weight: 400;
    color: #444444;
    line-height: 15px;
  }
  .upDevModal #helpText {
    font-size: 14px;
    font-weight: 400;
    color: #B8B9BA;
  }
  .upDevModal span {
    color: #F95E2F;
  }
  .upDevModal p {
    font-size: 14px;
    letter-spacing: 0.15px;
    font-weight: 700;
    color: #000000;
  }
  .upDevModal .input {
    padding: 5px;
    border: 1px solid #DDDDE1;
    background: #FFFFFF;
    width: 70%;
    margin-top: 10px;
  }
  .upDevModal textarea::placeholder { 
    font-weight: 500;
    font-size: 16px;
    color: #B8B9BA;
    padding-right: 15px;
  }
  .upDevModal button {
    background-color: #2d3436;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 3.5px 15px;
    border-radius: 1.5px;
    letter-spacing: -0.15px;
    opacity: 0.935;
    margin: 0;
    transition: none;
    color: white;
  }
  .upDevModal .inputCtr {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  .upDevModal textarea {
    background-color: white;
    padding: 7.5px;
    border-radius: 2.5px;
    height: 90px;
    border: 1.5px solid rgba(71, 31, 31, 0.25);
    font-size: 13px;
    font-weight: 600;
    margin: 5px 0 15px 0;
  }
  .positionsCtr {
    position: absolute;
    top: 115%;
    left: 0;
    background-color: #2d3436;
    padding: 5px 35px 5px 15px;
    border-radius: 2.5px;
    width: 80%;
    z-index: 999;
  }
  .positionsCtr .position {
    font-weight: 500;
    font-size: 12.5px;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: -0.15px;
    cursor: pointer;
    color: white;
    margin: 6.5px 0;
  }
  .customReqBg .upDevModal hr {
    border: none;
    height: 2px;
    background: rgba(128, 128, 128, 0.115);
    margin-bottom: 15px;
  }
  .customReqBg .upDevModal .help {
    position: absolute;
    bottom: 16.5px;
    right: 16.5px;
    cursor: pointer;
  }
  .customReqBg .upDevModal .help .helpContent {
    position: absolute;
    right: 137.5%;
    bottom: 7.5px;
    background-color: #2d3436;
    padding: 8.5px;
    border-radius: 2.5px;
    padding-left: 15px;
    transition: all 0.125s;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
  }
  .customReqBg .upDevModal .help:hover .helpContent {
    right: 125%;
    opacity: 1;
    visibility: visible;
  }
  .customReqBg .upDevModal .help .helpContent .helpItem {
    margin: 8.5px 0;
  }
  .customReqBg .upDevModal .help .helpContent .helpItem h6 {
    color: #459df5;
    font-weight: 600;
    letter-spacing: 0;
  }
  .customReqBg .upDevModal .help .helpContent .helpItem p {
    color: white;
    font-weight: 400;
    letter-spacing: -0.15px;
    opacity: 1;
  }
  .customReqBg .upDevModal .help .helpContent .helpItem p,
  .customReqBg .upDevModal .help .helpContent .helpItem h6 {
    font-size: 13px;
  }
  .errorContainer {
    display: flex;
    align-items: center;
  }
  .errorContainer .err {
    color: red;
    opacity: .65;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0;
    margin-left: 8.5px;
    transform: translateY(-1.5px);
  }