.reviews {
  .testimonials {
    .addData {
      position: fixed;
      top: 0;
      right: 0;
      background-color: rgba($color: white, $alpha: 0.5);
      width: 85%;
      height: 100vh;
      z-index: 20000;

      &_add {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // background-color: red;
        width: 70rem;
        height: 60rem;
        border-radius: 1rem;
        padding: 2rem;
        background-color: white;
        box-shadow: 0 20px 75px rgba(#000, 0.5);
        #addData_cancel {
          color: white;
          background-color: lighten($color: $danger, $amount: 0.8);
        }
        #addData_sub {
          color: white;
          background-color: lighten($color: $color_prim, $amount: 0.8);
        }
        label {
          margin: 0;
        }

        &__content {
          height: 100%;
          justify-content: space-between;
          // background-color: yellow;

          &-inputs {
            flex-basis: 80%;
            justify-content: space-between;
            .input {
              input {
                padding: 0.5rem;
              }
              textarea {
                min-height: 6rem;
                padding: 0.5rem;
              }
            }

            &--avatar {
              &_upload {
                display: flex;
                margin-top: 1rem;
                column-gap: 1rem;
                justify-content: space-between;

                &__imBox {
                  width: 4rem;
                  height: 4rem;
                  border: 1px solid black;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
            }
          }

          &-actions {
            // background-color: burlywood;
            margin-top: 1rem;
            display: flex;
            // align-items: flex-end;
            align-self: flex-end;
            column-gap: 0.5rem;
          }
        }
      }
    }
    .btn {
      color: $color_prim;
      font-size: 1.3rem;
      font-weight: 500;
      padding: 0.5rem 1rem !important;
      border-radius: 0.5rem;

      &:hover {
        text-decoration: underline;
      }
    }
    h3 {
      margin: 0;
    }
    p {
      margin: 0;
    }
    &_content {
      &__top {
        // background-color: chartreuse;
        align-items: flex-start;
        align-content: center;
        .btn {
          padding: 0;
          // margin-bottom: .5rem;
        }
      }
    }
    &_list {
      gap: 3rem;
    }
    width: 100%;
    // justify-content: space-between;
    .testimonial {
      justify-content: space-between;
      // column-gap: 5vw;

      &_description {
        flex-basis: 50%;
      }

      &_author {
        // align-items: center;
        column-gap: 1rem;
        flex-basis: 30%;

        &__avatar {
          width: 4rem !important;
          height: 4rem !important;
          max-width: 4rem !important;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &_actions {
        display: flex;
        align-items: flex-end;
      }
    }
  }
}
