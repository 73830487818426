.contract {
  font-size: 1.3rem;
  &_cont {
    .ctr {
      margin-top: 4rem;

      &_el_header {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        svg {
          margin-bottom: 0.6rem;
        }
      }
    }
  }

  &_bottom {
    td,
    th {
      width: calc(100% / 4);
    }
    a {
      background-color: $color_prim;
      padding: 0.3rem 2rem;
      color: $white;
      &:hover {
        text-decoration: none;
        cursor: pointer;
        color: $white;
        background-color: darken($color: $color_prim, $amount: 5);
      }
    }
    svg {
      color: $danger;
      font-size: 2rem;

      &:hover {
        color: darken($color: $danger, $amount: 15);
        cursor: pointer;
      }
    }
  }
  
}

