.resource-header{
        h3{
            color: $color_prim;
            font-weight: 600;
            font-size: 2rem;
        }
        p{
            font-size: 1.4rem;
            font-weight: 500;
            color: #8E8E8E;
        }

    }

