.login-div {
  height: 89vh;
  width: 80vw;
}
.devtitle {
  align-self: center !important;
  margin-left: 0 !important;
  margin-bottom: 30px !important;
  color: black !important;
}
.login {
  /* width: 1200px; */
  height: 615px;
  display: flex;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  font-family: "Open Sans";
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.login .login-left,
.login .login-right {
  flex: 1;
}
a,
span {
  text-decoration: none;
  color: inherit;
  /* background-color: green; */
  text-emphasis: none;
}
/* LOGIN LEFT */
.login .login-left {
  position: relative;
  overflow: hidden;
}
.login .login-left .login-div .error {
  position: absolute;
  /* top: 37.5px; */
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 0, 0, 0.774);
  padding: 0;
  margin: 0;
  font-weight: 600;
  text-align: center;
  font-size: 1.35rem;
}
.login .login-left .circle img {
  position: absolute;
  top: -200px;
  left: -200px;
  height: 320px;
  width: 320px;
  opacity: 0.275;
}
.login .login-right .version {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  opacity: 0.55;
}
.login .login-left .circle.dev_log img {
  filter: grayscale(100%);
  opacity: 0.5;
}
.login .login-left .login-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 65px;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: fadeInUp;
  animation-fill-mode: both;
  animation-delay: 0.35s;
  animation-duration: 0.35s;
}
.login .login-left h2 {
  color: #031727b8;
  font-weight: 600;
  /* align-self: flex-start; */
  margin-bottom: 20px;
  margin-left: -6.5px;
}
.login .login-left p {
  color: #636e72b6;
  font-weight: 400;
  /* letter-spacing: 1px;s */
}
.login .login-left .input-div a {
  padding: 15px 75px;
  color: white;
  font-weight: 700;
  font-size: 1.35rem;
  border-radius: 35px;
  background: linear-gradient(to left, #0985e3bb, #0321b8b8);
  margin: 15px;
  text-decoration: none;
}
.login .login-left .input-div.dev_log a {
  background: linear-gradient(to left, #41434477, #2d3436c2);
}
.login .login-left .button-div {
  margin-top: 20px;
  margin-bottom: 40px;
}
.login .login-left .input-div .register {
  padding: 15px 75px;
  color: #0321b8b8;
  border-radius: 35px;
  background: transparent;
  border: 1.5px solid #0321b8b8;
  margin-top: 20px;
}
.login .login-left span {
  font-weight: 800;
  cursor: pointer;
}
.login-div a {
  font-size: 1.55rem !important;
}
.login .login-left input {
  border: none;
  border-bottom: 2px solid #e6e7e7;
  padding: 10px 0px;
  outline: none;
  width: 100%;
  /* font-weight: 800; */
  font-size: 1.75rem;
  margin-bottom: 25px;
}
.login .login-left input::placeholder {
  font-weight: 800;
}

/* LOGIN RIGHT */
.login .login-right {
  background-image: linear-gradient(to left, #0985e3bb, #031727b8),
    url(../components/images/Login.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 2;
  position: relative;
  color: white;
  overflow: hidden;
}
.login .login-right.dev_log {
  background-image: linear-gradient(to left, #b3babd77, #2d3436c2),
    url("https://images.unsplash.com/photo-1497091071254-cc9b2ba7c48a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1353&q=80");
  /* filter: grayscale(100%); */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}
.login .login-right .circle {
  position: absolute;
  bottom: -75px;
  right: -75px;
  height: 250px;
  width: 250px;
  border-radius: 150px;
  background-color: #0985e3bb;
}
.login .login-right .circle.dev_log {
  background-color: #2d3436c2;
}
.login .login-right .circle .spryte {
  position: relative;
  bottom: -40px;
  right: -42.5px;
  height: 125px;
  width: 125px;
}
.login .login-right .login-intro {
  font-size: 1.55rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.76);
}
.login .login-right .login-intro hr {
  background-color: white;
  opacity: 1;
}
.login .login-right .login-intro h2 {
  font-size: 3.25rem;
  color: white;
  font-weight: 700;
  margin-top: 18.5px;
}
.login .login-right .login-intro .welcome {
  letter-spacing: 3.5px;
  font-weight: 300;
  margin-bottom: -18.5px;
}
.login .login-right .login-loader {
  opacity: 0;
  animation: fadeInUp;
  animation-fill-mode: both;
  animation-delay: 0.35s;
  animation-duration: 0.35s;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login .login-right .login-loader img {
  height: 75px;
  width: 75px;
  animation: rotate 0.35s linear infinite;
  margin: 25px;
  margin-top: -25px;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
/* ANIMATIONS */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.loading-ctr {
  background-color: rgb(66, 158, 255) !important;
  height: 48px;
  width: 238.69px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  margin-top: -14.5px !important;
  margin-bottom: -14.5px !important;
}
