.request {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color: white, $alpha: 0.7);
  width: 100vw;
  height: 100vh;
  z-index: 20000;
  &_form {
    position: fixed;
    // z-index: 20000;
    background-color: white;
    box-shadow: 0 20px 75px rgba(#000, 0.5);
    padding: 3rem;
    width: 70rem;
    top: 18%;
    left: 50%;
    border-radius: 1rem;
    transform: translate(-50%, -50%);
    gap: 1rem;
    max-width: 70vw;
    max-height: 90vh;
    overflow: auto;
    &-textarea {
      height: 9rem;
      padding: 1rem;
      font-size: 1.2rem;
      border: 2px solid $color_prim;
      outline: none;
      border-radius: 5px;
    }
    &-action {
      // background-color: $color_prim;
      // align-items: end;
      gap: 0.5rem;
      // align-items: flex-end;
      justify-content: flex-end;
    }
    #request_form-close {
      font-size: 2rem;
      position: absolute;
      top: 3%;
      right: 3%;

      &:hover {
        cursor: pointer;
        color: $danger;
      }
    }
  }
}
