/**
 * Reset
 */

html {
  color: #222;
  font-size: 62.5%;
  line-height: 1.4;
}
.indicator {
  @include middlePosition();
}
.sp-selectable {
  &:hover {
    cursor: pointer;
  }
}

._facadeProfile {
  margin-top: 0.5rem;
  gap: 0.5rem;
  max-width: 20rem;
  a {
    padding: 0.5rem;

    text-align: center;
    font-weight: 600;
  }
  &-highlighted {
    background-color: $color_prim;
    color: white;
    &:hover {
      color: white;
    }
  }
  &-grey {
    background-color: #e3f0ff;
  }

  &-submit {
    background-color: #ecfaff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: auto;
    border-radius: 5px;
    margin-top: 14px;
    padding: 1rem 2rem;
    text-align: center;
    p {
      font-size: 12px;
      color: #4361e9;
    }
    button {
      background-color: #4361e9;
      border: none;
      font-size: 16px;
      color: white;
      // border-radius: 6px;
      padding: 0.5rem 2rem;
      font-weight: 500;
    }
  }
}

input:not([type="checkbox"]):not([type="radio"]),
textarea,
select {
  border: 1px solid #dfdfdf;
  // border-radius: 5px;
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: white;
  outline: none;
}

.row {
  display: flex;
  flex-direction: row;
  margin: 0;
}
.column {
  display: flex;
  flex-direction: column;
}
.client_upload {
  margin-bottom: 12px;
}

.hidden {
  display: none;
}
.all_resources .e-grid .e-rowcell {
  height: 0.5rem;
  // background-color: #00b300;
  padding: 0;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
.e-gantt .e-gantt-chart .e-holiday {
  background: rgba(#f44336, 0.7);
}

.e-gantt .e-gantt-chart .e-holiday .e-span {
  color: white;
  font-size: 20px;
}

#myProgress {
  position: relative;
  height: 18px;
  width: 10em;
  text-align: left;
  background-color: white;
  // -webkit-box-shadow: 5px 5px 16px 2px #000000;
  box-shadow: 0.5px 0.5px 2px #000000;
}
.highcontrast #overviewgrid #myProgress {
  background-color: black;
}

#myBar {
  position: absolute;
  width: 10%;
  height: 100%;
  background-color: #00b300;
}
#myBar.progressdisableb {
  background-color: #3498db;
}
#myBar.progressdisable {
  background-color: #df2222;
}
#blabel {
  position: relative;
  left: 10px;
  line-height: 18px;
  font-size: 10px;
  font-weight: 500;
  color: white;
}
#wlabel {
  position: relative;
  left: 10px;
  line-height: 18px;
  font-size: 10px;
  color: black;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}

@media print,
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 1.25dppx),
  (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: none;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

.e-grid {
  border-color: transparent !important;

  .e-toolbar {
    border-top: transparent;
  }
}

.e-inplaceeditor .e-editable-value-wrapper {
  // background-color: red;
  padding: 0 !important;
  // margin-left: .5rem;
  // margin-bottom: 0.5rem;

  &:hover {
    background-color: transparent;
  }

  .e-editable-value {
    border: none;
    // color: $danger;
  }

  .e-editable-overlay-icon {
    visibility: visible;
    // width: 1rem;
    color: $color_prim;
    margin-right: -0.3rem;
    margin-bottom: 0.5rem;

    &::before {
      content: "edit";
      font-size: 1.2rem;
    }
  }
}
