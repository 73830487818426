.datapoints {
  .request_form {
    min-width: 60rem;
  }
  .readOnlyStl,
  .readOnlyStl label {
    color: rgba(#0000, 0.4) !important;
    font-weight: 500;
  }
  .col_2_title {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    justify-content: space-between;
  }
  .col_2_proto {
    display: grid;
    grid-template-columns: 70% 28%;
    justify-content: space-between;
  }
  .col_3_input {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    justify-content: space-between;
  }
  .datapoints_list {
    .singleDataPoint {
      cursor: pointer;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      background-color: #f0fafb;
      padding: 1rem;
      width: 25rem;
      height: 7.5rem;
      overflow: hidden;
      h3 {
        font-weight: 700;
        font-size: 1.5rem;
      }
      p {
        font-size: 1.3rem;
      }
    }
  }
}
