.account {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  h3 {
    margin: 0;
  }
  &-div {
    display: flex;
    flex-direction: column;

    &_info {
      display: flex;
      gap: 2rem;

      .account-photo {
        max-height: 32rem;
        .avatar {
          width: 100%;
          height: 18rem;
          overflow: hidden;
          transition: transform 0.5s;
          &:hover .upload-btn-wrapper {
            transform: translateY(0);
            left: 40%;
          }
          .upload-btn-wrapper {
            transform: translateY(300px);
            left: 50%;
            .btn {
              width: 3rem;
              height: 3rem;

              p {
                margin-top: -1.7rem;
                font-weight: 500;
                // font-size: 1rem;
                font-size: 4rem;
              }
            }
          }
        }
      }

      .account-intro {
        border: 1px solid $color_border;
        border-radius: 1rem;
        // max-height: 32rem;
        flex-basis: 80%;
        overflow: auto;

        &_top {
          padding: 1rem;
          display: flex;
          justify-content: space-between;
          .completed {
            flex-direction: row;
          }

          .name {
            h1 {
              font-weight: 700;
              font-size: 3rem;
            }
            .personal-info {
              margin-top: 2rem;
              // font-size: larger;
              &-content {
                display: flex;
                align-items: center;
                gap: 0.4rem;

                h3 {
                  color: $color_prim;
                  font-weight: 600;
                  font-size: 1.5rem;
                }
                .e-inplaceeditor .e-editable-value-wrapper .e-editable-value {
                  font-size: 1.5rem;
                }
              }
            }
          }
        }
        &_bottom {
          padding: 1rem;
          border-top: 1px solid $color_border;
          .account-about {
            &_header {
              display: flex;
              gap: 0.5rem;
              color: $color_prim;
              h2 {
                font-weight: 600;
              }
              svg {
                &:hover {
                  color: $danger;
                  cursor: pointer;
                }
              }
            }
            #bioedit {
              display: none;
            }
          }
        }
      }
    }
  }
  .badges {
    justify-content: space-between;
    max-width: 70vw;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    &_box {
      align-items: center;
      display: flex;
      max-width: calc((90% / 3) - 10px);
      flex-direction: column;
      align-items: center;
      &-img {
        width: 10rem;
        height: 10rem;
        object-fit: cover;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .clipboard {
        width: 100%;
        background-color: $sidebare;
        svg {
          color: $color_prim;
          font-size: 2rem;

          &:hover {
            color: darken($color: $color_prim, $amount: 15);
            cursor: pointer;
          }
        }
        &_copy {
          display: flex;
        }
      }
    }
  }
  .marketing {
    .stat_content_data-content {
      max-width: 78%;
    }
  }

  .leadData {
    display: flex;
    gap: 2rem !important;
    // background: rebeccapurple;
    .leadData_time {
      gap: 0.5rem;
    }
  }
  .togglebox {
    align-items: baseline !important;
    gap: 0.5rem !important;

    #checkedLead {
      margin-top: 1rem;
    }
  }
}

._spInviteUsers {
  .modal-content {
    padding: 5rem 3rem !important;
    margin-top: 2rem;
  }
  &_content--close {
    top: 2rem !important;
    right: 2rem !important;
    // border-width: 2rem;
    border: 2px solid #4361e9;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--content {
    display: grid;
    gap: 1rem;
    position: relative;

    &_form {
      gap: 1rem;
      // max-height: 60vh !important;
      & > div {
      }
    }
    &_actions {
      gap: 1rem;

      button {
        border-radius: 5px;
      }

      ._invitesend {
        background-color: #4361e9;
        color: white;
        font-weight: 600;
        padding: 5px;
      }
    }
    &_errors {
      position: absolute;

      bottom: 0;
      left: 50%;
      background-color: crimson;
      color: white;
      padding: 0.2rem 1rem;
      border-radius: 5px;
      font-weight: 700;
    }
  }
}
